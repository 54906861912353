<!--云台控制-->

<template>
	<section class="panel-box control-box">
		<!-- 		<h4 class="title-box">
			<img src="@/assets/jiankong/text_ytkz.png" style="width: 90px" alt="" /> -->
		<!-- 展开/收缩 -->
		<!-- <div class="right-imgs" @click="toggleSpread">
				<img src="@/assets/jiankong/arrow.png" class="arrow" :class="{ spread }" alt="" style="margin-right: 0" />
				<img v-if="spread" src="@/assets/jiankong/text_shouqi.png" alt="" style="width: 54px" />
				<img v-else src="@/assets/jiankong/text_zhankai.png" style="width: 54px" alt="" />
			</div> -->
		<!-- </h4> -->
		<div class="content-wrapper">
			<!-- 
							@mousedown="changeDirectionIndex(item,index)" @mouseup="stopMove(item,index)" -->
			<!-- 控制板，定位 -->
			<div class="content-box" style="transform: rotate(-45deg);">
				<section class="control-circle-box">
					<div class="circle-bg" :class="'circle-bg-' + (activeDirectionIndex + 1)"></div>
					<ul class="triangles">
						<li v-for="(item,index) in controlArr" :key="index"
							@mousedown="changeDirectionIndexkiaishi(item,index)"
							@mouseup="changeDirectionIndexjieshu(item,index)">
							<img src="@/assets/jiankong/方向箭头.png" alt="" />
						</li>
					</ul>
					<div class="icon-box"
						style="margin-top: -55%;transform: rotate(45deg);z-index: 10;position: relative;">
						<div id="zoomIcon" alt="" @mousedown="handleMouseDown" @mouseup="handleMouseUp"
							:class="{ active: isIconActive }"
							style="margin-left: 37%;float: left;z-index: 10;position: relative;width: 23px;height: 24px;">
						</div>
						<div id="zoomIconda" src="@/assets/jiankong/放大.png" alt="" @mousedown="handleMouseDownda"
							@mouseup="handleMouseUpda" :class="{ active: isIconActiveda }"
							style="margin-left: 7%;float: left;z-index: 10;position: relative;width: 23px;height: 24px;">
						</div>
						<div class="qingxiduwenzi" v-show="this.qingxidu==1" @click="huangaoqing()">普清</div>
						<div class="qingxiduwenzi" v-show="this.qingxidu==0" @click="huanpuqing()">高清</div>
					</div>
					<!-- 	<div class="icon-box"
						style="margin-top: -55%;transform: rotate(45deg);z-index: 10;position: relative;">
					</div> -->
					<!-- <div class="location-box"> -->
					<!-- <div class="icon-box">
							<img src="@/assets/jiankong/location.png" alt="" />
							<img src="@/assets/jiankong/red_circle.png" alt="" />
						</div> -->
					<!-- <span>监控设备一</span> -->
					<!-- </div> -->
				</section>
				<!-- 加减控制 -->
				<!-- <ul class="add-cut-box">
					<li>
						缩放
						<div>
							<i @click="control(8)" class="el-icon-plus"></i>

							<i @click="control(9)" class="el-icon-minus"></i>
						</div>
					</li> -->
				<!-- 					<li>
						光圈
						<div>
							<i @click="controlNo" class="el-icon-plus"></i>

							<i @click="controlNo" class="el-icon-minus"></i>
						</div>
					</li> -->
				<!-- <li>
						焦距
						<div>
							<i @click="control(11)" class="el-icon-plus"></i>

							<i @click="control(10)" class="el-icon-minus"></i>
						</div>
					</li>
				</ul> -->
				<!-- 速度控制 -->
				<!-- 	<div class="rate-box">
					<div class="text-box">
						<span>速度</span>
						<span class="fontCount">{{rangeVal}}</span>
						<span>6倍速</span>
					</div>
					<div class="bar-box">
						<span class="fontCount">1</span>
						<input v-model="rangeVal" min="1" max="6" type="range" />
						<span class="fontCount">6</span>
					</div>
				</div> -->
			</div>
		</div>
	</section>
</template>

<script>
	import {
		SluiceWater,
		SluiceDetails,
		SluiceControl,
		SluiceControlPan,
		SluiceControlPantwo,
		SluiceHistoryData,
		SluiceRecordsList
	} from "@/api/water/sluice";
	import {
		jiankongsn
	} from "@/api/daping/jiankongsn";
	// import {
	// 	startmove,stopmove
	// } from "@/api/daping";
	export default {
		name: "",
		props: ['jiankongbiaoshi', 'qingxidu'],
		data() {
			return {
				// 云台方向
				command: 'RIGHT',
				// rangeVal: 3,
				spread: true,
				activeDirectionIndex: 0, //当前方向索引 0-7
				// 操作命令：0-上，1-下，2-左，3-右，4-左上，5-左下，6-右上，7-右下，8-放大，9-缩小，10-近焦距，11-远焦距
				controlArr: [{
					name: "UP",
					type: '0'
				}, {
					name: "RIGHT_UP",
					type: '6'
				}, {
					name: "RIGHT",
					type: '3'
				}, {
					name: "RIGHT_DOWN",
					type: '7'
				}, {
					name: "DOWN",
					type: '1'
				}, {
					name: "LEFT_DOWN",
					type: '5'
				}, {
					name: "LEFT",
					type: '2'
				}, {
					name: "LEFT_UP",
					type: '4'
				}],
				windowNum: 1,
				activeList: "[]",
				activeGridding: 1,
				rangeVal: 50,

				// 监控标识
				jkidentiFication: this.jiankongbiaoshi,
				jiankonglist: [],
				jiankongweiyisn: this.jiankongbiaoshi,
				isIconActive: false,
				isIconActiveda: false,



			};
		},
		created() {},
		mounted() {
			console.log("++++++++yuntaikongzhi.vue++++++++++" + this.jkidentiFication)
			// this.getjiankongsn()
		},
		watch: {
			spread: {
				handler(val) {
					this.$emit("changeControlSpread", val);
				},
				immediate: true,
			},
			jiankongbiaoshi(newValue) {
				// 监听到 message 变化时执行的操作
				console.log('message 变化为:', newValue);
				this.jiankongweiyisn = newValue
				console.log(this.jiankongweiyisn)
			}

		},

		methods: {
			huangaoqing(){
				this.$emit('childqingxi', 0);
			},
			huanpuqing(){
				this.$emit('childqingxi', 1);
			},
			handleMouseDown() {
				this.isIconActive = true;
				this.command = 'ZOOM_OUT';
				this.controlPankaishi();
				this.daojishi = 30
				this.$emit('childEvent', this.daojishi);

			},
			handleMouseUp() {
				this.isIconActive = false;
				this.command = 'ZOOM_OUT';
				this.controlPantingzhi();
				this.daojishi = 30
				this.$emit('childEvent', this.daojishi);
			},
			handleMouseDownda() {
				this.isIconActiveda = true;
				this.command = 'ZOOM_IN';
				this.controlPankaishi();
				this.daojishi = 30
				this.$emit('childEvent', this.daojishi);
			},
			handleMouseUpda() {
				this.isIconActiveda = false;
				this.command = 'ZOOM_IN';
				this.controlPantingzhi();
				this.daojishi = 30
				this.$emit('childEvent', this.daojishi);
			},
			// getjiankongsn() {
			// 	jiankongsn({
			// 		categoryId: 2,
			// 		pageNum: 1,
			// 		pageSize: 99999,
			// 		productId: 26,
			// 	}).then(response => {
			// 		if (response.code == 200) {
			// 			this.jiankonglist = response.rows;
			// 			console.log(this.jiankonglist)
			// 			for(let i=0;i<this.jiankonglist.length;i++){
			// 				if(this.jiankonglist[i].deviceId == this.jkidentiFication){
			// 					this.jiankongweiyisn = this.jiankonglist[i].serialNumber
			// 					// break
			// 				}
			// 			}
			// 			console.log("***************"+this.jiankongweiyisn)
			// 		}
			// 	})
			// },



			// 云台控制点击方向
			changeDirectionIndexkiaishi(item, index) {
				console.log(item, index)
				this.activeDirectionIndex = index;
				this.command = item.name;
				this.controlPankaishi();
				this.daojishi = 30
				this.$emit('childEvent', this.daojishi);
			},
			changeDirectionIndexjieshu(item, index) {
				console.log(item, index)
				this.activeDirectionIndex = index;
				this.command = item.name;
				this.controlPantingzhi();
				this.daojishi = 30
				this.$emit('childEvent', this.daojishi);
			},
			// 云台控制放大缩小
			// focalButton(event) {
			// 	console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++")
			// 	console.log(event)
			// 	this.command = event;
			// 	this.controlPankaishi();
			// },

			// 云台控制
			controlPankaishi() {
				console.log(this.jiankongweiyisn)
				SluiceControlPantwo({
					cameraIndexCode: this.jiankongweiyisn,
					command: this.command,
					speed: this.rangeVal,
					action: 0,
				}).then((val) => {
					// if (val.code == 200) {
					// 	this.$message({
					// 		message: '控制成功',
					// 		type: 'success'
					// 	});
					// } else {
					// 	this.$message.error('控制失败');
					// }
					this.daojishi = 30
					this.$emit('childEvent', this.daojishi);
					if (val.code != 200) {
						this.$message({
							message: '控制失败',
						});
					}
				}).catch((err) => {
					console.error(err.msg);
				});
			},
			controlPantingzhi() {
				console.log(this.jiankongweiyisn)
				SluiceControlPantwo({
					cameraIndexCode: this.jiankongweiyisn,
					command: this.command,
					speed: this.rangeVal,
					action: 1,
				}).then((val) => {
					// if (val.code == 200) {
					// 	this.$message({
					// 		message: '控制成功',
					// 		type: 'success'
					// 	});
					// } else {
					// 	this.$message.error('控制失败');
					// }
					this.daojishi = 30
					this.$emit('childEvent', this.daojishi);
					if (val.code != 200) {
						this.$message({
							message: '控制失败',
						});
					}
				}).catch((err) => {
					console.error(err.msg);
				});
			},





			// toggleSpread() {
			// 	this.spread = !this.spread;
			// },
			// controlNo() {
			// 	// this.$message.warning('暂不支持此操作！')

			// },
			// changeDirectionIndex(item, index) {
			// 	var that = this;
			// let activeGridding = Number(this.$store.getters.activeGridding) //目前点击的哪个窗口
			// let windowNum = this.$store.getters.windowNum //目前是几分屏
			// if (windowNum.indexOf('6_2') != -1) {
			// 	windowNum = 6
			// }
			// windowNum = Number(windowNum)
			// let activeList = JSON.parse(this.$store.getters.activeList) //目前激活的视频列表
			// if (activeList.length == 0) {
			// 	return this.$message.warning('请先选择控制的设备！')
			// } else {
			// 	if (activeList.length > windowNum) {
			// 		activeList = activeList.slice(0, windowNum)
			// 	}
			// 	if (activeList.length < activeGridding) {
			// 		return this.$message.warning('所选择的窗口没有可控制的设备！')
			// 	} else {
			// 		if (activeList[activeGridding - 1].Id == "") {
			// 			return this.$message.warning('所选择的窗口没有可控制的设备！')
			// 		} else {
			// 			startmove({
			// 				"map[projectId]": localStorage.getItem('projectId') || '',
			// 				"map[deviceId]": activeList[activeGridding - 1].Id,
			// 				"map[direction]": item.type,
			// 				"map[speed]": that.rangeVal,
			// 				"map[notStop]": 1, //不自动停止
			// 			}).then(res => {
			// 				if (res.data.msg != '操作成功') {
			// 					this.$message.warning(res.data.msg)
			// 				} else {
			// 					// this.$message.success(res.data.msg)
			// 				}
			// 			})
			// 		}
			// 	}
			// }
			// 	this.activeDirectionIndex = index;
			// },
			//停止云台控制
			// stopMove(item, index) {
			// 	var that = this;
			// 	let activeGridding = Number(this.$store.getters.activeGridding) //目前点击的哪个窗口
			// 	let windowNum = this.$store.getters.windowNum //目前是几分屏
			// 	if (windowNum.indexOf('6_2') != -1) {
			// 		windowNum = 6
			// 	}
			// 	windowNum = Number(windowNum)
			// 	let activeList = JSON.parse(this.$store.getters.activeList) //目前激活的视频列表
			// 	if (activeList.length == 0) {
			// 		return this.$message.warning('请先选择控制的设备！')
			// 	} else {
			// 		if (activeList.length > windowNum) {
			// 			activeList = activeList.slice(0, windowNum)
			// 		}
			// 		if (activeList.length < activeGridding) {
			// 			return this.$message.warning('所选择的窗口没有可控制的设备！')
			// 		} else {
			// 			if (activeList[activeGridding - 1].Id == "") {
			// 				return this.$message.warning('所选择的窗口没有可控制的设备！')
			// 			} else {
			// 				stopmove({
			// 					"map[projectId]": localStorage.getItem('projectId') || '',
			// 					"map[deviceId]": activeList[activeGridding - 1].Id,
			// 					"map[direction]": item.type,
			// 				}).then(res => {
			// 					if (res.data.msg != '操作成功') {
			// 						this.$message.warning(res.data.msg)
			// 					} else {
			// 						// this.$message.success(res.data.msg)
			// 					}
			// 				})
			// 			}
			// 		}
			// 	}
			// 	this.activeDirectionIndex = index;
			// },

			// control(type) {
			// 	let activeGridding = Number(this.$store.getters.activeGridding) //目前点击的哪个窗口
			// 	let windowNum = this.$store.getters.windowNum //目前是几分屏
			// 	if (windowNum.indexOf('6_2') != -1) {
			// 		windowNum = 6
			// 	}
			// 	windowNum = Number(windowNum)
			// 	let activeList = JSON.parse(this.$store.getters.activeList) //目前激活的视频列表
			// 	if (activeList.length == 0) {
			// 		return this.$message.warning('请先选择控制的设备！')
			// 	} else {
			// 		if (activeList.length > windowNum) {
			// 			activeList = activeList.slice(0, windowNum)
			// 		}
			// 		if (activeList.length < activeGridding) {
			// 			return this.$message.warning('所选择的窗口没有可控制的设备！')
			// 		} else {
			// 			if (activeList[activeGridding - 1].Id == "") {
			// 				return this.$message.warning('所选择的窗口没有可控制的设备！')
			// 			} else {
			// 				startmove({
			// 					"map[projectId]": localStorage.getItem('projectId') || '',
			// 					"map[deviceId]": activeList[activeGridding - 1].Id,
			// 					"map[direction]": type,

			// 				}).then(res => {
			// 					if (res.data.msg != '操作成功') {
			// 						this.$message.warning(res.data.msg)

			// 					} else {
			// 						this.$message.success(res.data.msg)
			// 					}

			// 				})

			// 			}
			// 		}

			// 	}
			// },
		},
	};
</script>

<style scoped lang="scss">
	.page-shipinjiankong .panel-box {
	    overflow: visible;
	}
	
	.qingxiduwenzi {
		color: #fff;
		float: left;
		margin-top: 16%;
		margin-left: -27%;
		font-size: 16px;
	}

	#zoomIcon {
		background: url("~@/assets/jiankong/缩小.png") no-repeat;
	}

	#zoomIcon.active {
		background: url("~@/assets/ZhaMen/缩小.png") no-repeat;
	}

	#zoomIconda {
		background: url("~@/assets/jiankong/放大.png") no-repeat;
	}

	#zoomIconda.active {
		background: url("~@/assets/ZhaMen/放大.png") no-repeat;
	}

	.control-box .content-box {
		padding-right: 10px !important;
		float: right;
		margin-top: -220px;
	}

	.right-imgs {
		display: flex;
		align-items: center;
		cursor: pointer;

		.arrow {
			margin-right: 4px;
			transition: all 0.5s;
			transform: rotate(90deg);

			&.spread {
				transform: rotate(-90deg);
			}
		}
	}

	.content-box {
		height: 100%;
		display: grid;
		grid-template-columns: 173px 1fr;
		grid-template-rows: 173px 1fr;
		grid-template-areas:
			"a b"
			"c c";
		// gap: 12px;
	}

	.control-circle-box {
		position: relative;
	}

	.control-circle-box,
	.triangles,
	.circle-bg {
		width: 96%;
		height: 96%;
	}

	.circle-bg {
		position: absolute;
		background: url(~@/assets/jiankong/控制器.png) no-repeat;
	}

	.triangles {
		position: relative;
		z-index: 1;

		li {
			position: absolute;
			z-index: 2;
			left: 48%;
			top: 11px;
			width: 16px;
			height: 12px;
			margin: -8px 0 0 -6px;
			transform-origin: 8px 77px;
			cursor: pointer;
			// display: none;
			display: block;

			img {
				width: 100%;
			}
		}

		// li:nth-child(2n) {
		// 	display: block;
		// }
	}

	@for $i from 0 through 8 {

		.triangles li:nth-of-type(#{$i}),
		.circle-bg-#{$i} {
			transform: rotate(45deg * $i);
		}
	}

	.location-box {
		width: 70px;
		height: 70px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		position: absolute;

		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 12px;

		.icon-box {
			position: relative;
			width: 23px;
			height: 23px;

			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	.add-cut-box {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		flex-direction: column;
		font-size: 18px;
		padding-top: 14px;
		padding-bottom: 14px;

		li {
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			color: #FFFFFF;

			div {
				margin-left: 8px;
				width: 95px;
				height: 25px;
				background: #06a8c5;
				opacity: 0.7;
				border-radius: 4px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 12px;
				color: #fff;
				position: relative;

				i {
					cursor: pointer;
				}

				&:after {
					display: block;
					content: "";
					position: absolute;
					top: 3px;
					bottom: 3px;
					left: 50%;
					width: 1px;
					background: #6dd3b7;
					opacity: 0.3;
				}
			}
		}
	}

	.rate-box {
		grid-area: c;
		width: calc(100% - 50px);
		color: #FFFFFF;
		font-size: 18px;
		padding-left: 15px;

		.fontCount {
			color: #fff;
			font-size: 22px;
			opacity: 0.64;
			font-style: italic;
			font-weight: bold;
		}

		.text-box {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 15px;
		}

		.bar-box {
			display: flex;
			justify-content: space-between;
			align-items: center;

			[type="range"] {
				width: 198px;

				-webkit-appearance: none;
				appearance: none;
				margin: 0;
				outline: 0;
				background: transparent;
			}

			[type="range"]::-webkit-slider-runnable-track {
				background: #527675;
				opacity: 0.4;
				height: 5px;
			}

			[type="range"i]::-webkit-slider-container {
				height: 20px;
				overflow: hidden;
			}

			[type="range"]::-webkit-slider-thumb {
				-webkit-appearance: none;
				appearance: none;
				width: 10px;
				height: 18px;
				border-radius: 4px;
				background-color: #fff;

				margin-top: -8px;
			}
		}
	}
</style>
