<template>
	<div>
		<div style="padding-top:30px">
			<div style="height:630px;width:1000px;border:1px solid #ccc;box-sizing: border-box;display:inline-block;vertical-align:bottom"
				class="ctrlProcessWave"></div>
		</div>
		<div style="padding-top:10px;float: right;">
			<span>{{curTimeState}}</span>
			<span style="margin-left:50px"></span>
			<!-- <span>{{frameInfo.txt}}</span> -->
		</div>

		<!-- <div>
			WS地址:<input v-model="v_wsUrl" style="width:300px">
			同一设备所有WS共享:<input type="checkbox" v-model.number="v_share">
		</div> -->
		<!-- <div style="padding-top:10px">
			IP:<input v-model="v_ip" style="width:120px">
			Port:<input v-model.number="v_port" style="width:40px">
			User:<input v-model="v_user" style="width:120px">
			Pwd:<input v-model="v_pwd" style="width:120px">
			通道:<input v-model.number="v_channel" style="width:40px">
		</div> -->
		<!-- <div style="padding-top:10px">
			<button @click="openClick">打开</button>
			<button @click="closeClick">关闭</button>
			<span style="margin-left:50px"></span>
			<button @click="pauseClick">暂停</button>
			<button @click="resumeClick">继续</button>
		</div> -->

		<!-- 显示信息 -->
		<!-- <div style="padding-top:10px">
			<span>{{curTimeState}}</span>
			<span style="margin-left:50px"></span>
			<span>{{frameInfo.txt}}</span>
		</div> -->

		<!-- 可视化 -->
		<!-- <div style="padding-top:10px">
			<div style="height:100px;width:300px;border:1px solid #ccc;box-sizing: border-box;display:inline-block;vertical-align:bottom"
				class="ctrlProcessWave"></div>
			<div
				style="height:40px;width:300px;display:inline-block;background:#999;position:relative;vertical-align:bottom">
				<div class="ctrlProcessX" style="height:40px;background:#0B1;position:absolute;"
					:style="{width:powerLevel+'%'}"></div>
				<div class="ctrlProcessT" style="padding-left:50px; line-height:40px; position: relative;">
					{{ durationTxt+"/"+powerLevel }}
				</div>
			</div>
		</div> -->

		<!-- 日志输出 -->
		<!-- <div style="padding-top:10px">
			<audio class="recPlay" controls style="display:none;width:100%"></audio>
			<div class="recPlayMsg"></div>

			<div v-for="obj in reclogs" :key="obj.idx" style="border-bottom:1px dashed #666;padding:5px 0;">
				<div :style="{color:obj.color==1?'red':obj.color==2?'green':obj.color}">
					{{obj.txt}}
					<button v-if="obj.obj.play" :data-key="obj.obj.play" @click="recPlayClick">播放</button>
					<button v-if="obj.obj.down" :data-key="obj.obj.down" @click="recDownClick">下载</button>
				</div>
			</div>
		</div> -->

	</div>
</template>

<script>
	//必须引入的js
	import RTP2Voice from '@/components/x-station/components/RTP2Voice.js';

	//可视化
	import Recorder from 'recorder-core';
	import 'recorder-core/src/extensions/wavesurfer.view.js';
	//测试用的js，生成wav文件
	import 'recorder-core/src/engine/wav.js';

	export default {

		props: ['kaiguan', 'chuanruzhi'],
		data() {
			return {
				v_wsUrl: window.$yinpinUrl + "/hcRTP2VoiceWS/ws",
				// v_ip: "192.168.1.9",
				// v_port: 80,
				// v_channel: 1,
				// v_share: true,
				// v_user: "admin",
				// v_pwd: "xfc19901003",
				v_ip: "",
				v_port: null,
				v_channel: null,
				v_user: "",
				v_pwd: "",
				v_share: true,
				curTimeState: "",
				frameInfo: {
					txt: ""
				},
				durationTxt: "0",
				powerLevel: 0,
				reclogs: []
			}
		},
		mounted() {
			console.log(this.chuanruzhi)
			if (this.chuanruzhi) {
				this.qiegefangfa()
			}

		},

		beforeDestroy() {
			this.closeClick()
		},
		methods: {
			qiegefangfa() {
				const parts = this.chuanruzhi.split('|');
				this.v_ip = parts[0];
				this.v_port = parts[1];
				this.v_channel = parts[2];
				this.v_user = parts[3];
				this.v_pwd = parts[4];
				if (this.kaiguan) {
					this.openClick()
				}
			},
			openClick() {
				if (this.rtp2Voice) { //关闭老的
					this.rtp2Voice.close();
				}
				this.reclog("正在打开，请稍候...");
				this.frameInfo = {
					txt: ""
				};
				this.showUpdateTime(0, 0);

				//创建连接对象，提供配置参数
				var rv = new RTP2Voice({
					wsUrl: this.v_wsUrl //websocket地址
						,
					device: { //设备信息
						ip: this.v_ip //ip地址
							,
						port: this.v_port //端口
							,
						user: this.v_user //用户名
							,
						pwd: this.v_pwd //密码
							,
						channel: this.v_channel //要连接的通道
					},
					shareDevice: this.v_share //共享设备连接，默认共享

						,
					onDisconnect: (msg) => { //掉线时回调，可以重新open连接服务器
							if (this.rtp2Voice == rv) { //还是当前对象
								this.__open(msg); //重连
							}
						}

						,
					onPcmFrame: (pcm, sampleRate, msgObj, binary, rawBytes) => { ////接收到pcm数据回调
						//可视化显示
						var sum = 0;
						for (var i = 0; i < pcm.length; i++) sum += Math.abs(pcm[i]);
						var powerLevel = Recorder.PowerLevel(sum, pcm.length);
						this.powerLevel = powerLevel;
						this.wave.input(pcm, powerLevel, sampleRate);

						//保存测试数据
						this.pcmFrame(pcm, sampleRate, msgObj, binary, rawBytes);
					},
					onUpdateTime: (currentTime, state) => { //播放时间回调，单位ms，state：1播放中 2缓冲中 3已暂停 4已停止
						this.showUpdateTime(currentTime, state);
					}
				});
				this.rtp2Voice = rv;

				//创建可视化对象
				this.wave = Recorder.WaveSurferView({
					elem: ".ctrlProcessWave"
				});

				//调用open，封装成一个方法方便掉线时重新连接
				this.retryCount = 0;
				this.__open("");
			},
			__open(tag) {
				this.retryCount++;
				var rv = this.rtp2Voice;
				if (this.retryCount > 1) {
					var delay = Math.min(30, this.retryCount - 1);
					this.reclog(tag + "[" + delay + "秒后重试]", "#ccc");
					setTimeout(() => {
						if (this.rtp2Voice == rv) { //还是当前对象
							this.__open_();
						}
					}, delay * 1000);
				} else {
					this.__open_();
				}
			},
			__open_() {
				var rv = this.rtp2Voice;
				rv.open((data) => {
					if (this.rtp2Voice != rv) return; //不是同一个对象，退出
					this.retryCount = 1; //重置次数
					this.reclog("已打开 " + JSON.stringify(data), 2);
				}, (err) => {
					if (this.rtp2Voice != rv) return; //不是同一个对象，退出
					this.reclog("打开失败：" + err, 1);
					this.__open("打开失败"); //重试
				});
			}


			,
			closeClick() { //关闭连接
				if (this.rtp2Voice) {
					this.rtp2Voice.close();
					this.rtp2Voice = null;
					this.reclog("已关闭");

					this.saveFrameInfo();
				} else {
					this.reclog("未打开", 1);
				}
			}


			,
			pauseClick() {
				if (this.rtp2Voice) {
					this.rtp2Voice.pause();
					this.reclog("已暂停");
				} else {
					this.reclog("未打开", 1);
				}
			},
			resumeClick() {
				if (this.rtp2Voice) {
					this.rtp2Voice.resume();
					this.reclog("已继续");
				} else {
					this.reclog("未打开", 1);
				}
			}








			,
			showUpdateTime(currentTime, state) {
				if (!state) {
					this.curTimeState = "";
					this.durationTxt = "";
				} else {
					var str = ({
						1: "播放中",
						2: "缓冲中...",
						3: "已暂停",
						4: "已停止"
					})[state] || "未知状态";
					str += " " + this.formatTime(currentTime);
					this.curTimeState = str;
					this.durationTxt = this.formatTime(currentTime);
				}
			}


			,
			pcmFrame(pcm, sampleRate, msgObj, binary, rawBytes) {
				var store = this.frameInfo;
				store.count = (store.count || 0) + 1;
				store.size = (store.size || 0) + rawBytes.byteLength;
				store.txt = "接收" + store.count + "次，" + store.size + "字节";

				store.sampleRate = sampleRate;
				store.pcms = (store.pcms || []);
				store.pcms.push(pcm);

				store.binarys = (store.binarys || []);
				store.binarys.push(binary);
			},
			saveFrameInfo() {
				var store = this.frameInfo,
					pcms = store.pcms || [],
					bins = store.binarys;
				if (!pcms.length) {
					this.reclog("未搜集到pcm数据", "#ccc");
					return;
				}
				var recblob = this.recblob = this.recblob || [""];
				//将二进制保存成文件
				var blob = new Blob(bins);
				recblob.push({
					blob: blob,
					type: "pcm"
				});
				this.reclog("所有原始音频数据 " + blob.size + "字节", 0, {
					down: recblob.length - 1
				});

				//生成一个wav文件测试
				var pcm = Recorder.SampleData(pcms, store.sampleRate, store.sampleRate).data;
				var rec = Recorder({
					type: "wav",
					sampleRate: store.sampleRate,
					bitRate: 16
				});
				rec.mock(pcm, store.sampleRate);
				rec.stop((blob, duration) => {
					recblob.push({
						blob: blob,
						type: rec.set.type
					});
					this.reclog("所有pcm已转成wav " + blob.size + "字节 " + duration + "ms", 0, {
						down: recblob.length - 1,
						play: recblob.length - 1
					});
				});
			},
			recPlayClick(e) {
				var audio = document.querySelector(".recPlay");
				var msgEl = document.querySelector(".recPlayMsg");
				msgEl.innerHTML = "";
				audio.style.display = "inline-block";
				if (!(audio.ended || audio.paused)) {
					audio.pause();
				};

				var key = e.target.dataset.key;
				var o = this.recblob[key];
				if (!o) return;
				audio.onerror = function(e) {
					msgEl.innerHTML = '<span style="color:red">播放失败[' + audio.error.code + ']' + audio.error.message +
						'</span>';
				};
				audio.src = (window.URL || webkitURL).createObjectURL(o.blob);
				audio.play();
			},
			recDownClick(e) {
				var key = e.target.dataset.key;
				var o = this.recblob[key];
				if (!o) return;
				var name = Date.now() + "." + o.type;

				var downA = document.createElement("A");
				downA.innerHTML = "下载" + name;
				downA.href = (window.URL || webkitURL).createObjectURL(o.blob);
				downA.download = name;
				downA.click();
			}



			//页面上显示日志信息
			,
			reclog(msg, color, obj) {
				var now = new Date();
				var t = ("0" + now.getHours()).substr(-2) +
					":" + ("0" + now.getMinutes()).substr(-2) +
					":" + ("0" + now.getSeconds()).substr(-2);
				var txt = "[" + t + "]" + msg;
				console.log(txt);
				this.reclogs.splice(0, 0, {
					txt: txt,
					color: color,
					obj: obj || {}
				});
			},
			formatTime(ms, showSS) {
				var ss = ms % 1000;
				ms = (ms - ss) / 1000;
				var s = ms % 60;
				ms = (ms - s) / 60;
				var m = ms % 60;
				ms = (ms - m) / 60;
				var h = ms,
					v = "";
				if (h > 0) v += (h < 10 ? "0" : "") + h + ":";
				v += (m < 10 ? "0" : "") + m + ":";
				v += (s < 10 ? "0" : "") + s;
				if (showSS) v += "″" + ("00" + ss).substr(-3);;
				return v;
			}
		}
	}
</script>