  <template>
  <div style="pointer-events: auto">
				
				<x-dialog-comp-small :title="'事件详情'" @on-close="closeClick()">
					<div class="show-left">
						<div class="imgBox">
							<img class="imgCon" :src="activeImg" alt="">
						</div>
						<div style="position: relative;">
							<div class="swiper-container">
								<div class="swiper-wrapper">
									<div class="swiper-slide"
											v-for="(item,index) of headList"
											:key="index"
											@click="swipreClick(item)"
											>
											<img :src="item.imgUrl" alt="">
											<div class="tip">
												<p style="font-size:12px;">{{item.time}}</p>
											</div>
										</div>
								</div>
							</div>
							<div class="swiper-button-prev"/>
							<div class="swiper-button-next"/>
						</div>
					</div>
					<div class="show-right">
						  <div style="text-align: center;font-size:16px;"><strong>事件详情</strong></div>
						  <div><strong>事件等级:</strong></div>
						  <div><strong>发生时间:</strong></div>
						  <div><strong>结束时间:</strong></div>
						  <div><strong>所处位置:</strong></div>
						  <div><strong>事件源:</strong></div>
						  <div><strong>事件类型:</strong></div>
					</div>
				</x-dialog-comp-small>
				
  </div>
</template>

<script>
import XDialogCompSmall from "@/components/x-dialog-comp-small";
import XTitleBtn from "@/components/x-title-btn";

import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'

export default {
  name: "x-video-event-details",
	components: { XTitleBtn, XDialogCompSmall},
	
  props: ['info'],
  data: () => {
    return {
			headList:[{
			  id:1,
			  time:'',
			  imgUrl:require("@/assets/ShuNao/test.png")
			},
			{
			  id:2,
			  time:'',
			  imgUrl:require("@/assets/ShuNao/test.png")
			},
			{
			  id:3,
			  time:'',
			  imgUrl:require("@/assets/ShuNao/test.png")
			}
			],
			activeImg:require('@/assets/ShuNao/test.png'),
			activeTime:'',
		}
  },
	computed: {
	  defaultOption() {
	    return {
	      step: 0.2, // 数值越大速度滚动越快
	      limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
	      hoverStop: true, // 是否开启鼠标悬停stop
	      direction: 1, // 0向下 1向上 2向左 3向右
	      openWatch: true, // 开启数据实时监控刷新dom
	      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
	      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
	      waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
	    }
	  }
	},
  created() {
    this.setNowData()
  },
  mounted() {
		 var mySwiper = new Swiper('.swiper-container', {
			loop: true,
			// 如果需要前进后退按钮
			pagination: '.swiper-pagination',
			slidesPerView: 4,
			paginationClickable: true,
			spaceBetween: 20,
			freeMode: true,
			observer: true,
			nextButton: '.swiper-button-next',
			prevButton: '.swiper-button-prev'
		})
  },
	beforeDestroy() {
	},
  methods: {
		setNowData(){
			for(let i=0;i<this.headList.length;i++){
				this.headList[i].time =this.getNextDate(-i) 
			}
			this.activeTime = this.headList[0].time
		},
		swipreClick(item){
			console.log(item)
			this.activeImg = item.imgUrl;
			this.activeTime = item.time
		},
		//
		 getNextDate(day) { //-1代表前一天，1代表后一天 date yyyy-MM-dd
			 let  dd = new Date();
				dd.setDate(dd.getDate() + day);
				let  y = dd.getFullYear();
				let  m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
				let  d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
				let  h = dd.getHours() < 10 ? "0" + dd.getHours() : dd.getHours();
				let mm = dd.getMinutes() < 10 ? "0" + dd.getMinutes() : dd.getMinutes();
				let ss = dd.getSeconds() < 10 ? "0" + dd.getSeconds() : dd.getSeconds();
				return y + "-" + m + "-" + d;
		},
		closeClick() {
		  this.$emit('on-close')
		},
  }
}
</script>

<style scoped>
.x-tc-page-bg {
  background: url("~@/assets/common/tanChuang.png") no-repeat;
  background-size: 100% 100%;
  width: 1132px;
  height: 763px;
  position: fixed;
  top: 100px;
  left: 400px;
  z-index: 99999;
  margin-left: 55px;
}

.x-tc-title {
  font-size: 22px;
  position: absolute;
  left: 80px;
  top: 15px;
  color: white;
  letter-spacing: 5px;
}

.x-close {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 30px;
  cursor: pointer;
}

.x-tc-cnt {
  width: 1080px;
  margin-left: 25px;
  height: 700px;
  overflow: auto;
  position: absolute;
  top: 58px;
  overflow: hidden;
  background-color: white;
}
.x-table-title-list-bg{
	width: 98%;margin-left: 1%;text-align: center;line-height:40px;height:40px;
}
.x-table-title-list-bg div{
	flex: 1;
}
.x-table-title-list-bg:hover{
	background: #032e5a;
}

.show-left{
  width:630px; float: left;height: 530px; margin-left: 10px;
}
.show-right{
  width:250px;float:left;height: 460px;margin-left: 10px;
	text-align: left;line-height: 40px; font-size: 14px;
}
/deep/.swiper-container{
  background: rgba(0,0,0,0.2);
  width:570px; height: 120px;margin-top: 10px;
    position: absolute;
    left: 30px;
    top: 0;
		padding:10px;
    overflow: auto;
}
.imgCon{
    width: 100%;height: 400px;
}
/deep/.swiper-button-prev,/deep/ .swiper-button-next{
  background-size: cover;
}
/deep/.swiper-button-next, .swiper-button-prev{
  height: 120px;
  width:30px;
  position: absolute;
	cursor: pointer;
 background-position: center;

}
.swiper-button-next{
    margin-top:0;
    right:0px;
    top: 10px;
    background-color: rgba(0,0,0,0.3);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22 5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23007aff'/%3E%3C/svg%3E");
    background-size: 10px 20px;
		background-repeat: no-repeat;
}

.swiper-button-prev{
    margin-top:0;
    left: 0;
    top: 10px;
    background-color: rgba(0,0,0,0.3);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22 22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23007aff'/%3E%3C/svg%3E");
    background-size: 10px 20px;
		background-repeat: no-repeat;
}
.imgBox{
  width: 100%;
  height: 400px;
}
</style>
