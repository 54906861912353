import qsjImg from '@/assets/icon/pointImg/取水井.png'
import dcImg from '@/assets/icon/pointImg/渡槽.png'
import sydImg from '@/assets/icon/pointImg/水源地.png'
import gmqsyhImg from '@/assets/icon/pointImg/规模取水用户.png'
import drqsyhImg from '@/assets/icon/pointImg/地热取水用户.png'
import pwkImg from '@/assets/icon/pointImg/排污口.png'
import qskImg from '@/assets/icon/pointImg/取水口.png'
import jsxmImg from '@/assets/icon/pointImg/工程建设.png'
import sbjgImg from '@/assets/icon/pointImg/水保监管.png'
import dhxImg from '@/assets/icon/pointImg/倒虹吸.png'

// import chImg from '@/assets/icon/pointImg/穿涵.png'
// import ggpImg from '@/assets/icon/pointImg/公告牌.png'
// import gspImg from '@/assets/icon/pointImg/公示牌.png'
// import jtqImg from '@/assets/icon/pointImg/交通桥.png'
// import jbImg from '@/assets/icon/pointImg/界碑.png'
// import scqImg from '@/assets/icon/pointImg/生产桥.png'
// import wbImg from '@/assets/icon/pointImg/围坝.png'
// import zkImg from '@/assets/icon/pointImg/闸口.png'
// import zmImg from '@/assets/icon/pointImg/支门.png'

// import axios from "axios";
import {
	css
} from "vue-tree-list/vue.config";

const state = {
	// 永镇水库ID
	YongZhenID: 6,
	// 胜利水库ID
	ShengLiID: 5,
	// 四号水库ID
	SiHaoID: 4,
	// 灌区ID
	GuanQuID: 4,
	//水价改革告警ID （暂未使用，使用是的4是灌区id）
	GaiGeID: 4,
	//取水井
	qushuijingInfo: [],
	qushuijingEntitie: [],
	//渡槽
	duchaoInfo: [],
	duchaoEntitie: [],
	//水源地
	shuiyuandiInfo: [],
	shuiyuandiEntitie: [],
	// 规模取水用户
	guimoquyongshuihuInfo: [],
	guimoquyongshuihuEntitie: [],
	// 排污口对象
	paiwukouInfo: [],
	paiwukouEntitie: [],
	// 地热取水用户
	direqushuiyonghuInfo: [],
	direqushuiyonghuEntitie: [],
	//取水口
	qushuikouInfo: [],
	qushuikouEntitie: [],
	//河湖管理范围
	hehuguanlifanweiInfo: [],
	hehuguanlifanweiEntitie: [],
	//建设项目
	jianshexiangmuInfo: [],
	jianshexiangmuEntitie: [],
	//示范工程
	shifangongchengInfo: [],
	shifangongchengEntitie: [],
	//水保监管
	shuibaojianguanInfo: [],
	shuibaojianguanEntitie: [],
	// 倒虹吸  
	daohongxiInfo: [],
	daohongxiEntitie: [],
	// 水库围坝
	weibaInfo: [],
	weibaEntitie: [],
	// 水库范围
	fanweiInfo: [],
	fanweiEntitie: [],
	// 灌区范围
	fanweigqInfo: [],
	fanweigqEntitie: [],
	// 农村供水工程
	nongcungongshuiInfo: [],
	nongcungongshuiEntitie: [],
	// 穿涵
	chuanhanInfo: [],
	chuanhanEntitie: [],
	// 公告牌
	gonggaopaiInfo: [],
	gonggaopaiEntitie: [],
	// 公示牌
	gongshipaiInfo: [],
	gongshipaiEntitie: [],
	// 热成像
	rechengxiangInfo: [],
	rechengxiangEntitie: [],
	// 桥
	qiaoInfo: [],
	qiaoEntitie: [],
	// 界碑
	jiebeiInfo: [],
	jiebeiEntitie: [],
	// 生产桥
	shengchanqiaoInfo: [],
	shengchanqiaoEntitie: [],
	// 围坝
	daweibaInfo: [],
	daweibaEntitie: [],
	// 闸口
	zhakouInfo: [],
	zhakouEntitie: [],
	// 支门
	zhimenInfo: [],
	zhimenEntitie: [],
}

const mutations = {
	// 清空
	StorageTotal: (state) => {
		state.qushuijingInfo = [];
		state.duchaoInfo = [];
		state.shuiyuandiInfo = [];
		state.guimoquyongshuihuInfo = [];
		state.paiwukouInfo = [];
		state.direqushuiyonghuInfo = [];
		state.jianshexiangmuInfo = [];
		state.shifangongchengInfo = [];
		state.shuibaojianguanInfo = [];
		state.daohongxiInfo = [];
		state.daweibaInfo = [];
		state.nongcungongshuiInfo = [];
		state.chuanhanInfo = [];
		state.gonggaopaiInfo = [];
		state.gongshipaiInfo = [];
		state.rechengxiangInfo = [];
		state.jiaotongqiaoInfo = [];
		state.jiebeiInfo = [];
		state.shengchanqiaoInfo = [];
		state.weibaInfo = [];
		state.fanweiInfo = [];
		state.fanweigqInfo = [];
		state.qiaoInfo = [];
		state.zhakouInfo = [];
		state.zhimenInfo = [];
	},
	//取水井
	SET_QUSHUIJING: (state, val) => {
		state.qushuijingInfo.push(val);
	},
	//渡槽
	SET_DUCHAO: (state, val) => {
		state.duchaoInfo.push(val);
	},
	//水源地
	SET_SHUIYUANDI: (state, val) => {
		state.shuiyuandiInfo.push(val);
	},
	// 规模取水用户
	SET_GUIMOQUYONGSHUIHU: (state, val) => {
		state.guimoquyongshuihuInfo.push(val);
	},
	// 排污口对象
	SET_PAIWUKOU: (state, val) => {
		state.paiwukouInfo.push(val);
	},
	// 地热取水用户
	SET_DIREQUSHUIYONGHU: (state, val) => {
		state.direqushuiyonghuInfo.push(val);
	},
	//取水口
	SET_QUSHUIKOU: (state, val) => {
		state.qushuikouInfo.push(val);
	},
	//河湖管理范围
	SET_HEHUGUANLIFANWEI: (state, val) => {
		state.hehuguanlifanweiInfo.push(val);
	},
	//建设项目
	SET_JIANSHEXIANGMU: (state, val) => {
		state.jianshexiangmuInfo.push(val);
	},

	//建设项目清空
	DEL_JIANSHEXIANGMU: (state) => {
		state.jianshexiangmuInfo = [];
	},
	//移民示范工程
	SET_SHIFANGONGCHENG: (state, val) => {
		state.shifangongchengInfo.push(val);
	},

	//移民示范工程清空
	DEL_SHIFANGONGCHENG: (state) => {
		state.shifangongchengInfo = [];
	},
	//水保监管
	SET_SHUIBAOJIANGUAN: (state, val) => {
		state.shuibaojianguanInfo.push(val);
	},
	//水保监管清空
	DEL_SHUIBAOJIANGUAN: (state) => {
		state.shuibaojianguanInfo = [];
	},
	// 倒虹吸
	SET_DAOHONGXI: (state, val) => {
		state.daohongxiInfo.push(val);
	},
	// 水库围坝
	SET_WEIBA: (state, val) => {
		state.weibaInfo.push(val);
	},
	// 水库范围
	SET_FANWEI: (state, val) => {
		state.fanweiInfo.push(val);
	},
	// 水库范围
	SET_GQFANWEI: (state, val) => {
		state.fanweigqInfo.push(val);
	},
	// 农村供水工程
	SET_NONGCUNGONGSHUI: (state, val) => {
		state.nongcungongshuiInfo.push(val);
	},

	// 穿涵
	SET_CHUANHAN: (state, val) => {
		state.chuanhanInfo.push(val);
	},
	DEL_CHUANHAN: (state) => {
		state.chuanhanInfo = [];
	},
	// 公告牌
	SET_GONGGAOPAI: (state, val) => {
		state.gonggaopaiInfo.push(val);
	},
	// 公示牌
	SET_GONGSHIPAI: (state, val) => {
		state.gongshipaiInfo.push(val);
	},
	// 热成像
	SET_RECHENGXIANG: (state, val) => {
		state.rechengxiangInfo.push(val);
	},
	// 界碑
	SET_JIEBEI: (state, val) => {
		state.jiebeiInfo.push(val);
	},
	// 交通桥
	SET_JIAOTONGQIAO: (state, val) => {
		// state.jiaotongqiaoInfo.push(val);
		state.qiaoInfo.push(val);
	},
	// 生产桥
	SET_SHENGCHANQIAO: (state, val) => {
		// state.shengchanqiaoInfo.push(val);
		state.qiaoInfo.push(val);
	},
	// 闸口
	SET_ZHAKOU: (state, val) => {
		state.zhakouInfo.push(val);
	},
	// 支门
	SET_ZHIMEN: (state, val) => {
		state.zhimenInfo.push(val);
	},
}


const actions = {

	showQiTaOfIds({
		commit
	}, ids) {

		// console.log("项目78678687",ids)
		// console.log("项目项目项目项目项目",state)
		for (let item of state[ids[2]]) {
			viewer.entities.remove(item)
		}
		state[ids[2]] = [];
		if (ids[0].length === 0) {
			return;
		}
		for (let item of state[ids[1]]) {
			// console.log("项目7+++++++++",item.id)
			if (ids[0].includes(item.id)) {
				// console.log("项目7+++8748++++++",item.id)
				let entitie = addEntitiePoint(item.name, item.longitude, item.latitude, item.img, item.name, 0.11,
					() => {
						if (ids[2] == 'jianshexiangmuEntitie') {
							window.jianshexiangmuClick(item)
						}
						 else if (ids[2] == 'shifangongchengEntitie') {
							window.shifangongchengClick(item)
						}
						else if (ids[2] == 'shuibaojianguanEntitie') {
							window.shuibaoClick(item)
						}
						else if (ids[2] == 'rechengxiangEntitie') {
							window.ShangQingClick(item)
						} else {
							window.qitaClick(item)
						}
					});
				entitie.treeId = item.id;
				//entitie.show = false;
				state[ids[2]].push(entitie)
			}
		}
	},

	// 取水井
	// showQuShuiJingOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.qushuijingEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.qushuijingEntitie = [];
	// 	if (ids.length === 0) {
	// 		return;
	// 	}
	// 	for (let item of state.qushuijingInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let entitie = addEntitiePoint(item.name, item.longitude, item.latitude, qsjImg, item.name, 0.11, () => {
	// 				window.qitaClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.qushuijingEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 渡槽
	// showDuChaoOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.duchaoEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.duchaoEntitie = [];
	// 	if (ids.length === 0) {
	// 		return;
	// 	}
	// 	for (let item of state.duchaoInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let entitie = addEntitiePoint(item.name, item.longitude, item.latitude, dcImg, item.name, 0.11, () => {
	// 				window.qitaClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.duchaoEntitie.push(entitie)
	// 		}
	// 	}
	// },

	//水源地
	// showShuiYuanDiOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.shuiyuandiEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.shuiyuandiEntitie = [];
	// 	if (ids.length === 0) {
	// 		return;
	// 	}
	// 	for (let item of state.shuiyuandiInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let entitie = addEntitiePoint(item.name, item.longitude, item.latitude, sydImg, item.name, 0.11, () => {
	// 				window.qitaClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.shuiyuandiEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 规模取水用户
	// showGuiMoYongShuiHuOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.guimoquyongshuihuEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.guimoquyongshuihuEntitie = [];
	// 	if (ids.length === 0) {
	// 		return;
	// 	}
	// 	for (let item of state.guimoquyongshuihuInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let entitie = addEntitiePoint(item.name, item.longitude, item.latitude, gmqsyhImg, item.name, 0.11, () => {
	// 				window.qitaClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.guimoquyongshuihuEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 排污口对象
	// showPaiWuKouOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.paiwukouEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.paiwukouEntitie = [];
	// 	if (ids.length === 0) {
	// 		return;
	// 	}
	// 	for (let item of state.paiwukouInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let entitie = addEntitiePoint(item.name, item.longitude, item.latitude, pwkImg, item.name, 0.11, () => {
	// 				window.qitaClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.paiwukouEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 地热取水用户
	// showDiReQuShuiYongHuOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.direqushuiyonghuEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.direqushuiyonghuEntitie = [];
	// 	if (ids.length === 0) {
	// 		return;
	// 	}
	// 	for (let item of state.direqushuiyonghuInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let entitie = addEntitiePoint(item.name, item.longitude, item.latitude, drqsyhImg, item.name, 0.11, () => {
	// 				window.qitaClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.direqushuiyonghuEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 取水口
	// showQuShuiKouOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.qushuikouEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.qushuikouEntitie = [];
	// 	if (ids.length === 0) {
	// 		return;
	// 	}
	// 	for (let item of state.qushuikouInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let entitie = addEntitiePoint(item.name, item.longitude, item.latitude, qskImg, item.name, 0.11, () => {
	// 				window.qitaClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.qushuikouEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 建设项目
	// showJianSheXiangMuOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.jianshexiangmuEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.jianshexiangmuEntitie = [];
	// 	if (ids.length === 0) {
	// 		return;
	// 	}
	// 	for (let item of state.jianshexiangmuInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let entitie = addEntitiePoint(item.name, item.longitude, item.latitude, jsxmImg, item.name, 0.11, () => {
	// 				window.jianshexiangmuClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.jianshexiangmuEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// // 根据ID显示水保监管
	// ShuiBaoJianGuanOfId({
	// 	commit
	// }, id) {
	// 	console.log(state)
	// 	for (let item of state.shuibaojianguanInfo) {
	// 		if (item.id == id) {
	// 			item.show = true;
	// 			item.treeId = item.id
	// 			console.log(item)
	// 			viewer.mars.flyTo(item, {
	// 				scale: 2.5,
	// 				radius: 2000
	// 			});

	// 			window.setTimeout(function () {
	// 				// item.name.show = false;
	// 			}, 5000);
	// 			return;
	// 		}
	// 	}
	// },
	// // 水保监管
	showShuiBaoJianGuanOfIds({
		commit
	}, ids) {
		for (let item of state.shuibaojianguanEntitie) {
			viewer.entities.remove(item)
		}
		state.shuibaojianguanEntitie = [];
		if (ids.length === 0) {
			return;
		}
		for (let item of state.shuibaojianguanInfo) {
			if (ids.includes(item.id)) {
				let entitie = addEntitiePoint(item.name, item.longitude, item.latitude, item.img, item.name, 0.11,
					() => {
						window.shuibaoClick(item)
					});
				entitie.treeId = item.id;
				//entitie.show = false;
				state.shuibaojianguanEntitie.push(entitie)
			}
		}
	},

	// 倒虹吸
	// showDaoHongXiOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.daohongxiEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.daohongxiEntitie = [];
	// 	if (ids.length === 0) {
	// 		return;
	// 	}
	// 	for (let item of state.daohongxiInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let entitie = addEntitiePoint(item.name, item.longitude, item.latitude, dhxImg, item.name, 0.11, () => {
	// 				window.qitaClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.daohongxiEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 农村供水工程
	// showNongCunGongShuiOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.nongcungongshuiEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.nongcungongshuiEntitie = [];
	// 	if (ids.length === 0) {
	// 		return;
	// 	}
	// 	for (let item of state.nongcungongshuiInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let entitie = addEntitiePoint(item.name, item.longitude, item.latitude, item.img, item.name, 0.11, () => {
	// 				window.qitaClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.nongcungongshuiEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 河湖管理范围
	showHeHuGuanLiFanWeiOfIds({
		commit
	}, ids) {
		for (let item of state.hehuguanlifanweiEntitie) {
			viewer.entities.remove(item)
		};
		state.hehuguanlifanweiEntitie = [];

		for (let item of state.hehuguanlifanweiInfo) {
			if (ids.includes(item.id)) {
				let name = item.name;
				let id = item.id;
				if (item.space == "" || item.space == null) {
					continue;
				}
				let fanWeiData = JSON.parse(item.space);
				if (fanWeiData.hasOwnProperty("space")) {
					continue;
				}
				let positions = [];
				if (!fanWeiData) {
					continue;
				}
				let coordinates;
				if (fanWeiData.geometry.coordinates.length > 1) {
					coordinates = fanWeiData.geometry.coordinates
				} else {
					coordinates = fanWeiData.geometry.coordinates[0]
				}
				for (let i = 0, len = coordinates.length; i < len; i++) {
					positions.push(
						coordinates[i][0], coordinates[i][1], 30
					);
				}

				let entitie = viewer.entities.add({
					name: name,
					polyline: {
						positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
						width: 1,
						material: new Cesium.PolylineDashMaterialProperty({
							color: Cesium.Color.CYAN
						})
					},
					popup: {
						html: name,
						anchor: [0, -12], //左右、上下的偏移像素值。
						onAdd: function(eleId) {
							window._popup_eleId = eleId;
						},
					},
					click: function(e) {}
				});
				entitie.treeId = item.id;
				//entitie.show = false;
				state.hehuguanlifanweiEntitie.push(entitie)
			}
		}
	},

	// 水库围坝
	showShuiKuWeiBaOfIds({
		commit
	}, ids) {
		// console.log(state.weibaInfo)
		// console.log(state.weibaEntitie)
		for (let item of state.weibaEntitie) {
			viewer.entities.remove(item)
		};
		state.weibaEntitie = [];

		for (let item of state.weibaInfo) {
			if (ids.includes(item.id)) {
				let name = item.name;
				let id = item.id;
				if (item.space == "" || item.space == null) {
					continue;
				}
				let fanWeiData = JSON.parse(item.space);
				if (fanWeiData.hasOwnProperty("space")) {
					continue;
				}
				let positions = [];
				if (!fanWeiData) {
					continue;
				}
				let coordinates;
				if (fanWeiData.geometry.coordinates.length > 1) {
					coordinates = fanWeiData.geometry.coordinates
				} else {
					coordinates = fanWeiData.geometry.coordinates[0]
				}
				for (let i = 0, len = coordinates.length; i < len; i++) {
					positions.push(
						coordinates[i][0], coordinates[i][1], 30
					);
				}
				// #f3890880
				let entitie = viewer.entities.add({
					name: name,
					polyline: {
						positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
						width: 1,
						material: new Cesium.PolylineDashMaterialProperty({
							color: Cesium.Color.lightgreen
						})
					},
					popup: {
						html: name,
						anchor: [0, -12], //左右、上下的偏移像素值。
						onAdd: function(eleId) {
							window._popup_eleId = eleId;
						},
					},
					click: function(e) {}
				});
				entitie.treeId = item.id;
				//entitie.show = false;
				state.weibaEntitie.push(entitie)
			}
		}
	},
	
	// 水库范围、灌区范围隐藏
	hiddenfanweiInfo({ commit }, data = false) {
		console.log("=======水库范围、灌区范围隐藏隐藏========")
		console.log(state.fanweiEntitie)
		for (let item of state.fanweiEntitie) {
			item.show = data;
		}
	},
	// 隐藏重要地址
	// hiddenfanweiInfo({
	// 	commit
	// }) {
	// 	for (let item of state.fanweiEntitie) {
	// 		if (item.show == true) {
	// 			item.show = false
	// 		} else {
	// 			item.show = true
	// 		}
	// 	}
	// },
	
	// 水库范围、灌区范围
	showShuiKuFanWeiOfIds({
		commit
	}, ids) {
		console.log("=======水库范围、灌区范围========")
		for (let item of state.fanweiEntitie) {
			viewer.entities.remove(item);
		}
		state.fanweiEntitie = [];
		for (let item of state.fanweiInfo) {
			if (ids.includes(item.id)) {
				let name = item.name;
				let id = item.id;
				if (item.space == "" || item.space == null) {
					console.log("item.space == null-----跳出")
					continue;
				}
				let fanWeiData = JSON.parse(item.space);
				if (fanWeiData.hasOwnProperty("space")) {
					console.log("fanWeiData.hasOwnProperty-----跳出")
					continue;
				}
				let positions = [];
				if (!fanWeiData) {
					console.log("!fanWeiData-----跳出")
					continue;
				}
				let coordinates;
				if (fanWeiData.features[0].geometry.type == "Polygon") { //胜利水库格式类型
					coordinates = fanWeiData.features[0].geometry.coordinates[0];
				} else if (fanWeiData.features[0].geometry.type == "MultiLineString") { //灌区格式类型
					coordinates = fanWeiData.features[0].geometry.coordinates[0]; 
					
				} else { //永镇水库格式类型
					coordinates = fanWeiData.features[0].geometry.coordinates[0][0];
				}
				for (let i = 0, len = coordinates.length; i < len; i++) {
					positions.push(coordinates[i][0], coordinates[i][1]);
				}
				var extent = {
					xmin: 73.0,
					xmax: 136.0,
					ymin: 3.0,
					ymax: 59.0
				}
				var geojson = {
					"type": "Feature",
					"geometry": {
						"type": "MultiPolygon",
						"coordinates": [
							[
								[
									[extent.xmin, extent.ymax],
									[extent.xmin, extent.ymin],
									[extent.xmax, extent.ymin],
									[extent.xmax, extent.ymax],
									[extent.xmin, extent.ymax]
								],
								coordinates
							]
						]
					}
				}
				var layer = new mars3d.layer.GeoJsonLayer(viewer, {
					"data": geojson,
					"symbol": {
						"styleOptions": {
							"fill": true,
							"color": "rgba(20, 44, 80, 0.3)",
							"opacity": 0.7,
							"outline": true,
							"outlineColor": "#ffffff",
							"outlineWidth": 10,
							"outlineOpacity": 0,
							"arcType": Cesium.ArcType.GEODESIC,
							"clampToGround": true
						}
					},
					"visible": true
				});
				for (let i = 0, len = coordinates.length; i < len; i++) {
					positions.push(coordinates[i][0], coordinates[i][1]);
				}
				let fillColor = Cesium.Color.TRANSPARENT; // 填充颜色设为透明
				let outlineColor = Cesium.Color.fromCssColorString("#0055ff90"); // 边框颜色
				let outlineWidth = 5.0; // 边框宽度

				// let polygonEntity = viewer.entities.add({
				// 	name: name,
				// 	polygon: {
				// 		hierarchy: Cesium.Cartesian3.fromDegreesArray(positions),
				// 		material: fillColor
				// 	}
				// });

				// let outlineEntity = viewer.entities.add({
				// 	name: name + "_outline",
				// 	polyline: {
				// 		positions: Cesium.Cartesian3.fromDegreesArray(positions),
				// 		width: outlineWidth,
				// 		material: outlineColor
				// 	}
				// });
				
			}
		}
	},
	// // 灌区范围
	showguanquFanWeiOfIdsguanqu({
		commit
	}, ids) {
		console.log("=======灌区范围========")
		for (let item of state.fanweigqEntitie) {
			viewer.entities.remove(item);
		}
		state.fanweigqEntitie = [];
		for (let item of state.fanweigqInfo) {
			if (ids.includes(item.id)) {
				let name = item.name;
				let id = item.id;
				if (item.space == "" || item.space == null) {
					console.log("item.space == null-----跳出")
					continue;
				}
				let fanWeiData = JSON.parse(item.space);
				if (fanWeiData.hasOwnProperty("space")) {
					console.log("fanWeiData.hasOwnProperty-----跳出")
					continue;
				}
				let positions = [];
				if (!fanWeiData) {
					console.log("!fanWeiData-----跳出")
					continue;
				}
				let coordinates;
				if (fanWeiData.features[0].geometry.type == "Polygon") { //胜利水库格式类型
					coordinates = fanWeiData.features[0].geometry.coordinates[0];
				} else if (fanWeiData.features[0].geometry.type == "MultiLineString") { //灌区格式类型
					coordinates = fanWeiData.features[0].geometry.coordinates[0]; 
					
				} else { //永镇水库格式类型
					coordinates = fanWeiData.features[0].geometry.coordinates[0][0];
				}
				for (let i = 0, len = coordinates.length; i < len; i++) {
					positions.push(coordinates[i][0], coordinates[i][1]);
				}
				var extent = {
					xmin: 73.0,
					xmax: 136.0,
					ymin: 3.0,
					ymax: 59.0
				}
				var geojson = {
					"type": "Feature",
					"geometry": {
						"type": "MultiPolygon",
						"coordinates": [
							[
								[
									[extent.xmin, extent.ymax],
									[extent.xmin, extent.ymin],
									[extent.xmax, extent.ymin],
									[extent.xmax, extent.ymax],
									[extent.xmin, extent.ymax]
								],
								coordinates
							]
						]
					}
				}
				var layer = new mars3d.layer.GeoJsonLayer(viewer, {
					"data": geojson,
					"symbol": {
						"styleOptions": {
							"fill": true,
							"color": "rgba(20, 44, 80, 0.3)",
							"opacity": 0.7,
							"outline": true,
							"outlineColor": "#ffffff",
							"outlineWidth": 10,
							"outlineOpacity": 0,
							"arcType": Cesium.ArcType.GEODESIC,
							"clampToGround": true
						}
					},
					"visible": true
				});
				for (let i = 0, len = coordinates.length; i < len; i++) {
					positions.push(coordinates[i][0], coordinates[i][1]);
				}
				let fillColor = Cesium.Color.TRANSPARENT; // 填充颜色设为透明
				let outlineColor = Cesium.Color.fromCssColorString("#0055ff90"); // 边框颜色
				let outlineWidth = 5.0; // 边框宽度
	
				// let polygonEntity = viewer.entities.add({
				// 	name: name,
				// 	polygon: {
				// 		hierarchy: Cesium.Cartesian3.fromDegreesArray(positions),
				// 		material: fillColor
				// 	}
				// });
	
				// let outlineEntity = viewer.entities.add({
				// 	name: name + "_outline",
				// 	polyline: {
				// 		positions: Cesium.Cartesian3.fromDegreesArray(positions),
				// 		width: outlineWidth,
				// 		material: outlineColor
				// 	}
				// });
				
			}
		}
	},
	
	
	
	// 桥
	qiaoOfIds({
		commit
	}, ids) {
		console.log(state)
		for (let item of state.qiaoEntitie) {
			viewer.entities.remove(item)
		};
		state.qiaoEntitie = [];

		for (let item of state.qiaoInfo) {
			if (ids.includes(item.id)) {
				let name = item.name;
				let id = item.id;
				if (item.space == "" || item.space == null) {
					continue;
				}
				let fanWeiData = JSON.parse(item.space);
				if (fanWeiData.hasOwnProperty("space")) {
					continue;
				}
				let positions = [];
				if (!fanWeiData) {
					continue;
				}
				let coordinates;
				if (fanWeiData.geometry.coordinates.length > 1) {
					coordinates = fanWeiData.geometry.coordinates
				} else {
					coordinates = fanWeiData.geometry.coordinates[0]
				}
				for (let i = 0, len = coordinates.length; i < len; i++) {
					positions.push(
						coordinates[i][0], coordinates[i][1], 30
					);
				}
				// #f3890880
				let entitie = viewer.entities.add({
					name: name,
					polyline: {
						positions: Cesium.Cartesian3.fromDegreesArrayHeights(positions),
						width: 2,
						material: new Cesium.ColorMaterialProperty(Cesium.Color.fromCssColorString(
							"#D2691E"))
					},
					popup: {
						html: name,
						anchor: [0, -12], //左右、上下的偏移像素值。
						onAdd: function(eleId) {
							window._popup_eleId = eleId;
						},
					},
					click: function(e) {}
				});
				entitie.treeId = item.id;
				//entitie.show = false;
				state.qiaoEntitie.push(entitie)
			}
		}
	},

	// 根据info飞行
	flyInfoOfId({
		commit
	}, info) {
		var id = info[0];
		var infoEntitle = info[1];
		for (let item of infoEntitle) {
			if (item.treeId === id) {
				viewer.mars.flyTo(item, {
					scale: 2.5,
					radius: 2000
				});
				// item.name.show = true;
				window.setTimeout(function() {
					// item.name.show = false;
				}, 5000);
				return;
			}
		}
	},
};


export default {
	namespaced: true,
	state,
	mutations,
	actions
}

/**
 * 添加点位数据
 * @param name
 * @param lon
 * @param lat
 * @param pointImg
 * @param html
 */
function addEntitiePoint(name, lon, lat, pointImg, html, scale = 0.11, callback) {
	let entitie = viewer.entities.add({
		position: Cesium.Cartesian3.fromDegrees(lon, lat),
		show: true,
		billboard: {
			image: pointImg,
			scale: 0.29, //原始大小的缩放比例
			horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
			verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
			scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 8.0e6, 0.1),
			disableDepthTestDistance: Number.POSITIVE_INFINITY, //一直显示，不被地形等遮挡
		},
		label: {
			show: false,
			text: name,
			font: "normal small-caps normal 19px 楷体",
			style: Cesium.LabelStyle.FILL_AND_OUTLINE,
			fillColor: Cesium.Color.fromCssColorString("#ffffff").withAlpha(1),
			outlineWidth: 2,
			horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
			verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
			pixelOffset: new Cesium.Cartesian2(20, -10), //偏移量
			heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, //贴地
		},
		tooltip: {
			html: name,
			anchor: [0, -10],
		},
		/* popup: {
			html: html,
			anchor: [0, -12], //左右、上下的偏移像素值。
			onAdd: function(eleId) {
				window._popup_eleId = eleId;
			},
		}, */
		click: function(e) { //单击后的回调
			callback();
		}
	});
	return entitie;
}