<template>
	<div style="pointer-events: auto;z-index: 1000;">
		<x-dialog-comp title="声纹监测" @on-close="$emit('on-close')">
			<x-title-btn :menu-data.sync="titleMenuData"></x-title-btn>
			<div v-if="titleMenuData[0].checkType">
				<!-- <div class="x-table-title-bg x-tab-title-bg" style="width:100%;text-align: center;font-size: 14px">
					<div style="flex:1"><strong>名称</strong></div>
					<div style="flex:1"><strong>数值</strong></div>
				</div>

				<div style="height: 680px;overflow-y: auto;overflow-x: hidden;font-size: 14px" class="cursor">
					<div v-for="(item,index) in resData" ref="resDataListRef">
						<div class="x-table-title-list-bg"
							style="width: 98%;margin-left: 1%;text-align: center;line-height:40px;height:40px;">
							<div style="flex:1">{{ item.name }}</div>
							<div style="flex:1">{{ item.value }}</div>
							<div style="flex:1" v-if="shangqingShow">{{ item.yuzhi }}</div>
						</div>
					</div>
				</div> -->
				<div style="float: left;width:100%; height: 100%;padding:10px 10px;">
					<shengwenzujian :kaiguan="kaiguan" :chuanruzhi="chuanruzhi"></shengwenzujian>
				</div>
				
			</div>

		</x-dialog-comp>
	</div>
</template>

<script>
	import shengwenzujian from "@/components/x-station/components/shengwenzujian";
	import XDialogComp from "@/components/x-dialog-comp";
	import HZEcharts from "@/components/hz-echarts/echarts";
	import XTitleBtn from "@/components/x-title-btn";
	export default {
		name: "x-device-ShengWen",
		props: ['compData', 'titleName'],

		components: {
			XTitleBtn,
			XDialogComp,
			HZEcharts,
			shengwenzujian,
		},

		data: () => {
			return {
				kaiguan: true,
				chuanruzhi: "192.168.1.9|80|0|admin|xfc19901003",
				bzpt: false,
				bzptId: null, //选中的id
				resData: [{
					name: '声纹',
					value: '正常',
				}],
				shangqingShow: false,
				titleMenuData: [{
					checkType: true,
					name: '实时数据',
				}],
			}
		},
		created() {},
		mounted() {

		},
		beforeDestroy() {},
		methods: {


		}
	}
</script>

<style scoped>
	.x-tc-page-bg {
		background: url("~@/assets/common/tanChuang.png") no-repeat;
		background-size: 100% 100%;
		width: 1132px;
		height: 763px;
		position: fixed;
		top: 100px;
		left: 400px;
		z-index: 99999;
		margin-left: 55px;
	}

	.x-tc-page-twobg {
		background: url("~@/assets/common/tanChuang.png") no-repeat;
		background-size: 100% 100%;
		width: 1132px;
		height: 763px;
		position: fixed;
		top: 100px;
		left: 400px;
		z-index: 99999;
		margin-left: 55px;
	}

	.x-tc-title {
		font-size: 22px;
		position: absolute;
		left: 80px;
		top: 15px;
		color: white;
		letter-spacing: 5px;
	}

	.x-close {
		width: 50px;
		height: 50px;
		position: absolute;
		right: 30px;
		cursor: pointer;
	}

	.x-tc-cnt {
		height: 680px;
		width: 1050px;
		overflow: auto;
	}

	.x-card {
		width: 230px;
		height: 270px;
		margin-right: 33px;
		float: left;
	}

	.x-card:nth-child(4n) {
		margin-right: 0px;
	}

	.x-card-cnt {
		width: 250px;
		height: 230px;
	}

	.x-sm-title-bg {
		background: url("~@/assets/ZhiNengXiTong/title.png") no-repeat;
		background-size: 100% 100%;
		width: 220px;
		height: 35px;
		display: flex;
		align-items: center;
		padding-left: 20px;
		font-family: douyuFont-2;
		font-size: 14px;
	}
</style>