<template>
	<div>
		<div style="float: left;margin-top: 15px;margin-left: 10px;">
			<el-select v-model="shijiangeshixuanze" placeholder="请选择" class="shijianqiehuande">
				<el-option v-for="item in shijiangeshiqiehuan" :key="item.value" :label="item.label"
					:value="item.value">
				</el-option>
			</el-select>
		</div>
		<div v-if="this.shijiangeshixuanze==1" style="float: left;">
			<nianduxuanze :xuanzedenian="xuanzedenian" :getlist="getlist" :getdaochu="getdaochu"
				@change="costxuanzedenian">
			</nianduxuanze>
		</div>
		<div v-if="this.shijiangeshixuanze==2" style="float: left;">
			<shijianxuanze :beginTime="beginTime" :endTime="endTime" :getdaochu="getdaochu" :getlist="getlist"
				@change="costPlannedAmountChange">
			</shijianxuanze>
		</div>
		<div class="x-tc-cnt" style="margin-left: 20px;" v-loading="!dataFlag">
			<div v-if="this.shebeixuanzedeid != null && this.shebeixuanzedeid != ''">
				<div class="x-card" v-for="(item, index) in historyData" v-if="dataFlag" :key="index"
					@click="dianjiecharts(item,index)">
					<div class="x-sm-title-bg">{{ item.title }}</div>
					<HZEcharts class="x-card-cnt" width="250px" height="210px"
						:chartData="{ xAxisData: item.dateList, seriesData: item.valueList }"></HZEcharts>
				</div>
			</div>
			<div v-else>

				<span style="
	  				color: #218fce;
	  				width: 100%;
	  				height: 600px;
	  				display: flex;
	  				justify-content: center;
	  				align-items: center;
	  				flex-direction: column;
	  				font-size: 19px;
	  				text-align: center
	  				">暂无设备</span>
			</div>
		</div>

	</div>
</template>

<script>
	import jiankongbofang from "@/components/x-video/jiankongbofang";
	import XDialogComp from "@/components/x-dialog-comp";
	import HZEcharts from "@/components/hz-echarts/echarts";
	import shishiecharts from "@/components/hz-echarts/shishiecharts";
	import XAnQuanFenXi from "@/components/x-AnQuanFenXi/yututanchaung";

	import liebiao from "@/components/x-AnQuanFenXi/liebiao";

	import shijianxuanze from "@/components/x-device-comp/shijianxuanze";
	import nianduxuanze from "@/components/x-device-comp/nianduxuanze";

	import fangdaecharts from "@/components/hz-echarts/fangdaecharts";

	import gongcheng from "@/components/x-device-ShangQing/tanchuang/gongcheng";
	export default {
		name: "x-liShiJiLu",
		props: ['deviceid', 'stationInfo'],
		components: {
			gongcheng,
			fangdaecharts,
			XDialogComp,
			HZEcharts,
			shishiecharts,
			shijianxuanze,
			nianduxuanze,
			XAnQuanFenXi,
			jiankongbofang,
			liebiao,
			// chakantupian,
		},
		data() {
			return {
				total: 0,
				resData: [],
				searchData: {
					limit: 10,
					offset: 0,
				},
				xialashebeilist: [],
				shishishebeilist: [],
				shebeixuanzedeid: this.deviceid,
				shishishebeiid: this.deviceid,
				shijiangeshiqiehuan: [{
					value: 1,
					label: '年度选择'
				}, {
					value: 2,
					label: '时间段选择'
				}],
				shijiangeshixuanze: 1,
				jiankongxuanze: null,
				xuanzedenian: '',

				loadingType: true,
				selectTitle: "", //选中的标题
				zhutitle: "",
				menuData: [],
				dataFlag: false,
				resDataFlag: false,
				historyData: [], //历史
				fdec: false,
				fdecname: "", //选中的id
				fdecvalueList: [],
				fdecdateList: [],
				dakaidexiabiao: -1,
				beginTime: "",
				endTime: '',

			}
		},

		created() {
		},

		mounted() {
			console.log('进入历史页面')
			console.log(this.shebeixuanzedeid)
			console.log(this.stationInfo)
			// this.initData();

		},

		beforeDestroy() {

		},

		methods: {


			dianguandiaocharts() {
				this.fdec = false;
			},
			dianjiecharts(data, index) {
				this.fdecname = data.title;
				this.fdecvalueList = data.valueList;
				this.fdecdateList = data.dateList;
				this.yuzhidelist = [];
				console.log(data)
				this.dakaidexiabiao = index
				console.log(this.dakaidexiabiao)
				this.fdec = true;
			},
			dakaiecharts(data, index) {
				this.fdecname = data.name;
				this.fdecvalueList = data.recentData.valueList;
				this.fdecdateList = data.recentData.dateList;
				this.yuzhidelist = data.yuzhiList
				console.log(data)
				this.dakaidexiabiao = index
				console.log(this.dakaidexiabiao)
				this.fdec = true;
			},
			getlist() {
				console.log(this.stationInfo)
				console.log(this.stationInfo.dataDevices)
				console.log(this.stationInfo.categoryId)
				console.log(this.beginTime)
				console.log(this.endTime)
				console.log(this.xuanzedenian)
				console.log(this.shebeixuanzedeid)
				if (this.shebeixuanzedeid != '' && this.shebeixuanzedeid != null) {

					if (this.shijiangeshixuanze == 1) {
						this.dataFlag = false;
						this.$request({
							url: `${window.$baseUrl_hz_bz}/api/guanqu/historyRecordFromYear?devId=${this.shebeixuanzedeid}&categoryId=${this.stationInfo.categoryId}&year=${this.xuanzedenian}`,
							type: 'get',
						}).then((val) => {
							this.historyData = val.data;
							this.dataFlag = true;


							if (this.fdec == true) {
								this.fdecname = this.historyData[this.dakaidexiabiao].title;
								this.fdecvalueList = this.historyData[this.dakaidexiabiao].valueList;
								this.fdecdateList = this.historyData[this.dakaidexiabiao].dateList;
								console.log("执行方法", this.dakaidexiabiao)
							}
						});

					} else {
						this.dataFlag = false;
						this.$request({
							url: `${window.$baseUrl_hz_bz}/api/guanqu/historyRecordById?devId=${this.shebeixuanzedeid}&categoryId=${this.stationInfo.categoryId}&beginTime=${this.beginTime}&endTime=${this.endTime}`,
							type: 'get',
						}).then((val) => {
							this.historyData = val.data;
							this.dataFlag = true;


							if (this.fdec == true) {
								this.fdecname = this.historyData[this.dakaidexiabiao].title;
								this.fdecvalueList = this.historyData[this.dakaidexiabiao].valueList;
								this.fdecdateList = this.historyData[this.dakaidexiabiao].dateList;
								console.log("执行方法", this.dakaidexiabiao)
							}
						});
					}
				} else {

					this.dataFlag = true;
				}
			},
			/**
			 * @Description: 获取一段时间内的平均数据 http://39.107.140.142:8903
			 * @Author: LiYang
			 * @Date:  2022/9/16  0:48
			 */
			initData() {
				this.$request({
					url: '/RequestRelay',
					method: 'post',
					headers: {
						isToken: false
					},
					data: {
						url: `${window.$baseUrl_hz_sk}/TjcUpbuMEk.php/irriinfo/statistics/location/index?devId=1456&sort=id&order=desc&offset=${this.searchData.offset}&limit=${this.searchData.limit}&filter=%7B%7D&op=%7B%7D&_=1663345472693`,
						type: 'get',
					}
				}).then((val) => {
					let data = JSON.parse(val.msg);
					this.resData = data.rows;
					this.total = data.total;
				});
			},

			async costPlannedAmountChange(value1, value2) {
				this.beginTime = value1
				this.endTime = value2
				console.log("beginTime" + this.beginTime)
				console.log("endTime" + this.endTime)
			},
			async costxuanzedenian(value1) {
				this.xuanzedenian = value1
				console.log("xuanzedenian" + this.xuanzedenian)
			},

			getdaochu() {
				console.log("+++++++++接口+++++++++")
				console.log("beginTime" + this.beginTime)
				console.log("startTime" + this.startTime)
				console.log("+++++++++接口+++++++++")
				if (this.shijiangeshixuanze == 1) {
					this.download('/api/guanqu/historyRecordFromYearExport', {
						devId: this.shebeixuanzedeid,
						categoryId: this.stationInfo.categoryId,
						year: this.xuanzedenian,
					}, `${this.zhutitle}_${new Date().getTime()}.xlsx`)
				} else {
					this.download('/api/guanqu/historyRecordByIdExport', {
						devId: this.shebeixuanzedeid,
						categoryId: this.stationInfo.categoryId,
						beginTime: this.beginTime,
						endTime: this.endTime,
					}, `${this.zhutitle}_${new Date().getTime()}.xlsx`)
				}

			},


			// 分页改变
			handleSizeChange(val) {
				this.searchData.limit = val;
				this.initData();
			},

			// 分页改变
			handleCurrentChange(val) {
				this.searchData.offset = (val - 1) * 10;
				this.initData();
			},

		}
	}
</script>

<style>
	.shebeixuanze .el-select .el-input .el-select__caret {
		color: #ffffff;
		font-size: 16px;
		margin-top: 5px;
	}

	.shebeixuanze .el-input--suffix .el-input__inner {
		width: 130px;
	}

	.shebeixuanze .el-select>.el-input {
		display: block;
		width: 75px;
	}

	.shebeixuanze .el-input__icon {
		line-height: 1 !important;
	}

	.shebeixuanze .el-input__inner {
		background-color: #1a6e9f;
		color: #fff;
		border: 0px solid #fff;
	}

	.shebeixuanze .el-input--prefix .el-input__inner {
		padding-left: 10px !important;
		height: 30px;
		margin-top: 4px;
	}

	.shebeixuanze .el-input--suffix .el-input__inner {
		padding-right: 10px !important;
		height: 30px;
	}


	.shebeixuanze .rq .el-date-editor.el-input,
	.shebeixuanze .el-date-editor.el-input__inner {
		width: 120px !important;
	}




	.jiankongxiala .el-select .el-input .el-select__caret {
		color: #ffffff;
		font-size: 16px;
		margin-top: 5px;
	}

	.jiankongxiala .el-input--suffix .el-input__inner {
		width: 190px;
	}

	.jiankongxiala .el-select>.el-input {
		display: block;
		width: 75px;
	}

	.jiankongxiala .el-input__icon {
		line-height: 1 !important;
	}

	.jiankongxiala .el-input__inner {
		background-color: #1a6e9f;
		color: #fff;
		border: 0px solid #fff;
	}

	.jiankongxiala .el-input--prefix .el-input__inner {
		padding-left: 10px !important;
		height: 30px;
		margin-top: 4px;
	}

	.jiankongxiala .el-input--suffix .el-input__inner {
		padding-right: 10px !important;
		height: 30px;
	}


	.jiankongxiala .rq .el-date-editor.el-input,
	.jiankongxiala .el-date-editor.el-input__inner {
		width: 120px !important;
	}




	.shijianqiehuande .el-select .el-input .el-select__caret {
		color: #ffffff;
		font-size: 16px;
		margin-top: 5px;
	}

	.shijianqiehuande .el-input--suffix .el-input__inner {
		width: 110px;
	}

	.shijianqiehuande .el-select>.el-input {
		display: block;
		width: 75px;
	}

	.shijianqiehuande .el-input__icon {
		line-height: 1 !important;
	}

	.shijianqiehuande .el-input__inner {
		background-color: #1a6e9f;
		color: #fff;
		border: 0px solid #fff;
	}

	.shijianqiehuande .el-input--prefix .el-input__inner {
		padding-left: 10px !important;
		height: 30px;
		margin-top: 4px;
	}

	.shijianqiehuande .el-input--suffix .el-input__inner {
		padding-right: 10px !important;
		height: 30px;
	}


	.shijianqiehuande .rq .el-date-editor.el-input,
	.shijianqiehuande .el-date-editor.el-input__inner {
		width: 120px !important;
	}

	.shishixz .el-input__inner {
		height: 37px;
		background-color: #1a6e9f00 !important;
		color: #fff !important;
		border: 0px solid #fff !important;
		font-size: 20px;
		text-align: center;
	}

	.shishixz .el-color-picker__icon,
	.shishixz .el-input,
	.shishixz .el-textarea {
		display: inline-block;
		width: 503px !important;
		margin-left: 10px;
		margin-top: 2px;
	}

	.shishixz .el-select .el-input .el-select__caret {
		color: #ffffff !important;
		font-size: 18px !important;
	}
</style>
<style scoped>
	.fanye {
		width: 940px;
		height: 58px;
		margin-top: 0px;
		margin-left: 72.5px;
		float: left;

	}

	.chaxunanniu {
		color: #fff;
		margin-left: 15px;
		float: left;
		line-height: 32px;
		width: 72px;
		height: 32px;
		margin-top: 3px;
		border-radius: 10%;
		font-size: 16px;
		background-color: #1a6e9f;

	}

	.chaxunanniu:hover {
		color: #fff;
		margin-left: 15px;
		float: left;
		line-height: 32px;
		width: 72px;
		height: 32px;
		margin-top: 3px;
		border-radius: 10%;
		font-size: 16px;
		background-color: #218fce;

	}

	.block {
		height: 46px;
		margin-top: 12px;
		float: right;

	}

	.biaoti {

		background-color: #104d7b;
		width: 940px;
		height: 45px;
		margin-top: 5px;
		margin-left: 72.5px;
		float: left;
		/* border: #fff solid 1px; */

	}

	.bt_span {
		float: left;
		color: #fff;
		margin-top: 0px;
		margin-left: 0px;
		/* width: 188px; */
		height: 45px;
		cursor: pointer;
		display: block;
		font-size: 18px;
		line-height: 48px;
		font-weight: bold;
		text-align: center;

	}

	.nr_span {
		float: left;
		color: #fff;
		margin-top: 0px;
		margin-left: 0px;
		/* width: 188px; */
		height: 54px;
		cursor: pointer;
		/* display: block; */
		font-size: 16px;
		line-height: 55px;
		text-align: center;

		/* 设置为单行文本 */
		white-space: nowrap;
		/* 设置溢出时显示省略号 */
		text-overflow: ellipsis;
		/* 设置元素宽度，并让文本在该宽度内截断 */
		/* 设置元素内容溢出部分隐藏 */
		overflow: hidden;
		/* 设置元素为块级元素，这样才能进行宽度限制 */
		display: inline-block;

	}


	.neirong {

		/* background-color: #104d7b; */
		width: 940px;
		height: 54px;
		margin-top: 0px;
		margin-left: 0px;
		float: left;
		border-bottom: #1c7bb1 solid 1px;


	}

	.xia {
		width: 940px;
		height: 375px;
		margin-top: 0px;
		margin-left: 72.5px;
		float: left;
		/* border: #fff solid 1px; */
		overflow-x: hidden;
		overflow-y: scroll;
	}

	.x-tc-page-bg {
		background: url("~@/assets/common/tanChuang.png") no-repeat;
		background-size: 100% 100%;
		width: 1132px;
		height: 763px;
		position: fixed;
		top: 100px;
		left: 400px;
		z-index: 99999;
		margin-left: 55px;
	}

	.x-table-box {
		height: 680px;
		overflow-y: auto;
		overflow-x: hidden;
		font-size: 14px
	}

	.x-tc-page-twobg {
		background: url("~@/assets/common/tanChuang.png") no-repeat;
		background-size: 100% 100%;
		width: 1132px;
		height: 763px;
		position: fixed;
		top: 100px;
		left: 400px;
		z-index: 99999;
		margin-left: 55px;
	}

	.x-table-title-list-bg {
		width: 98%;
		margin-left: 1%;
		text-align: center;
		line-height: 40px;
		height: 40px;
	}

	.x-table-title-list-bg:nth-child(2n) {
		background: #ffffff10;
	}

	.x-table-title-list-bg:hover {
		background: #ffffff26;
	}

	.x-tc-title {
		font-size: 22px;
		position: absolute;
		left: 80px;
		top: 15px;
		color: white;
		letter-spacing: 5px;
	}

	.x-close {
		width: 50px;
		height: 50px;
		position: absolute;
		right: 30px;
		cursor: pointer;
	}

	.x-tc-cnt {
		height: 680px;
		width: 1050px;
		overflow: auto;
	}

	.x-card {
		width: 230px;
		height: 270px;
		margin-right: 33px;
		float: left;
	}

	.x-card:nth-child(4n) {
		margin-right: 0px;
	}

	.x-card-cnt {
		width: 250px;
		height: 230px;
	}

	.x-sm-title-bg {
		background: url("~@/assets/ZhiNengXiTong/title.png") no-repeat;
		background-size: 100% 100%;
		width: 220px;
		height: 35px;
		display: flex;
		align-items: center;
		padding-left: 20px;
		font-family: douyuFont-2;
		font-size: 14px;
	}

	.x-aq-cnt {
		width: 100%;
		margin-left: 0%;
		margin-top: 0px;
	}

	.shangxuan {
		width: 100%;
		height: 100px;
		margin-left: 0px;
		margin-top: 0px;
		/* background-color: #218fce; */
	}

	.xiashu {
		width: 100%;
		height: 610px;
		margin-left: 0px;
		margin-top: 0px;
		/* background-color: #218fce; */
		overflow-x: hidden;
		overflow-y: scroll;
	}

	.xiaosp {
		/* background-color: #218fce; */
		width: 50%;
		height: 30px;
		margin-left: 0px;
		margin-top: 1px;
		font-size: 14px;
		line-height: 30px;
		float: left;

		text-align: center;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		display: inline-block;
	}

	.btdanwei {
		width: 110%;
		height: 20px;
		margin-left: -5%;
		margin-top: 7px;
		font-size: 14px;
		float: left;

	}

	.container {
		display: flex;
		justify-content: center;
	}

	.xiaoshuju {
		/* width: 100%; */
		height: 20px;
		/* float: left; */

		display: flex;
		align-items: center;

		line-height: 19px;
		/* text-align: center; */
		/* white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden; */
		/* display: inline-block; */
	}

	.beijingse {
		/* width: 40px; */
		line-height: 21px;
		height: 20px;
		display: block;
		border-radius: 12%;
		margin-right: 5px;
	}

	.xiaotishi {
		/* line-height: 19px;
		color: red; */

		/* width: 40px; */
		line-height: 21px;
		height: 20px;
		display: block;
		border-radius: 12%;
		margin-right: 5px;

	}

	.xiabukuang {
		width: 253px;
		height: 277px;
		margin-left: 15px;
		margin-top: 7px;
		margin-bottom: 21px;
		float: left;
	}

	.xiabukuangtwo {
		width: 253px;
		height: 231px;
		margin-left: 15px;
		margin-top: 7px;
		margin-bottom: 21px;
		float: left;
	}

	.xiaobiaoti {
		background: url("~@/assets/cezhanlishi/dashang.png") no-repeat;
		background-size: 100% 100%;
		width: 100%;
		height: 16.6%;
		margin-left: 0px;
		margin-top: 0px;
		line-height: 60px;
		font-size: 18px;
	}

	.xiaoneirong {
		background: url("~@/assets/cezhanlishi/daxia.png") no-repeat;
		background-size: 100% 100%;
		width: 100%;
		height: 83.4%;
		margin-left: 0px;
		margin-top: 0px;
	}

	.xiaoneirongtwo {
		width: 100%;
		height: 231px;
		margin-left: 0px;
		margin-top: 0px;
		float: left;
	}

	.xiaoliebiao {
		width: 220px;
		height: 172px;
		margin-left: 15px;
		margin-top: 15px;
		border: 1px solid #439dd9;
		float: left;
		overflow-x: hidden;
		overflow-y: scroll;

	}

	.neiqian {
		width: 250px;
		height: 231px;
		margin-left: 0px;
		margin-right: 10px;
		float: left;
	}

	.xuanzedikuang {
		width: 54%;
		height: 45px;
		margin-left: 23%;
		margin-top: 17px;
		border: 2px solid #439dd9;
		float: left;
	}

	.xiaodian {
		background-color: #ffff7f;
		margin-left: 16px;
		margin-top: 16px;
		width: 8px;
		height: 8px;
		border-radius: 100%;
		font-size: 10px;
		line-height: 20px;
		float: left;

	}

	.shangcjsj {

		color: #ffffff;
		/* background: black; */
		width: 54%;
		height: 25px;
		margin-left: 23%;
		margin-top: 8px;
		line-height: 27px;
		/* cursor: pointer; */
		display: block;
		float: left;
		font-size: 18px;
	}
</style>
<style scoped>
	.x-pagination {
		position: absolute;
		right: 40px;
		bottom: 50px;
	}

	.x-pagination>>>.el-pagination__total {
		color: #ffffff;
	}

	.x-pagination>>>.el-input__inner {
		background-color: rgba(255, 255, 255, 0);
		color: #ffffff;
		border: 0;
	}

	.x-pagination>>>.btn-prev {
		background-color: rgba(255, 255, 255, 0);
		color: #ffffff;
	}

	.x-pagination>>>.btn-next {
		background-color: rgba(255, 255, 255, 0);
		color: #ffffff;
	}


	.x-pagination>>>.el-pager li {
		padding: 0 4px;
		background: #fff0;
	}

	.x-pagination>>>.el-pagination__jump {
		color: #ffffff;
	}

	.x-pagination>>>.el-pager li.active {
		color: #2ea6d2 !important;
	}

	.x-pagination>>>.el-pager li.number {
		color: #ffffff;
	}

	.x-pagination>>>.el-pager li.btn-quicknext,
	.el-pager li.btn-quickprev {
		color: #ffffff;
	}
</style>