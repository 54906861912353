import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token-Dushui'

// export function getToken() {
//   return Cookies.get(TokenKey)
// }

// export function setToken(token) {
//   return Cookies.set(TokenKey, token)
// }

// export function removeToken() {
//   return Cookies.remove(TokenKey)
// }

export function getToken() {
	if (window.$WEB == true) {
		return sessionStorage.getItem(TokenKey)
	} else {
		return Cookies.get(TokenKey)
	}
	// return sessionStorage.getItem(TokenKey)
}

export function setToken(token) {
	if (window.$WEB == true) {
		return sessionStorage.setItem(TokenKey, token)
	} else {
		return Cookies.set(TokenKey, token)
	}
}

export function removeToken() {
	if (window.$WEB == true) {
		return sessionStorage.removeItem(TokenKey)
	} else {
		return Cookies.remove(TokenKey)
	}
}