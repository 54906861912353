/**
* @Author liYang
* @Date 2022/9/16 23:58
* @Description: TODO
* @Version 1.0
*/
<template>
  <div>
    <div>
      <div class="x-table-title-bg margin-top x-tab-title-bg" style="width: 1060px;text-align: center;font-size: 14px">
        <div style="width: 120px;">测点名称1</div>
        <div style="width: 120px">水位高程(m)</div>
        <div style="width: 120px;">测点名称2</div>
        <div style="width: 120px">水位高程(m)</div>
        <div style="width: 120px;">测点名称3</div>
        <div style="width: 120px">水位高程(m)</div>
        <div style="width: 120px;">测点名称4</div>
        <div style="width: 120px">水位高程(m)</div>
        <div style="width: 200px">上报时间</div>
      </div>

      <div style="height: 340px;overflow-y: auto;overflow-x: hidden;font-size: 14px"
           class="cursor">
        <div v-for="(item,index) in resData" ref="resDataListRef">
          <div class="x-table-title-list-bg" :class="index % 2 === 0 ? '' : 'x-tab-title-bg'"
               style="width: 1060px;text-align: center">
            <div style="width: 120px;">{{ item.name1 }}</div>
            <div style="width: 120px;">{{ item.elevation1 }}</div>
            <div style="width: 120px;">{{ item.name2 }}</div>
            <div style="width: 120px;">{{ item.elevation2 }}</div>
            <div style="width: 120px;">{{ item.name3 }}</div>
            <div style="width: 120px;">{{ item.elevation3 }}</div>
            <div style="width: 120px;">{{ item.name4 }}</div>
            <div style="width: 120px;">{{ item.elevation4 }}</div>
            <div style="width: 200px">{{ item.TT }}</div>
          </div>
        </div>
      </div>
    </div>

    <el-pagination
        class="x-pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="searchData.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>

export default {
  name: "x-liShiJiLu",
  props: ['deviceId'],
  data() {
    return {
      total: 0,
      resData: [],
      searchData: {
        limit: 10,
        offset: 0,
      },
    }
  },

  created() {
    this.initData();
  },

  mounted() {

  },

  beforeDestroy() {

  },

  methods: {

    /**
     * @Description: 获取一段时间内的平均数据
     * @Author: LiYang
     * @Date:  2022/9/16  0:48
     */
    initData() {
      this.$request(
          {
            url: '/RequestRelay',
            method: 'post',
            headers: {
              isToken: false
            },
            data: {
              url: `${window.$baseUrl_hz_sk}/TjcUpbuMEk.php/irriinfo/statistics/seep/dataindex?stationId=${this.deviceId}&sort=id&order=desc&offset=${this.searchData.offset}&limit=${this.searchData.limit}&filter=%7B%7D&op=%7B%7D&_=1663344257890`,
              type: 'get',
            }
          }
      ).then((val) => {
        let data = JSON.parse(val.msg);
        this.resData = data.rows;
        this.total = data.total;
      });
    },

    // 分页改变
    handleSizeChange(val) {
      this.searchData.limit = val;
      this.initData();
    },

    // 分页改变
    handleCurrentChange(val) {
      this.searchData.offset = (val - 1) * 10;
      this.initData();
    },

  }
}
</script>

<style scoped>

.x-pagination{
  position: absolute;
  right: 40px;
  bottom: 50px;
}

.x-pagination >>> .el-pagination__total {
  color: #ffffff;
}

.x-pagination >>> .el-input__inner {
  background-color: rgba(255, 255, 255, 0);
  color: #ffffff;
  border: 0;
}

.x-pagination >>> .btn-prev {
  background-color: rgba(255, 255, 255, 0);
  color: #ffffff;
}

.x-pagination >>> .btn-next {
  background-color: rgba(255, 255, 255, 0);
  color: #ffffff;
}


.x-pagination >>> .el-pager li {
  padding: 0 4px;
  background: #fff0;
}

.x-pagination >>> .el-pagination__jump {
  color: #ffffff;
}

.x-pagination >>> .el-pager li.active {
  color: #2ea6d2 !important;
}

.x-pagination >>> .el-pager li.number {
  color: #ffffff;
}

.x-pagination >>> .el-pager li.btn-quicknext, .el-pager li.btn-quickprev{
  color: #ffffff;
}
</style>
