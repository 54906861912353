<template>
  <div>
    <x-dialog-comp title="潮汐表" @on-close="$emit('on-close')">
      <div class="text-left margin-left flex" style="width: 400px">
        <div class="flex align-center">
          <div class="x-xiaoDian"></div>
          退潮时间：07:26-16:12
        </div>
        <div class="flex align-center">
          <div class="x-xiaoDian"></div>
          涨潮时间：16:12-22:50
        </div>
      </div>
      <div style="width: 100%;height: 350px" ref="chaoxiRef"></div>
      <div style="width: 100%;text-align: center">
        <div class="douyuFont">潮高基准面：在平均海面下120cm</div>
      </div>

      <div class="margin-top">
        <table rules="groups" border="1" cellspacing="0" style="width: 90%;line-height: 50px;margin-left: 5%">
        <tr>
          <td>高低潮</td>
          <td>满潮</td>
          <td>干潮</td>
          <td>满潮</td>
        </tr>
        <tr>
          <td>潮时</td>
          <td>07:26</td>
          <td>16:12</td>
          <td>22:50</td>
        </tr>
        <tr>
          <td>潮高(峰值)</td>
          <td>177cm</td>
          <td>73cm</td>
          <td>93cm</td>
        </tr>
        </table>
      </div>
    </x-dialog-comp>
  </div>
</template>

<script>
import XDialogComp from "@/components/x-dialog-comp";
import XTitleBtn from "@/components/x-title-btn";
import XRealTime from "@/components/x-device-Beng/components/x-real-time";
import XDetails from "@/components/x-device-osmoticPressure-dialog/components/x-details";
import XAnQuanFenXi from "@/components/x-AnQuanFenXi";
import {getOsmoticPressure} from "@/api/device/osmoticPressure";
import XHistory from "@/components/x-device-osmoticPressure-dialog/components/x-history";

export default {
  name: "x-device-ChaoXi",
  components: {XHistory, XAnQuanFenXi, XDetails, XRealTime, XDialogComp, XTitleBtn},
  props: {
    deviceId: "",
  },
  data() {
    return {
      loadingType: false,
      resData: {},
    }
  },
  created() {

  },
  mounted() {
    this.initEchartsRef();
  },
  destroyed() {
  },
  methods: {
    initEchartsRef() {
      let myChart = this.$echarts.init(this.$refs.chaoxiRef, null);
      let option = {
        xAxis: {
          data: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00", "24:00"],
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 9,
              color: "#ffffff", //X轴文字颜色
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          name: '潮高（cm）',
          nameTextStyle:{
            color: '#ffffff',
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 9,
              color: "#ffffff", //X轴文字颜色
            },
          },
          axisLine: {
            show: true,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
          },
          textStyle: {
            color: "rgb(0,0,0)",
          },
        },
        series: [
          {
            type: "line",
            symbol: "circle",
            symbolSize: 10,
            smooth: true,
            data: [78, 83, 94, 109, 128, 149, 166, 176, 175, 165, 148, 129, 110, 95, 84, 76, 73, 74, 78, 82, 86, 89, 92, 93],
            itemStyle: {
              //拐点的样式
              color: "rgba(244,253,255,1)",
              borderWidth: 1,
              borderColor: "rgba(87, 183, 242, 1)",
            },
            lineStyle: {
              //线条的样式
              width: 3,
              color: "rgba(87, 183, 242, 1)",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(87, 183, 242, 1)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(6,37,55,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },

  },
}
</script>

<style scoped>

.x-aq-cnt {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.x-xiaoDian {
  width: 31px;
  height: 31px;
  background: url("~@/assets/YongZhenShuiKu/小点.png") no-repeat;
  background-size: 100% 100%;

}
</style>

<style>
table{
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-collapse: collapse; /*设置单元格的边框合并为1*/
}

td{
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}
</style>
