<template>
	<div class="xiaoshaixuan">
		<span class="xuanzewenzi" style="margin-right: 0px;">查询时间：</span>
		<div class="rq" style="float: left;">
			<el-date-picker v-model="qishiriqi" type="date" placeholder="选择日期" :clearable="false" @change="pjsjone"
				value-format="yyyy-MM-dd" prefix-icon="''">
			</el-date-picker>
		</div>
		<div class="xs" style="float: left;margin-left: 10px;margin-top: 4px;">
			<el-select v-model="qishixiaoshi" placeholder="" @change="pjsjone">
				<el-option v-for="(item,index) in shi" :key="item" :label="item+'时'" :value="item">
				</el-option>
			</el-select>
		</div>
		<div class="fz" style="float: left; margin-left: 10px;margin-top: 4px;">
			<el-select v-model="qishifenzhong" placeholder="" @change="pjsjone">
				<el-option v-for="(item,index) in fen" :key="item" :label="item+'分'" :value="item">
				</el-option>
			</el-select>
		</div>
		<span class="xuanzewenzi" style="margin-right: 10px;margin-left: 10px;">至</span>
		<div class="rq" style="float: left; ">
			<el-date-picker v-model="jieshuriqi" type="date" placeholder="选择日期" :clearable="false" @change="pjsjtwo"
				value-format="yyyy-MM-dd" prefix-icon="''">
			</el-date-picker>
		</div>
		<div class="xs" style="float: left;margin-left: 10px;margin-top: 4px;">
			<el-select v-model="jieshuxiaoshi" placeholder="" @change="pjsjtwo">
				<el-option v-for="(item,index) in shi" :key="item" :label="item+'时'" :value="item">
				</el-option>
			</el-select>
		</div>
		<div class="fz" style="float: left; margin-left: 10px;margin-top: 4px;">
			<el-select v-model="jieshufenzhong" placeholder="" @change="pjsjtwo">
				<el-option v-for="(item,index) in fen" :key="item" :label="item+'分'" :value="item">
				</el-option>
			</el-select>
		</div>
		<div class="chaxunanniu" @click="childMethod()"><span>查询</span></div>
		<div class="chaxunanniu" @click="childdaochu()"><span>导出</span></div>
		<!-- <div class="sanshijianbj">
			<span :class="shijianbj==1?'active':''" @click="tannInfo(1)">1h</span>
			<span :class="shijianbj==2?'active':''" @click="tannInfo(2)">3h</span>
			<span :class="shijianbj==3?'active':''" @click="tannInfo(3)">6h</span>
		</div>
		<el-button type="primary">数据导出</el-button> -->
	</div>

</template>

<script>
	export default {
		name: "index",
		components: {},
		props: {
			getlist: {
				type: Function,
				default: () => {
					return Function;
				},
			},
			getdaochu: {
				type: Function,
				default: () => {
					return Function;
				},
			},
		},
		data() {
			return {
				shi: [],
				fen: [],
				shijianbj: 0,
				qishiriqi: '',
				qishixiaoshi: '00',
				qishifenzhong: '00',
				jieshuriqi: '',
				jieshuxiaoshi: '23',
				jieshufenzhong: '59',
				beginTime: "",
				endTime: "",
			}
		},

		created() {},
		mounted() {
			this.fenen()
			this.shihi()
			this.yesterday()
			this.today()
			this.childMethod()
		},
		beforeDestroy() {},
		methods: {
			
			childdaochu() {
				this.getdaochu();
				console.log('调用getdaochu父组件方法')
			},
			// 限制七天
			handleChangeendTime() {
				// 将日期和时间字符串转换成Date对象
				const start = new Date(this.beginTime)
				const end = new Date(this.endTime)
				// 判断是否超过7天
				const duration = end - start
				if (duration > 7 * 24 * 60 * 60 * 1000) {
					// 更新结束时间
					const oneHourAgo = new Date(start.getTime() + 7 * 24 * 60 * 60 * 1000)
					let year = oneHourAgo.getFullYear(); // 获取年份
					let month = (oneHourAgo.getMonth() + 1).toString().padStart(2, '0'); // 获取月份
					let date = oneHourAgo.getDate().toString().padStart(2, '0'); // 获取日期
					let hour = oneHourAgo.getHours().toString().padStart(2, '0'); // 获取小时数
					let minute = oneHourAgo.getMinutes().toString().padStart(2, '0'); // 获取分钟数
					let second = oneHourAgo.getSeconds().toString().padStart(2, '0'); // 获取秒数
					this.endTime = year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' +
						second; // 设置 endTime 数据
					this.jieshuriqi = year + '-' + month + '-' + date
					this.jieshuxiaoshi = hour
					this.jieshufenzhong = minute
					console.log("****改7天****")
					console.log(this.beginTime)
					console.log(this.endTime)
					console.log("****改7天****")
				}
				this.$emit('change', this.beginTime, this.endTime);
			},
			handleChangebeginTime() {
				// 将日期和时间字符串转换成Date对象
				const start = new Date(this.beginTime)
				const end = new Date(this.endTime)
				// 判断是否超过7天
				const duration = end - start
				if (duration > 7 * 24 * 60 * 60 * 1000) {
					// 更新结束时间
					const oneHourAgo = new Date(end.getTime() - 7 * 24 * 60 * 60 * 1000)
					let year = oneHourAgo.getFullYear(); // 获取年份
					let month = (oneHourAgo.getMonth() + 1).toString().padStart(2, '0'); // 获取月份
					let date = oneHourAgo.getDate().toString().padStart(2, '0'); // 获取日期
					let hour = oneHourAgo.getHours().toString().padStart(2, '0'); // 获取小时数
					let minute = oneHourAgo.getMinutes().toString().padStart(2, '0'); // 获取分钟数
					let second = oneHourAgo.getSeconds().toString().padStart(2, '0'); // 获取秒数
					this.beginTime = year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' +
						second; // 设置 beginTime 数据
					this.qishiriqi = year + '-' + month + '-' + date
					this.qishixiaoshi = hour
					this.qishifenzhong = minute
					console.log("****改7天****")
					console.log(this.beginTime)
					console.log(this.endTime)
					console.log("****改7天****")
				}
				this.$emit('change', this.beginTime, this.endTime);
			},
			childMethod() {
				this.getlist();
				console.log('调用父组件方法')
			},
			dangqianshijian() {
				let now = new Date();
				let oneHourAgo = new Date(now.getTime());
				let year = oneHourAgo.getFullYear(); // 获取年份
				let month = (oneHourAgo.getMonth() + 1).toString().padStart(2, '0'); // 获取月份
				let date = oneHourAgo.getDate().toString().padStart(2, '0'); // 获取日期
				let hour = oneHourAgo.getHours().toString().padStart(2, '0'); // 获取小时数
				let minute = oneHourAgo.getMinutes().toString().padStart(2, '0'); // 获取分钟数
				let second = oneHourAgo.getSeconds().toString().padStart(2, '0'); // 获取秒数
				this.endTime = year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' +
					second; // 设置 endTime 数据
				this.jieshuriqi = year + '-' + month + '-' + date
				this.jieshuxiaoshi = hour
				this.jieshufenzhong = minute
				this.$emit('change', this.beginTime, this.endTime);
				this.childMethod()


			},
			yixiaoshiqian() {
				let now = new Date();
				let oneHourAgo = new Date(now.getTime() - 60 * 60 * 1000); // 获取前一小时的时间
				let year = oneHourAgo.getFullYear(); // 获取年份
				let month = (oneHourAgo.getMonth() + 1).toString().padStart(2, '0'); // 获取月份
				let date = oneHourAgo.getDate().toString().padStart(2, '0'); // 获取日期
				let hour = oneHourAgo.getHours().toString().padStart(2, '0'); // 获取小时数
				let minute = oneHourAgo.getMinutes().toString().padStart(2, '0'); // 获取分钟数
				let second = oneHourAgo.getSeconds().toString().padStart(2, '0'); // 获取秒数
				this.beginTime = year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' +
					second; // 设置 beginTime 数据
				this.qishiriqi = year + '-' + month + '-' + date
				this.qishixiaoshi = hour
				this.qishifenzhong = minute
				// console.log("1时间为this.beginTime：" + this.beginTime)
				this.dangqianshijian()
			},
			sanxiaoshiqian() {
				let now = new Date();
				let oneHourAgo = new Date(now.getTime() - 180 * 60 * 1000); // 获取前一小时的时间
				let year = oneHourAgo.getFullYear(); // 获取年份
				let month = (oneHourAgo.getMonth() + 1).toString().padStart(2, '0'); // 获取月份
				let date = oneHourAgo.getDate().toString().padStart(2, '0'); // 获取日期
				let hour = oneHourAgo.getHours().toString().padStart(2, '0'); // 获取小时数
				let minute = oneHourAgo.getMinutes().toString().padStart(2, '0'); // 获取分钟数
				let second = oneHourAgo.getSeconds().toString().padStart(2, '0'); // 获取秒数
				this.beginTime = year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' +
					second; // 设置 beginTime 数据
				this.qishiriqi = year + '-' + month + '-' + date
				this.qishixiaoshi = hour
				this.qishifenzhong = minute
				// console.log("3时间为this.beginTime：" + this.beginTime)
				this.dangqianshijian()
			},
			liuxiaoshiqian() {
				let now = new Date();
				let oneHourAgo = new Date(now.getTime() - 360 * 60 * 1000); // 获取前一小时的时间
				let year = oneHourAgo.getFullYear(); // 获取年份
				let month = (oneHourAgo.getMonth() + 1).toString().padStart(2, '0'); // 获取月份
				let date = oneHourAgo.getDate().toString().padStart(2, '0'); // 获取日期
				let hour = oneHourAgo.getHours().toString().padStart(2, '0'); // 获取小时数
				let minute = oneHourAgo.getMinutes().toString().padStart(2, '0'); // 获取分钟数
				let second = oneHourAgo.getSeconds().toString().padStart(2, '0'); // 获取秒数
				this.beginTime = year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' +
					second; // 设置 beginTime 数据
				this.qishiriqi = year + '-' + month + '-' + date
				this.qishixiaoshi = hour
				this.qishifenzhong = minute
				// console.log("6时间为this.beginTime：" + this.beginTime)
				this.dangqianshijian()
			},
			pjsjone() {
				this.shijianbj = 0;
				this.beginTime = this.qishiriqi + " " + this.qishixiaoshi + ":" + this.qishifenzhong + ":" + "00"
				// console.log("开始时间this.beginTime：" + this.beginTime)
				// this.handleChangeendTime()
				this.$emit('change', this.beginTime, this.endTime);
			},
			pjsjtwo() {
				this.shijianbj = 0;
				this.endTime = this.jieshuriqi + " " + this.jieshuxiaoshi + ":" + this.jieshufenzhong + ":" + "00"
				// console.log("结束时间this.endTime：" + this.endTime)
				// this.handleChangebeginTime()
				this.$emit('change', this.beginTime, this.endTime);
			},
			fenen() {
				for (let i = 0; i < 60; i++) {
					let minuteStr = i.toString().padStart(2, '0');
					this.fen.push(minuteStr);
				}
			},
			shihi() {
				for (let i = 0; i < 24; i++) {
					let minuteStr = i.toString().padStart(2, '0');
					this.shi.push(minuteStr);
				}
			},
			yesterday() {
				// 创建一个 Date 对象并获取昨天的日期
				let date = new Date();
				date.setDate(date.getDate() - 1);
				// 格式化日期为 YYYY-MM-DD 的字符串
				let year = date.getFullYear();
				let month = String(date.getMonth() + 1).padStart(2, '0');
				let day = String(date.getDate()).padStart(2, '0');
				this.qishiriqi = `${year}-${month}-${day}`
				this.beginTime = this.qishiriqi + " " + this.qishixiaoshi + ":" + this.qishifenzhong + ":" + "00"
				this.$emit('change', this.beginTime, this.endTime);
				// 返回格式化后的字符串
				return `${year}-${month}-${day}`;
			},
			today() {
				// 创建一个 Date 对象并获取昨天的日期
				let date = new Date();
				date.setDate(date.getDate());
				// 格式化日期为 YYYY-MM-DD 的字符串
				let year = date.getFullYear();
				let month = String(date.getMonth() + 1).padStart(2, '0');
				let day = String(date.getDate()).padStart(2, '0');
				this.jieshuriqi = `${year}-${month}-${day}`
				this.endTime = this.jieshuriqi + " " + this.jieshuxiaoshi + ":" + this.jieshufenzhong + ":" + "00"
				this.$emit('change', this.beginTime, this.endTime);
				// console.log("默认今天this.endTime：" + this.endTime)
				// 返回格式化后的字符串
				return `${year}-${month}-${day}`;
			},
			tannInfo: function(id) {
				this.shijianbj = id;
				// console.log(this.shijianbj)
				if (this.shijianbj == 1) {
					this.yixiaoshiqian()
				}
				if (this.shijianbj == 2) {
					this.sanxiaoshiqian()
				}
				if (this.shijianbj == 3) {
					this.liuxiaoshiqian()
				}
			},




		}
	}
</script>
<style>
	.xiaoshaixuan .el-select .el-input .el-select__caret {
		color: #ffffff;
		font-size: 16px;
		/* margin-top: 5px; */
	}

	.xiaoshaixuan .el-select>.el-input {
		display: block;
		width: 75px;
	}

	.xiaoshaixuan .el-input__inner {
		background-color: #1a6e9f;
		color: #fff;
		border: 0px solid #fff;
	}
	.xiaoshaixuan .el-input__icon {
	    line-height: 1 !important;
	}

	.xiaoshaixuan .el-input--prefix .el-input__inner {
		padding-left: 10px !important;
		height: 30px;
		margin-top: 4px;
	}

	.xiaoshaixuan .el-input--suffix .el-input__inner {
		padding-right: 10px !important;
		height: 30px;
		/* width: 119px; */
	}


	.xiaoshaixuan .rq .el-date-editor.el-input,
	.xiaoshaixuan .el-date-editor.el-input__inner {
		width: 100px !important;
	}
</style>
<style scoped>
	.el-button--primary {
		color: #FFF;
		background-color: #2191d1;
		border-color: #409EFF;
		width: 80px;
		margin-top: 3px;
		margin-left: 20px;
	}



	.sanshijianbj span {
		margin-left: 10px;
		color: #fff;
		margin-left: 10px;
		float: left;
		line-height: 32px;
		width: 41px;
		height: 32px;
		margin-top: 3px;
		font-size: 16px;
		text-align: center;
		border-radius: 10%;
	}

	.sanshijianbj span.active {
		background-color: #1a6e9f;
		margin-left: 10px;
		text-align: center;
		color: #fff;
		margin-left: 10px;
		float: left;
		line-height: 32px;
		width: 41px;
		height: 32px;
		margin-top: 3px;
		font-size: 16px;
		border-radius: 10%;
	}

	.el-button {
		padding: 9px 0px;
	}

	.el-button--primary {
		color: #FFF;
		background-color: #409EFF;
		border-color: #409EFF;
		width: 80px;
		margin-top: 3px;
		margin-left: 20px;
	}

	.el-button--primary:focus,
	.el-button--primary:hover {
		background: #66b1ff;
		border-color: #66b1ff;
		color: #FFF;
		width: 80px;
	}


	.sanxuan {
		color: #fff;
		margin-left: 10px;
		float: left;
		line-height: 32px;
		width: 41px;
		height: 32px;
		margin-top: 3px;
		font-size: 16px;
		/* background-color: #1a6e9f;	 */

	}

	.chaxunanniu {
		color: #fff;
		margin-left: 10px;
		float: left;
		line-height: 32px;
		width: 65px;
		height: 32px;
		margin-top: 3px;
		border-radius: 10%;
		font-size: 16px;
		background-color: #1a6e9f;

	}

	.chaxunanniu:hover {
		color: #fff;
		margin-left: 10px;
		float: left;
		line-height: 32px;
		width: 65px;
		height: 32px;
		margin-top: 3px;
		border-radius: 10%;
		font-size: 16px;
		background-color: #218fce;

	}

	.xuanzewenzi {
		color: #fff;
		margin-left: 10px;
		margin-right: 0px;
		float: left;
		font-size: 16px;
		line-height: 39px;
	}


	.xiaoshaixuan {
		/* background-color: #104d7b; */
		/* width: 100%; */
		height: 50px;
		margin-top: 10px;
		margin-left: 0px;
		float: left;
		/* border: #fff solid 1px; */
	}
</style>
