<template>
	<div style="width: 100%;height: 100%;">
		<div :id="'player'+ID" style="width: 100%;height: 92%;"></div>
		<img src="../../../public/img/播放按钮.png" class="anniu" @click="bofangjiankong()" v-if="this.jishi==1" />
		<span style="color: #fff;text-align: center;width: 100%;display: block;">
			<!-- {{videoInfo.online==0?'状态:在线，':''}}{{videoInfo.online==1?'状态:离线，':''}} -->
			<span>{{bofangZhuangtai}}</span>
			<span v-if="this.chuandeshijian==1">
				<span v-if="this.daojishi!=0">当前监控{{this.daojishi}}秒后自动暂停</span>
				<span v-if="this.daojishi==0">当前监控已暂停</span>
			</span>
		</span>
		<div v-if="this.jishi!=1 && this.duijiangkongzhi==2 && this.shifouzhichiduijiang == 2" class="gongnengkuang">
			<div class="duijianganniu" @click="dianjiduijiang()"
				v-if="this.duijiangpanduan == 2 && this.jiankongurlpanduan == 'wss'">
				<img src="~@/assets/jiankong/开始对讲.png" style="width: 40px;height: 40px;" />
			</div>
			<div class="duijianganniu" @click="dianjiduijiang()"
				v-if="this.duijiangpanduan == 1 && this.jiankongurlpanduan == 'wss'">
				<img src="~@/assets/jiankong/关闭对讲.png" style="width: 40px;height: 40px;" />
			</div>
			<div class="duijianganniu" @click="dianjishengyin()" v-if="this.shengyinpanduan == 2">
				<img src="~@/assets/jiankong/声音关.png" style="width: 40px;height: 40px;" />
			</div>
			<div class="duijianganniu" @click="dianjishengyin()" v-if="this.shengyinpanduan == 1">
				<img src="~@/assets/jiankong/声音开.png" style="width: 40px;height: 40px;" />
			</div>
		</div>
		<yuntaikongzhi @childEvent="handleChildEvent" @childqingxi="handleChildqingxi" :qingxidu="qingxidu"
			:jiankongbiaoshi="jiankongweiyisn" v-if="this.kongzhikaiguan == 2 && this.yuntaikongzhi == 2">
		</yuntaikongzhi>
	</div>
</template>

<script>
	import {
		jiankongsn,
		getDevice
	} from "@/api/daping/jiankongsn";
	import yuntaikongzhi from "@/components/x-video/yuntaikongzhi";
	export default {
		name: "x-video",
		components: {
			yuntaikongzhi
		},
		props: {
			ID: {
				type: Number,
				default: 0
			},
			yuntaikongzhi: {
				type: Number,
				default: 1
			},
			chuandeshijian: {
				type: Number,
				default: 1
			},
			duijiangkongzhi: {
				type: Number,
				default: 1
			},
		},

		data() {
			return {
				chaoshitime: 240,
				duijiangtime: 240,
				shifouhuoquurl: 1, //2是，1否
				jiankongbiaoshi: this.ID,
				jishi: 0,
				daojishi: 30,
				dizhi: "",
				videoInfo: {},
				bofangZhuangtai: "",
				errList: {
					'0x0190003e': '设备离线', //取流失败
					'0x12f900009': '取流超时',
					'0x12f910010': '取流失败', //不确定问题
					'0x01900050': '取流失败', //不确定问题
					'0x01b01307': '取流失败url过期',
					'0x12f910015': '取流失败', //未找到对应websocket，取流套接字被动关闭的报错
					'0x12f910011': '流中断', //未找到对应websocket，取流套接字被动关闭的报错
					'0x0190004a': '设备离线', //设备不在线
				},

				jiankonglist: [],
				jiankongweiyisn: "",
				kongzhikaiguan: 1,
				shifouzhichiduijiang: 1,
				jiankongneirong: {},
				qingxidu: 1,
				duijiangpanduan: 1,
				shengyinpanduan: 1,
				duijiangurl: "",
				jiankongurlpanduan: window.$JIANKONGURLTYPE,
			}
		},
		created() {},

		mounted() {
			console.log("++++jiankongbofang.vue++++this.yuntaikongzhi+++++++")
			console.log(this.duijiangkongzhi)
			console.log(this.yuntaikongzhi)
			console.log(this.ID)
			if (this.ID != undefined || this.ID != null) {
				if (this.yuntaikongzhi == 2 || this.duijiangkongzhi == 2) {
					console.log("希望打开云台" + this.ID)
					this.getjiankongsn()
				}
				this.bofangjiankong()
			}

			// this.shipindingshi = setInterval(this.guanbi, 90000);
			// this.shipindingshi = setInterval(() => {
			// 	this.daojishi = this.daojishi - 1
			// 	if (this.daojishi == 0) {
			// 		this.guanbi()
			// 	}
			// }, 1000);


		},

		watch: {
			// shifoukaiqiyuntai:{
			// 	shifoukaiqiyuntai(newVal){
			// 		this.yuntaikongzhi = this.shifoukaiqiyuntai
			// 		console.log("当前点击的yuntaikongzhi：", this.yuntaikongzhi)
			// 		}
			// 	},

			ID: {
				handler(newVal) {
					this.ID = newVal;
					this.jiankongbiaoshi = this.ID
					if (this.ID != undefined) {
						if (this.yuntaikongzhi == 2 || this.duijiangkongzhi == 2) {
							console.log("希望打开云台")
							this.getjiankongsn()
						}

					}
					this.bofangjiankong()
				},
			},

			// faMsg(value) {
			// 	console.log("::::" + value)
			// 	this.dizhi = value
			// 	this.initPlayer()
			// }
		},


		beforeDestroy() {
			clearInterval(this.shipindingshi)
			clearInterval(this.duijiangdingshi)
			this.videoShow = false;
			this.guanbi()
			this.jieshuhanhua()
			this.guanbishengyin()
		},
		// destroyed() {
		// 	this.videoShow = false;
		// 	this.guanbi()
		// },
		methods: {
			dianjishengyin() {
				console.log("点击声音按钮")
				if (this.shengyinpanduan == 1) {
					this.kaiqishengyin()

				} else {
					this.guanbishengyin()
				}
			},
			kaiqishengyin() {
				console.log("进入打开声音方法")
				const _this = this
				_this.player.JS_OpenSound().then(
					() => {
						console.info('打开声音 success');
						this.shengyinpanduan = 2
					},
					(err) => {
						console.info('打开声音 failed');
					}
				);

			},
			guanbishengyin() {
				console.log("进入停止声音方法")
				const _this = this
				_this.player.JS_CloseSound().then(
					() => {
						console.info('停止声音 success');
						this.shengyinpanduan = 1
					},
					(err) => {
						console.info('停止声音 failed');
					}
				);
			},
			dianjiduijiang() {
				console.log("点击对讲按钮")
				if (this.duijiangpanduan == 1) {
					this.$request({
						// streamType=0主码流/1子码流
						url: `${window.$baseUrl_hz_bz}/api/haikcamera/talkURLsByDevId?devId=${this.ID}&transmode=1&netZoneCode=1&protocol=wss`,
						type: 'get',
						headers: {
							isToken: false,
						}
					}).then((val) => {
						// this.videoInfo = val.data;
						this.duijiangurl = val.data.bofangUrl
						console.log("this.++++++++++++++++++++++++++++++++++++++++duijiangurl")
						console.log(this.duijiangurl)
						this.kaishihanhua()
					});
				} else {
					this.$request({
						// streamType=0主码流/1子码流
						url: `${window.$baseUrl_hz_bz}/api/haikcamera/talkURLsByDevId?devId=${this.ID}&transmode=1&netZoneCode=1&protocol=wss`,
						type: 'get',
						headers: {
							isToken: false,
						}
					}).then((val) => {
						// this.videoInfo = val.data;
						this.duijiangurl = val.data.bofangUrl
						console.log("this.++++++++++++++++++++++++++++++++++++++++duijiangurl")
						console.log(this.duijiangurl)
						this.jieshuhanhua()
					});
				}
			},
			bofangjiankong() {

				this.$request({
					// streamType=0主码流/1子码流
					url: `${window.$baseUrl_hz_bz}/api/haikcamera/previewURLsByDevId?devId=${this.ID}&streamType=${this.qingxidu}&protocol=${this.jiankongurlpanduan}`,
					type: 'get',
					headers: {
						isToken: false,
					}
				}).then((val) => {
					this.videoInfo = val.data;
					this.dizhi = val.data.bofangUrl

					console.log(this.dizhi)
					// this.dizhiurl = val.data.bofangUrl

					this.initPlayer()
				});
			},
			handleChildEvent(value) {
				if (this.chuandeshijian == 1) {
					this.daojishi = value;
				}
			},
			handleChildqingxi(value) {
				this.qingxidu = value;
				// console.log("清晰度："+this.qingxidu)
				this.bofangjiankong()
			},
			// getjiankongsn() {
			// 	jiankongsn({
			// 		categoryId: 2,
			// 		pageNum: 1,
			// 		pageSize: 99999,
			// 		productId: 26,
			// 	}).then(response => {
			// 		if (response.code == 200) {
			// 			this.jiankonglist = response.rows;
			// 			console.log(this.jiankonglist)
			// 			for(let i=0;i<this.jiankonglist.length;i++){
			// 				if(this.jiankonglist[i].deviceId == this.ID){
			// 					this.jiankongweiyisn = this.jiankonglist[i].serialNumber
			// 					console.log(this.jiankonglist[i].productId)
			// 					// if(this.jiankonglist[i].productId == 26){
			// 					// 	this.kongzhikaiguan = 2
			// 					// 	console.log(this.kongzhikaiguan)
			// 					// }
			// 				}
			// 			}
			// 			console.log("***************"+this.jiankongweiyisn)
			// 			if(this.jiankongweiyisn != ""){
			// 				this.kongzhikaiguan = 2
			// 				console.log(this.kongzhikaiguan)
			// 			}

			// 		}
			// 	})
			// },
			getjiankongsn() {
				console.log("查询是否支持云台" + this.ID)
				getDevice(this.ID).then(response => {
					this.jiankongneirong = response.data;
					this.jiankongweiyisn = this.jiankongneirong.serialNumber
					if (this.jiankongneirong.productId == 26) {
						this.kongzhikaiguan = 2
						console.log("++++jiankongbofang.vue++++this.kongzhikaiguan+++++支持云台")
					} else {
						console.log("++++jiankongbofang.vue++++this.kongzhikaiguan+++++不支持云台")
					}
					console.log(this.jiankongneirong.devParams)
					console.log("***************")
					if (this.jiankongneirong.devParams != null) {

						let parsedParams = JSON.parse(this.jiankongneirong.devParams);
						parsedParams.forEach(param => {
							if (param.key === "support_voice" && param.value === "true") {
								this.shifouzhichiduijiang = 2;
								console.log("++++jiankongbofang.vue++++this.kongzhikaiguan+++++支持喊话")
								console.log(this.duijiangkongzhi)
								console.log(this.shifouzhichiduijiang)
							}
						});
					}


				})
			},

			/**
			 * 初始化播放器
			 */
			initPlayer() {
				this.player = new window.JSPlugin({
					// 需要英文字母开头 必填
					szId: 'player' + this.ID,
					// 必填,引用H5player.min.js的js相对路径
					szBasePath: './h5player',

					// 当容器div#play_window有固定宽高时，可不传iWidth和iHeight，窗口大小将自适应容器宽高
					iWidth: 600,
					iHeight: 200,

					// 分屏播放，默认最大分屏4*4
					iMaxSplit: 16,
					iCurrentSplit: 1,

					openDebug: true,

					// 样式
					oStyle: {
						border: '#343434',
						borderSelect: '#FFCC00',
						background: '#000'
					}
				})
				this.play(0)
			},

			/**
			 * 喊话
			 */
			kaishihanhua(index) {
				console.log("进入对讲方法")
				const _this = this
				const paraduijian = _this.duijiangurl
				_this.player.JS_StartTalk(paraduijian).then(
					() => {
						console.info('对讲开启 成功');
						this.duijiangpanduan = 2
						_this.player.JS_TalkSetVolume(100).then(
							() => {
								console.info('对讲音量设置100 success');
							},
							(err) => {
								console.info('对讲音量设置100 failed');
							}
						)

						this.duijiangdingshi = setInterval(() => {
							console.log("对讲倒计时", this.duijiangtime)
							this.duijiangtime = this.duijiangtime - 1
							if (this.duijiangtime == 0) {
								clearInterval(this.duijiangdingshi);
								this.jieshuhanhua()
							}
						}, 1000);


						if (this.shipindingshi) {
							clearInterval(this.shipindingshi);
							this.shipindingshi = null;
						}
						// if (this.chaoshidingshi) {
						// 	clearInterval(this.chaoshidingshi);
						// 	this.chaoshidingshi = null;
						// }

					},
					(err) => {
						console.info('对讲开启 失败');
					}
				);
			},
			jieshuhanhua(index) {
				clearInterval(this.duijiangdingshi);
				this.duijiangtime = 240
				console.log("进入停止对讲方法")
				const _this = this
				const paraduijian = _this.duijiangurl
				_this.player.JS_StopTalk(paraduijian).then(
					() => {
						console.info('停止对讲开启 成功');
						this.duijiangpanduan = 1


						if (this.chuandeshijian == 1) {
							if (!this.shipindingshi) {
								this.shipindingshi = setInterval(() => {
									this.daojishi = this.daojishi - 1
									if (this.daojishi == 0) {
										clearInterval(this.shipindingshi);
										this.guanbi()
									}
								}, 1000);
							}
						}

					},
					(err) => {
						console.info('停止对讲开启 失败');
					}
				);
			},
			/**
			 * 播放
			 */
			play(index) {
				// if (this.shifouhuoquurl == 2) {
				// 	this.bofangjiankong()
				// } else {
				this.shifouhuoquurl = 1
				this.chaoshitime = 240
				this.jishi = 0
				this.daojishi = 30

				// console.log("play")
				const _this = this
				let preUrl = this.dizhi
				// console.log(preUrl)
				const param = {
					playURL: preUrl,
					// 1：高级模式  0：普通模式，高级模式支持所有
					mode: 1
				}
				// console.log(param)
				// 索引默认0
				if (!index) {
					index = 0
				}
				// console.log(index)
				_this.player.JS_Play(preUrl, param, index).then(() => {
						// 播放成功回调
						if (this.chuandeshijian == 1) {
							this.bofangZhuangtai = "播放状态:播放成功，";
						} else {
							this.bofangZhuangtai = "播放状态:播放成功";
						}
						// console.log('播放成功')
						if (this.chuandeshijian == 1) {
							if (!this.shipindingshi) {
								this.shipindingshi = setInterval(() => {
									this.daojishi = this.daojishi - 1
									if (this.daojishi == 0) {
										clearInterval(this.shipindingshi);
										this.guanbi()
									}
								}, 1000);
							}
						}

					},
					(err) => {
						if (this.chuandeshijian == 1) {
							this.bofangZhuangtai = "播放状态:" + (this.errList[err] != undefined ? this.errList[err] :
								'播放失败') + "，";
						} else {
							this.bofangZhuangtai = "播放状态:" + (this.errList[err] != undefined ? this.errList[err] :
								'播放失败');
						}
						console.log(err)
						// console.log(_this.player)
						console.log('播放失败')
					})

				// }
			},
			guanbi() {
				this.jishi = 1
				if (this.shipindingshi) {
					clearInterval(this.shipindingshi);
					this.shipindingshi = null;
				}
				////暂停倒即时4分钟 暂不需要 每次点击拉流即可
				// if (this.chaoshidingshi) {
				// 	setInterval(() => {
				// 		this.chaoshitime = this.chaoshitime - 1
				// 		console.log("开始倒计时", this.chaoshitime, "设置shifouhuoquurl", this.shifouhuoquurl)
				// 		if (this.chaoshitime == 0) {
				// 			this.shifouhuoquurl = 2
				// 			console.log("倒计时为0，设置shifouhuoquurl", this.shifouhuoquurl)
				// 		}
				// 	}, 1000);
				// }

				const _this = this
				_this.player.JS_StopRealPlayAll().then(
					() => {
						// console.info(_this.player);
						// console.log("停止播放");
						if (this.shengyinpanduan != 1) {
							this.dianjishengyin()
						}
						if (this.duijiangpanduan != 1) {
							this.dianjiduijiang()
						}

					},
					(err) => {
						// console.info('JS_StopRealPlayAll failed');
						// console.log("停止播放err");
					}
				);
			},
		}
	}
</script>

<style scoped>
	.anniu {
		width: 120px;
		height: 120px;
		z-index: 999;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -60px;
		margin-left: -60px;
	}

	.duijianganniu {
		float: left;
		margin-left: 15px;
		/* 	margin-top: -265px;
		padding-left: 88.3% !important;
		z-index: 999;
		position: absolute; */
	}

	.gongnengkuang {
		float: left;
		margin-top: -76px !important;
		padding-left: 0% !important;
		width: 400px;
		height: 54px;
		z-index: 999;
		position: absolute;
	}
</style>