<template>
	<div>
		<div :class="className" ref="oneecharts" :style="{ height: height, width: width }" />
	</div>
</template>

<script>
	import * as echarts from 'echarts'

	require('echarts/theme/macarons') // echarts theme
	import resize from './mixins/resize'

	const animationDuration = 2000

	export default {
		mixins: [resize],
		props: {
			className: {
				type: String,
				default: 'chart'
			},
			name: {
				type: String,
				default: ''
			},
			width: {
				type: String,
				default: '100%'
			},
			height: {
				type: String,
				default: '300px'
			},
			autoResize: {
				type: Boolean,
				default: true
			},
			chartData: {
				type: Object,
				// required: true,
				default: null
			}
		},
		data() {
			return {
				// 是否显示弹出层
				cxzbopen: false,
				chart: null,
				data: null,

				min: 0,
			}
		},
		/*    watch: {
			  chartData: {
				deep: true,
				handler(val) {
				  this.setOptions(val)
				}
			  }
			}, */

		mounted() {
			this.$nextTick(() => {
				this.initChart()
				// this.inittanchuangChart()
			})

			if (this.chartData == null) {
				//临时数据
				let frontOneHour = new Date(new Date().getTime());
				frontOneHour = this.$xUtil.parseTime(frontOneHour, '{h}:{i}');
				let frontOneHour_2 = new Date(new Date().getTime() - 2 * 60 * 60 * 1000);
				frontOneHour_2 = this.$xUtil.parseTime(frontOneHour_2, '{h}:{i}');
				let frontOneHour_4 = new Date(new Date().getTime() - 4 * 60 * 60 * 1000);
				frontOneHour_4 = this.$xUtil.parseTime(frontOneHour_4, '{h}:{i}');
				let frontOneHour_6 = new Date(new Date().getTime() - 6 * 60 * 60 * 1000);
				frontOneHour_6 = this.$xUtil.parseTime(frontOneHour_6, '{h}:{i}');
				let frontOneHour_8 = new Date(new Date().getTime() - 8 * 60 * 60 * 1000);
				frontOneHour_8 = this.$xUtil.parseTime(frontOneHour_8, '{h}:{i}');
				let frontOneHour_10 = new Date(new Date().getTime() - 10 * 60 * 60 * 1000);
				frontOneHour_10 = this.$xUtil.parseTime(frontOneHour_10, '{h}:{i}');

				let keys = [frontOneHour_8, frontOneHour_6, frontOneHour_4, frontOneHour_2, frontOneHour];
				this.data = {
					xAxisData: keys,
					seriesData: [10, 10.1, 10.5, 11, 10.4]
				}
			} else {
				this.data = this.chartData;
			}
			// console.log(this.data)
			if (this.data.yuzhiList.length == 0) {
				if (this.chartData.jingjie != null || this.chartData.chaobaozheng != null) {

					this.data.yuzhiList = [{
							"text": "警戒",
							"value": this.chartData.jingjie,
							"symbol": "<",
							"color": "#e9fa00"
						},
						{
							"text": "超保证",
							"value": this.chartData.chaobaozheng,
							"symbol": ">",
							"color": "#ff0000"
						}
					]
				}
			}



		},
		beforeDestroy() {
			if (!this.chart) {
				return
			}
			this.chart.dispose()
			this.chart = null
		},
		methods: {
			handleClickcxzb() {
				this.cxzbopen = false
				this.$emit('on-point')
			},
			fangdaecharts() {
				this.cxzbopen = true
				this.inittanchuangChart()
				console.log(this.cxzbopen)
			},
			initChart() {
				this.chart = echarts.init(this.$refs.oneecharts, 'shine')
				const seriesData = [...this.data.seriesData];

				for (const item of this.data.yuzhiList) {
					seriesData.push(item.value);
				}

				const chumin = Math.min(...seriesData) - 0.5;
				const max = Math.max(...seriesData) + 0.5;

				// if (chumin < 0) {
				// 	this.min = 0;
				// } else {
				this.min = chumin;
				// }

				this.chart.setOption({
					tooltip: {
						trigger: 'axis'
					},
					grid: {
						top: '23%',
						left: '10%',
						right: '10%',
						bottom: '2%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						boundaryGap: false, //坐标轴两边留白
						data: this.data.xAxisData,
						axisLabel: { //坐标轴刻度标签的相关设置。
							// interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
							//	margin:15,
							textStyle: {
								color: '#eeeeee',
								fontStyle: 'normal',
								fontFamily: '微软雅黑',
								fontSize: 12,
							},
						},
						axisTick: { //坐标轴刻度相关设置。
							show: false,
						},
						axisLine: { //坐标轴轴线相关设置
							lineStyle: {
								color: '#CAFFFD',
								opacity: 0.3
							}
						},
						splitLine: { //坐标轴在 grid 区域中的分隔线。
							show: false,
							lineStyle: {
								color: '#E5E9ED',
								// 	opacity:0.1
							}
						}
					},
					yAxis: [{
						min: this.min.toFixed(2),
						max: max.toFixed(2),
						type: 'value',
						name: '单位:(' + this.data.danwei + ')',
						axisLabel: {
							textStyle: {
								color: '#EEEEEE',
								fontStyle: 'normal',
								fontFamily: '微软雅黑',
								fontSize: 12,
							}
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#ffffff',
								opacity: 0.3
							}
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: true,
							lineStyle: {
								type: 'dashed',
								color: 'rgba(202, 255, 253, 0.2)',
								// 	opacity:0.1
							}
						}

					}],
					series: [{
						type: 'line',
						smooth: true,
						symbol: 'circle',
						symbolSize: 5,

						// markPoint: {
						// 	data: [{
						// 			type: 'max',
						// 			name: '最大值'
						// 		},
						// 		{
						// 			type: 'min',
						// 			name: '最小值'
						// 		}
						// 	]
						// },
						// markLine: {
						// 	data: [{
						// 		type: 'average',
						// 		name: '平均值'
						// 	}]
						// },

						itemStyle: {
							// normal: {
							// 	color: 'rgba(0, 255, 255, 1.0)',
							// 	shadowColor: '#00ffff',
							// 	shadowBlur: 10,
							// 	borderColor: '#00ffff',
							// 	borderWidth: 2,
							// 	lineStyle: {
							// 		color: "#00ffff",
							// 		width: 1
							// 	},
							// 	areaStyle: {
							// 		color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
							// 			offset: 0,
							// 			color: 'rgba(0,0,0,0)'
							// 		}, {
							// 			offset: 1,
							// 			color: 'rgba(0, 255, 255, 1.0)',
							// 		}]),
							// 	}
							// },

							normal: {
								color: 'rgba(32, 248, 134, 1)',
								shadowColor: '#20F886',
								shadowBlur: 10,
								borderColor: '#20F886',
								borderWidth: 2,
								lineStyle: {
									color: "#20F886",
									width: 1
								},
								areaStyle: {
									color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
										offset: 0,
										color: 'rgba(0,0,0,0)'
									}, {
										offset: 1,
										color: 'rgba(32, 248, 134, 1)'
									}]),
								}
							}
						},
						data: this.data.seriesData,

						// 横向虚线
						markLine: {
							symbol: ['none'],
							data: this.data.yuzhiList.map(item => {
								const color = item.color || "#00ff00";
								const value = item.value;
								const text = item.text || "";
								return {
									silent: true,
									lineStyle: {
										type: "solid",
										color: color,
									},
									label: {
										position: "top",
										show: false,
										formatter: `{a|${value}}  {b|${text}}`,
										rich: {
											a: {
												color: '#fff',
												fontSize: 15,
											},
											b: {
												color: '#fff',
												fontSize: 15,
												width: '100%',
												align: 'center',
												overflow: 'truncate',
												padding: [0, 5],
											},
										},
									},
									yAxis: value,
									emphasis: {
										label: {
											show: true,
											position: "top",
											textStyle: {
												color: '#fff',
												fontSize: 15,
											}
										}
									}
								};
							}),
						},
					}],

				})
			},


		}
	}
</script>

<style>
	.el-dialog-cxzb .el-dialog {
		position: relative;
		margin: 0 auto 50px;
		background: #154981 !important;
		margin-top: 0vh !important;
		margin-left: 22.3% !important;
		;
		width: 56% !important;
		height: 82% !important;
	}

	.el-dialog-cxzb .x-close {
		width: 50px;
		height: 50px;
		position: absolute;
		right: 30px;
		cursor: pointer;
	}

	.el-dialog-cxzb .el-dialog__headerbtn .el-dialog__close {
		color: #ffffff;
		font-size: 30px;
	}

	.el-dialog-cxzb .el-dialog__body {
		padding: 0px 0px;
		color: #606266;
		width: 100%;
		height: 94%;
	}

	.el-dialog-cxzb .el-dialog__mask {
		background-color: transparent;
	}

	.titlecss {
		text-align: center;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
</style>