<template>
    <div class="x-page">
        <div class="x-btn-close" @click="closeClick"></div>
        <div class="x-title">泵站营运态势</div>
        <div class="x-time" style="top: 100px">{{ nowDate }}</div>

        <div style="width: 85%;margin-left: 10%;position: absolute;top: 140px;text-align: left;line-height: 35px">
            <div class="flex">
                <div class="x-title-left">环境动态:</div>
                <div class="flex-sub">空气温度：<span class="x-value">29℃</span></div>
                <div class="flex-sub">环境湿度：<span class="x-value">34%</span></div>
                <div class="flex-sub">烟雾浓度：<span class="x-value">正常</span></div>
            </div>

            <div class="flex" style="margin-top: 20px">
                <div class="x-title-left">运行动态:</div>
                <div class="flex-sub">水泵运行：<span class="x-value"><span style="color: #08f0ff;">0</span>/6台</span></div>
            </div>

            <div class="flex" style="margin-top: 20px">
                <div class="x-title-left">设备状态:</div>
                <div>
                    <div class="flex" style="width: 430px">
                        <div class="flex-sub">故障水泵：<span class="x-value">0/6 台</span></div>
                        <div class="flex-sub">声纹异常：<span class="x-value">0/6 台</span></div>
                        <div class="flex-sub">电流异常：<span class="x-value">0/6 处</span></div>
                    </div>

                    <div class="flex" style="width: 430px">
                        <div class="flex-sub">电压异常：<span class="x-value">0/6 台</span></div>
                        <div class="flex-sub">维保超期：<span class="x-value">0/6 台</span></div>
                        <div class="flex-sub">空开超温：<span class="x-value">0/6 处</span></div>
                    </div>

                </div>

            </div>

            <div class="flex" style="margin-top: 20px">
                <div class="x-title-left">电力状态:</div>
                <div class="flex-sub">实时电压：<span class="x-value">0V</span></div>
                <div class="flex-sub">实时总电流：<span class="x-value">0A</span></div>
                <div class="flex-sub">年度用电：<span class="x-value">26万kWh</span></div>
            </div>


            <div class="flex" style="margin-top: 20px">
                <div class="x-title-left">供水状态:</div>
                <div class="flex-sub">瞬时流量：<span class="x-value">0m³/s</span></div>
                <div class="flex-sub">年度供水：<span class="x-value">910万m³</span></div>
                <div class="flex-sub"></div>
            </div>


        </div>




    </div>
</template>

<script>
export default {
    name: "x-BengFang-ShuJuMianBan",

    data() {
        return {
            resData: [],
            nowDate: '',
        }
    },

    created() {
    },

    mounted() {
        // this.initData();
        setInterval(() => {
            this.timestampToTime();
        }, 1000 * 3);
    },

    methods: {
        initData(){
            // 广告牌
            this.$request({
                url: `${window.$baseUrl_hz_bz}/led/getScreenShow?deviceld=802&update=1`,//接口未开发
                type: 'get',
            }).then((val) => {
                let data = val.rows
                this.resData = data;
                // window.ue.web.change_yongzhen_led(
                //     data[0].title,
                //     data[1].time,
                //     `实时水位:${data[2]["实时水位"]}`, `可纳降雨:${data[2]["可纳降雨"]}`,
                //     `设计水位:${data[3]["设计水位"]}`, `汛限水位:${data[3]["汛限水位"]}`,
                //     `可充库容:${data[4]["可充库容"]}`, `实时库容:${data[4]["实时库容"]}`,
                //     `可用库容:${data[5]["可用库容"]}`, `可用天数:${data[5]["可用天数"]}`,
                //     `实时水温:${data[6]["实时水温"]}`, `实时水质:${data[6]["实时水质"]}`,
                //     `24h雨量:${data[7]["24h雨量"]}`, `空气温度:${data[7]["空气温度"]}`,
                //     `出库流量:${data[8]["出库流量"]}`, `入库流量:${data[8]["入库流量"]}`,
                //     `位移监测:${data[9]["位移监测"]}`, `渗压监测:${data[9]["渗压监测"]}`,
                //     data[10].tail,
                // );
            });
        },

        timestampToTime() {
          let date = new Date();//时间戳为10位需*1000，时间戳为13位的话不需乘1000
          let Y = date.getFullYear() + '-';
          let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1):date.getMonth()+1) + '-';
          let D = (date.getDate()< 10 ? '0'+date.getDate():date.getDate())+ ' ';
          let h = (date.getHours() < 10 ? '0'+date.getHours():date.getHours())+ ':';
          let m = (date.getMinutes() < 10 ? '0'+date.getMinutes():date.getMinutes()) + ':';
          let s = date.getSeconds() < 10 ? '0'+date.getSeconds():date.getSeconds();
          this.nowDate = Y + M + D + h + m + s;
      },

        closeClick(){
            this.$emit('on-close')
        }
    }
};
</script>

<style scoped>
.x-page{
    background: url("~@/assets/YongZhenShuiKu/组件03.png") no-repeat;
    background-size: 100% 100%;
    width: 650px;
    height: 500px;
    color: white;
    font-family: 'Source Han Sans CN';
    position: fixed;
    top: 100px;
    left: calc(50% - 325px);
    z-index: 99999;
    margin-left: 55px;
}

.x-title{
    position: absolute;
    top: 40px;
    font-size: 22px;
    width: 100%;
    letter-spacing: 2px;
}

.x-time{
    position: absolute;
    top: 100px;
    font-size: 18px;
    width: 100%;
    color: #08f0ff;
}

.x-value{
    color: #2df280;
}

.x-btn-close{
    width: 50px;
    height: 50px;
    position: absolute;
    right: 27px;
    top: 30px;
    z-index: 9999;
}

.x-title-left{
    width: 120px;
    font-size: 20px;
}

.flex-sub{
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}
</style>
