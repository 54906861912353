<template>
  <div style="object-fit: fill;width: 100%;height: 100%;">
    <video ref="videoRef" autoplay controls style="object-fit: fill;width: 100%;height: 100%;"></video>
  </div>
</template>

<script>
export default {
  name: "x-video-hls",
  data: () => {
    return {}
  },
  props: ['videoUrl'],
  created() {
  },
  mounted() {
    let video = this.$refs.videoRef;
    let videoSrc = this.videoUrl;

    if (video.canPlayType('application/vnd.apple.mpegurl')) {
      // 浏览器本身支持HLS
      video.src = videoSrc;
    } else if (Hls.isSupported()) {
      // 检查是否支持HLS.js
      let hls = new Hls();
      hls.loadSource(videoSrc);
      hls.attachMedia(video);
    }
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>

<style scoped>

</style>


<style scoped>

</style>
