import Vue from 'vue'
import App from './App.vue'
import router from './router'
import xScaleBox from '@/components/x-ScaleBox/index'
import xSmallTitle from '@/components/x-small-title-bg/index'
import './style/index.css'
import * as echarts from "echarts"
import 'echarts-gl';
import store from './store';
import xUtil from '@/utils/x-util.js'

// 图片上传组件
import ImageUpload from "@/components/ImageUpload"

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import request from './utils/request.js'
import vueSeamlessScroll from 'vue-seamless-scroll'
// import { download,downloadDaping } from '@/utils/request' //downloadDaping 2023.10.25失踪
import { download } from '@/utils/request'
import { panduanbackClick } from '@/components/x-kanbanfanhui/fanhuipanduan.js'

//引入localforage
import localforage from "localforage";
localforage.config({
  name: 'myDatabase'
});
Vue.prototype.$localForage = localforage;


Vue.config.productionTip = false

Vue.prototype.$echarts = echarts
Vue.prototype.$request = request
Vue.prototype.$xUtil = xUtil
Vue.prototype.download = download
// Vue.prototype.downloadDaping = downloadDaping
Vue.prototype.$panduanbackClick = panduanbackClick

Vue.component('ImageUpload', ImageUpload)

Vue.component('xScaleBox', xScaleBox);
Vue.component('xSmallTitle', xSmallTitle);
// 无限滚动
Vue.component('vueSeamlessScroll', vueSeamlessScroll);

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
export const EventBus = new Vue();
