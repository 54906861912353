import request from '@/utils/request'

// 查询水情参报事件
export function baojinglist(query) {
  return request({
    url: '/daping/warningEventList',
    method: 'get',
    params: query
  })
}
// 查询水情参报事件
export function sjggbaojinglist(query) {
  return request({
    url: '/shuijiagaige/daping/warningEventList',
    method: 'get',
    params: query
  })
}