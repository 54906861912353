<template>
  <div>
    <x-dialog-comp title="运行记录" @on-close="$emit('on-close')">
      <div class="text-white douyuFont">
        <div class="flex flex-wrap " style="position: relative;top: 10px">
          <div class="flex align-center text-df mb-2" style="width: 30%">
            <div class="flex align-center" style="width: 160px">
              <div class="x-lingxing"></div>
              <div class="x-text-shadow">调度人：</div>
            </div>
            <div class="x-ellipsis text-left" style="width: 160px">
              宋连军 <span class="text-sm"></span>
            </div>
          </div>
          <div class="flex align-center text-sm mb-2" style="width: 30%">
            <div class="flex align-center" style="width: 160px">
              <div class="x-lingxing"></div>
              <div class="x-text-shadow">调度开始时间：</div>
            </div>
            <div class=" x-ellipsis text-left" style="width: 160px">
              {{getDay(-3)}} <span class="text-sm"></span>
            </div>
          </div>
          <div class="flex align-center text-sm mb-2" style="width: 30%">
            <div class="flex align-center" style="width: 160px">
              <div class="x-lingxing"></div>
              <div class="x-text-shadow">调度结束时间：</div>
            </div>
            <div class=" x-ellipsis" style="width: 160px">
              {{getDay(-1)}} <span class="text-sm"></span>
            </div>
          </div>

          <div class="flex align-center text-sm mb-2" style="width: 30%">
            <div class="flex align-center" style="width: 160px">
              <div class="x-lingxing"></div>
              <div class="x-text-shadow">最高水位：</div>
            </div>
            <div class=" x-ellipsis text-left" style="width: 160px">
              9 <span class="text-sm">m</span>
            </div>
          </div>

          <div class="flex align-center text-sm mb-2" style="width: 30%">
            <div class="flex align-center" style="width: 200px">
              <div class="x-lingxing"></div>
              <div class="x-text-shadow">最高水位发生时间：</div>
            </div>
            <div class=" x-ellipsis text-left" style="width: 160px;font-size: 12px;">
              {{getDay(-2)}}<span class="text-sm"></span>
            </div>
          </div>

          <div class="flex align-center text-sm mb-2" style="width: 30%"></div>

          <div class="flex align-center text-sm mb-2" style="width: 30%">
            <div class="flex align-center" style="width: 160px">
              <div class="x-lingxing"></div>
              <div class="x-text-shadow">调度指令：</div>
            </div>
            <div class=" x-ellipsis text-left" style="width: 160px">
              正常放水
            </div>
          </div>

          <div class="flex align-center text-sm mb-2" style="width: 30%"></div>
          <div class="flex align-center text-sm mb-2" style="width: 30%"></div>

          <div class="flex align-center text-sm mb-2" style="width: 30%">
            <div class="flex align-center" style="width: 160px">
              <div class="x-lingxing"></div>
              <div class="x-text-shadow">调度研判：</div>
            </div>
            <div class=" x-ellipsis text-left text-green" style="width: 160px">
              A
            </div>
          </div>

        </div>

        <div class="margin-top margin-left">
          <div class="x-sm-title-bg">
            <div class="margin-left-xl">水位过程曲线</div>
          </div>
          <div style="width: 100%;height: 250px" ref="shuiWeiRef"></div>
        </div>

        <div class="margin-top margin-left">
          <div class="x-sm-title-bg">
            <div class="margin-left-xl">流量过程曲线</div>
          </div>
          <div style="width: 100%;height: 250px" ref="liuLiangRef"></div>
        </div>

      </div>
    </x-dialog-comp>
  </div>
</template>

<script>
import XDialogComp from "@/components/x-dialog-comp";

export default {
  name: "x-operation-record",
  components: {XDialogComp},
  props: {},
  data() {
    return {}
  },
  created() {
  },
  mounted() {
    let keys = [
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
    ];
    this.initEchartsRef(this.$refs.shuiWeiRef, keys, [9, 8, 8, 8.5, 8.5, 8.5, 8.5, 7.5, 8.5]);
    this.initEchartsRef(this.$refs.liuLiangRef, keys, [2, 3, 3, 3.5, 2.5, 2.5, 3.5, 2.5, 2.5]);
  },
  destroyed() {
  },
  methods: {
		//获取当前日期
			getDay(day){
		　　var today = new Date();
		　　var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;
		　　today.setTime(targetday_milliseconds); //注意，这行是关键代码
		　　var tYear = today.getFullYear();
		　　var tMonth = today.getMonth();
		　　var tDate = today.getDate();
		　　return tYear+"年"+(tMonth + 1)+"月"+tDate+"日";
		},
		
    initEchartsRef(dom, xData, yData) {
      let myChart = this.$echarts.init(dom, null);
      let option = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: '10%',
          left: '0%',
          right: '10%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false, //坐标轴两边留白
          data: xData,
          axisLabel: { //坐标轴刻度标签的相关设置。
            interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
            //	margin:15,
            textStyle: {
              color: '#eeeeee',
              fontStyle: 'normal',
              fontFamily: '微软雅黑',
              fontSize: 12,
            },
          },
          axisTick: { //坐标轴刻度相关设置。
            show: false,
          },
          axisLine: { //坐标轴轴线相关设置
            lineStyle: {
              color: '#CAFFFD',
              opacity: 0.3
            }
          },
          splitLine: { //坐标轴在 grid 区域中的分隔线。
            show: false,
            lineStyle: {
              color: '#E5E9ED',
              // 	opacity:0.1
            }
          }
        },
        yAxis: [{
          type: 'value',
          axisLabel: {
            textStyle: {
              color: '#EEEEEE',
              fontStyle: 'normal',
              fontFamily: '微软雅黑',
              fontSize: 12,
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#CAFFFD',
              opacity: 0.3
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: 'rgba(202, 255, 253, 0.2)',
              // 	opacity:0.1
            }
          }

        }],
        series: [{
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 5,
          itemStyle: {
            normal: {
              color: 'rgba(32, 248, 134, 1)',
              shadowColor: '#20F886',
              shadowBlur: 10,
              borderColor: '#20F886',
              borderWidth: 2,
              lineStyle: {
                color: "#20F886",
                width: 1
              },
              areaStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                  offset: 0,
                  color: 'rgba(0,0,0,0)'
                }, {
                  offset: 1,
                  color: 'rgba(32, 248, 134, 1)'
                }]),
              }
            }
          },
          data: yData
        },]
      };
      myChart.setOption(option);
    },
  },
}
</script>

<style scoped>

.x-sm-title-bg {
  background: url("~@/assets/ZhiNengXiTong/title.png") no-repeat;
  background-size: 100% 100%;
  width: 300px;
  height: 35px;
  display: flex;
  align-items: center;
}
</style>
