<template>
    <div style="position: relative;pointer-events: auto">
        <!-- HZ视频 -->
        <x-video v-if="showvideoHZType"
                 :video-src="videoData"
                 @on-close="closeClick"></x-video>
        <div style="position: absolute;right: 420px;top: 200px;z-index: 99999">
            <el-select v-model="checkVideo" placeholder="请选择" @change="checkVideoChange">
                <el-option
                        v-for="item in videoList"
                        :key="item.bindingId"
                        :label="item.name"
                        :value="item.bindingId">
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
import XVideo from "@/components/x-video/index.vue";

export default {
    name: "x-video-list-play",
    components: {XVideo},
    props: ['videoList'],
    data() {
        return {
            showvideoHZType: false,
            checkVideo: '',
            videoData: {},
        };
    },

    created() {
        this.videoData = {
            bindingId: this.videoList[0].bindingId,
            name: this.videoList[0].name
        };
        this.checkVideo = this.videoData.bindingId;
        this.showvideoHZType = true;
    },

    mounted() {

    },

    methods: {
        closeClick() {
            this.$emit('on-close')
        },

        checkVideoChange(val){
            for(let item of this.videoList){
                if(item.bindingId === val){
                    this.showvideoHZType = false;
                    this.$nextTick(()=>{
                        this.videoData = {
                            bindingId: item.bindingId,
                            name: item.name
                        };
                        this.showvideoHZType = true;
                    })
                }

            }

        }
    }
};
</script>

<style>

</style>
