<template>
  <div class="cell-player">
    <div v-for="i in cellCount" :key="i" :class="cellClass(i)" style="position: relative">
      <el-tooltip class="item" effect="dark" content="关闭" placement="top">
        <div style="position: absolute;right: 20px;top: 10px;z-index: 1111;color: #03d2ff;font-size: 27px" @click="videoCleanClick(i)" v-if="videoList[i - 1]">
          <i class="el-icon-circle-close"></i>
        </div>
      </el-tooltip>

      <x-cell-player :title="i" v-if="cellCount !== 6" :check-id="checkId" @on-click="videoClick"></x-cell-player>
      <x-cell-player :title="i" v-if="cellCount === 6 && i !== 2 && i !== 3" :check-id="checkId"
                     @on-click="videoClick"></x-cell-player>
      <template v-if="cellCount === 6 && i === 2">
        <div class="cell-player-6-2-cell">
          <x-cell-player :title="i" :check-id="checkId" @on-click="videoClick"></x-cell-player>
          <x-cell-player :title="(++i)" :check-id="checkId" @on-click="videoClick"></x-cell-player>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import XCellPlayer from '@/views/video/components/XCellPlayer'

export default {
  name: 'x-video-list',
  components: {XCellPlayer},
  props: {
    cellCount: {
      type: Number,
      default: 4
    },
    videoList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data: () => {
    return {
      checkId: '',
    }
  },
  computed: {
    cellClass() {
      return function (index) {
        switch (this.cellCount) {
          case 1:
            return ['cell-player-1']
          case 4:
            return ['cell-player-4']
          case 6:
            if (index === 1) {
              return ['cell-player-6-1']
            }
            if (index === 2) {
              return ['cell-player-6-2']
            }
            if (index === 3) {
              return ['cell-player-6-none']
            }
            return ['cell-player-6']
          case 9:
            return ['cell-player-9']
          case 16:
            return ['cell-player-16']
          default:
            break
        }
      }
    }
  },
  methods: {
    // 视频清空点击事件
    videoCleanClick(i) {
      this.$parent.videoList[i - 1] = ''
      this.$parent.videoList = JSON.parse(JSON.stringify(this.$parent.videoList))
    },


    // 视频点击事件
    videoClick(val) {
    },
  }
}
</script>

<style scoped>

.cell-player {
  flex: 1;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cell-player-4 {
  width: 50%;
  height: 50% !important;
  box-sizing: border-box;
}

.cell-player-1 {
  width: 100%;
  box-sizing: border-box;
}

.cell-player-6-1 {
  width: 66.66%;
  height: 66.66% !important;
  box-sizing: border-box;
}

.cell-player-6-2 {
  width: 33.33%;
  height: 66.66% !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.cell-player-6-none {
  display: none;
}

.cell-player-6-2-cell {
  width: 100%;
  height: 50% !important;
  box-sizing: border-box;
}

.cell-player-6 {
  width: 33.33%;
  height: 33.33% !important;
  box-sizing: border-box;
}

.cell-player-9 {
  width: 33.33%;
  height: 33.33% !important;
  box-sizing: border-box;
}

.cell-player-16 {
  width: 25%;
  height: 25% !important;
  box-sizing: border-box;
}

.cell {
  display: flex;
  flex-direction: column;
  height: 100%;
}

</style>
