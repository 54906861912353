<template>
  <div style="color:#fff">
    <x-dialog-comp title="水质监测" @on-close="$emit('on-close')">
      <div v-loading="loadingType" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <x-title-btn :menu-data.sync="titleMenuData"></x-title-btn>

        <x-real-time v-if="titleMenuData[0].checkType"></x-real-time>
        <x-details v-if="titleMenuData[2].checkType" :res-data="resData"></x-details>
        <div v-if="titleMenuData[3].checkType" class="x-aq-cnt">
          <x-an-quan-fen-xi></x-an-quan-fen-xi>
        </div>

      </div>
    </x-dialog-comp>
  </div>
</template>

<script>
import XDialogComp from "@/components/x-dialog-comp";
import XTitleBtn from "@/components/x-title-btn";
import XRealTime from "@/components/x-device-waterQuality-dialog/components/x-real-time";
import XDetails from "@/components/x-device-waterQuality-dialog/components/x-details";
import { getWaterQuality } from "@/api/device/waterQuality";
import XAnQuanFenXi from "@/components/x-AnQuanFenXi";

export default {
  name: "x-device-waterQuality-dialog",
  components: { XAnQuanFenXi, XDetails, XRealTime, XDialogComp, XTitleBtn },
  props: {
    deviceId: "",
  },
  data() {
    return {
      loadingType: false,
      resData: {},
      titleMenuData: [
        {
          checkType: true,
          name: '实时数据',
        }, {
          checkType: false,
          name: '历史数据',
        }, {
          checkType: false,
          name: '设备详情',
        }, {
          checkType: false,
          name: '安全研判',
        }
      ],
    }
  },
  created() {
    this.initData();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    initData() {
      // getWaterQuality(this.deviceId).then((val) => {
      //   this.loadingType = false;
      //   this.resData = val.data;
      // })
    },

  },
}
</script>

<style scoped>
.x-aq-cnt {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
