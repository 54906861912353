import request from '@/utils/request'

// 查询位移设备信息列表
export function listDisplacement(query) {
  return request({
    url: '/device/displacement/list',
    method: 'get',
    params: query
  })
}

// 查询位移设备信息详细
export function getDisplacement(id) {
  return request({
    url: '/device/displacement/' + id,
    method: 'get'
  })
}

// 新增位移设备信息
export function addDisplacement(data) {
  return request({
    url: '/device/displacement',
    method: 'post',
    data: data
  })
}

// 修改位移设备信息
export function updateDisplacement(data) {
  return request({
    url: '/device/displacement',
    method: 'put',
    data: data
  })
}

// 删除位移设备信息
export function delDisplacement(id) {
  return request({
    url: '/device/displacement/' + id,
    method: 'delete'
  })
}
