/**
* @Author liYang
* @Date 2022/9/15 23:24
* @Description: TODO
* @Version 1.0
*/
<template>
  <div>
    <div class="flex">
      <div class="x-small-title cursor " v-for="item of menuData" @click="itemTypeClick(item)"
           :class="item.checkType ? 'x-small-bg-img-check' : 'x-small-bg-img'">
        {{ item.name }}
        <div v-if="item.redIconType"
             style="width: 8px;height: 8px;background-color: red;border-radius: 50%;position: absolute;left: -3px;top: -3px"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "x-title-btn",
  props: {
    menuData: {
      type: Array,
      default: []
    }
  },
  data() {
    return {}
  },

  created() {
  },

  mounted() {
  },

  beforeDestroy() {

  },

  methods: {
    itemTypeClick(val) {
      for (let item of this.menuData) {
        item.checkType = false;
      }
      val.checkType = true;
      this.$emit('on-click', val);
    }
  }
}
</script>

<style scoped>

</style>
