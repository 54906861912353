<template>
  <div class="player" @click="playerClick" :class="checkId === title ? 'x-border' : ''">
    <div v-if="$parent.videoList[title - 1]"  style="object-fit: fill;width: 100%;height: 100%;">
      <x-video-hls :video-url="$parent.videoList[title - 1].jiankurlHd"></x-video-hls>
    </div>
    <div v-else>
      通道 {{ title }}
    </div>
  </div>
</template>

<script>
import XVideoHls from "@/views/video/components/x-video-hls";
export default {
  components: {XVideoHls},
  props: {
    title: '',
    checkId: '',
  },
  watch: {

  },
  methods: {
    playerClick() {
      this.$emit('on-click', this.title)
    }
  }
}
</script>

<style scoped>
.player {
  background-color: #041B20;
  height: 97%;
  /*border: 1px solid white;*/
  border-radius: 10px;
  color: white;
  text-align: center;
  margin: 10px;
}

.x-border{
  border: 3px solid #fa1010;
}

</style>
