const jiankong = {
  state: {
    activeList: JSON.stringify([]),//左侧视频列表点击
    windowNum: 1+'',//视频窗口数量
    activeGridding: 1,//点击的哪个窗口
    isHistory:false,
    activeHis:"{}",//历史回放激活左侧菜单


   
  },

  mutations : {
    ActiveList(state, payload) {
      state.activeList=payload
    },
    WindowNum(state, payload) {
      state.windowNum=payload+''
    },
    ActiveGridding(state, payload) {
      state.activeGridding=payload
    },
    IsHistory(state, payload) {
      state.isHistory=payload
     },
     ActiveHis(state, payload) {
      state.activeHis=payload
     }


  },
  action: {
    setActiveList({ commit }, data) {
      commit('ActiveList', data)
    },
    setWindowNum({ commit }, data) {
      commit('WindowNum', data)
    },
    setActiveGridding({ commit }, data) {
      commit('ActiveGridding', data)
    },
    setIsHistory({ commit }, data) {
      commit('IsHistory', data)
    },
    setActiveHis({ commit }, data) {
      commit('ActiveHis', data)
    },

  },
}

export default jiankong