/**
* @Author liYang
* @Date 2022/9/16 1:05
* @Description: TODO
* @Version 1.0
*/
<template>
  <div>
    <div class="flex margin-top">
      <!--      <el-radio-group v-model="typeRadio" size="small" @change="typeRadioChange">-->
      <!--        <el-radio-button label="近3天位移记录"></el-radio-button>-->
      <!--        <el-radio-button label="近7天位移记录"></el-radio-button>-->
      <!--        <el-radio-button label="时间段查询"></el-radio-button>-->
      <!--        <el-radio-button label="按年查询"></el-radio-button>-->
      <!--      </el-radio-group>-->
    </div>

    <div class="margin-top flex">
      <div>
        <div style="width: 500px;height: 500px;" ref="WeiYi3Ref"></div>
        <div class="douyuFont">
          安全等级：<span class="text-green margin-left">A</span>
        </div>
      </div>

      <div class="text-white text-left" style="line-height: 30px">
        <div class="flex">
          <span style="color: #0f386233;">{{deviceId}}</span>
        </div>
        <div class="flex">
          <div class="x-lingxing"></div>
          X轴（北方向）位移：0.001m，阈值：0.1m，正常
        </div>
        <div class="flex">
          <div class="x-lingxing"></div>
          Y轴（东方向）位移：0.002m，阈值：0.1m，正常
        </div>
        <div class="flex">
          <div class="x-lingxing"></div>
          H轴（高度）位移：0.001m，阈值：0.1m，正常
        </div>
        <div class="flex">
          <div class="x-lingxing"></div>
          XY轴（二维）位移：0.001m，阈值：0.1m，正常
        </div>
        <div class="flex">
          <div class="x-lingxing"></div>
          XYH轴（三维）位移：0.001m，阈值：0.1m，正常
        </div>
        <div style="width: 500px;height: 400px;" ref="weiYi2Ref">
        </div>
      </div>
    </div>


  </div>
</template>

<script>

export default {
  name: "x-shiShiShuJu",
  props: ['deviceId'],
  data() {
    return {
      // 是否显示更新时间字段
      showTime: true,
      typeRadio: '实时水位',
      resData: [],
      // 水库水位高程(m)
      shuiKuShuiWeiGaoCheng: '',
    }
  },

  created() {
  },

  mounted() {
    this.initData();
  },

  beforeDestroy() {

  },

  methods: {

    initData() {
      this.emitLoading(false);
      this.initWeiyijianceRef();
      this.initWeiyijianceRef2();
    },

    // 初始化位移监测
    initWeiyijianceRef() {
      this.$request(
          {
            url: '/RequestRelay',
            method: 'post',
            headers: {
              isToken: false
            },
            data: {
              url: `${window.$baseUrl_hz_sk}/TjcUpbuMEk.php/irriinfo/stationinfo/weiyirecord/ajaxNowdata?stationinfo_id=${this.deviceId}`,
              type: 'get',
            }
          }
      ).then((val) => {
        let data = JSON.parse(val.msg);
        this.resData = data.rows;
        this.total = data.total;
      });

      let lineData = [
        [[0, 0, 0], [1, 1, 1]],
        [[0, 0, 0], [-1, 1, 1]]
      ]
      let pointData = [[1, 1, 1], [-1, 1, 1]];
      let myChart = this.$echarts.init(this.$refs.WeiYi3Ref, null);
      let option = {
        tooltip: {},
        xAxis3D: {
          name: "横河向位移",
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#ff0000",
            }
          },
          nameTextStyle: {
            color: "#ffffff",
            fontSize: 12,
          },
          axisLabel: {
            color: "#ffffff",
            fontSize: 12,
          },
        },
        yAxis3D: {
          name: "顺河向位移",
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#fffa00",
            }
          },
          nameTextStyle: {
            color: "#ffffff",
            fontSize: 12,
          },
          axisLabel: {
            color: "#ffffff",
            fontSize: 12,
          }
        },
        zAxis3D: {
          name: "垂直位移",
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#00ff3c",
            }
          },
          nameTextStyle: {
            color: "#ffffff",
            fontSize: 12,
          },
          axisLabel: {
            color: "#ffffff",
            fontSize: 12,
          }
        },
        grid3D: {
          axisPointer: {
            lineStyle: {
              color: "#f00",
            },
            show: false,
          },
          viewControl: {
            autoRotate: true,

          },
          boxWidth: 100,
          boxHeight: 100,
          boxDepth: 80,
          top: -20,
        },

        series: [],
      };
      for (let item of lineData) {
        option.series.push({
          type: "line3D",
          data: item,
          lineStyle: {
            color: "#5470C6",
            width: 3,
          },
        });
      }
      option.series.push({
        type: "scatter3D",
        data: pointData,
        itemStyle: {
          color: "#ff0099",
          width: 3,
        },
      });
      myChart.setOption(option);
    },

    // 初始化位移监测2D
    initWeiyijianceRef2() {

      let Date8 = new Date();
      let date = this.getNowTime(new Date());
      let date7 = this.getNowTime(new Date(Date8.getTime() - 168 * 60 * 60 * 1000));
      this.$request(
          {
            url: '/RequestRelay',
            method: 'post',
            headers: {
              isToken: false
            },
            data: {
              url: `${window.$baseUrl_hz_sk}/TjcUpbuMEk.php/irriinfo/stationinfo/weiyirecord/ajaxBetween?stationId=${this.deviceId}&devId=1456&riqi=${date7}+%E5%88%B0+${date}`,
              type: 'get',
            }
          }
      ).then((val) => {
        let data = JSON.parse(val.msg);

        let myChart = this.$echarts.init(this.$refs.weiYi2Ref, null);
        let color = ['#00ff22', '#f4e000', '#ff0000']; //2个以上的series就需要用到color数组
        let legend = {
          //data，就是取得每个series里面的name属性。
          orient: 'horizontal',
          icon: 'rect', //图例形状
          padding: 0,
          bottom: 10,
          right: 40,
          itemWidth: 14, //小圆点宽度
          itemHeight: 1, // 小圆点高度
          itemGap: 21, // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
          textStyle: {
            fontSize: 14,
            color: '#ffffff',
          },
        };
        let tooltip = {
          show: true,
          trigger: 'axis',
        };
        let seriesData = [
          {
            "name": "X轴",
            "data": data.data.dayXl,
            "type": "line",
            "smooth": true,
            "symbol": "emptyCircle",
            "symbolSize": 6,
            "itemStyle": {},
            "label": {"show": false},
            "lineStyle": {"width": 1},
            "areaStyle": {
              "opacity": 0.2,
              "color": {
                "type": "linear",
                "x": 0,
                "y": 0,
                "x2": 0,
                "y2": 1,
                "colorStops": [{"offset": 0, "color": "#00ff22"}, {"offset": 1, "color": "transparent"}],
                "global": false
              }
            }
          }, {
            "name": "Y轴",
            "data": data.data.dayYl,
            "type": "line",
            "smooth": true,
            "symbol": "emptyCircle",
            "symbolSize": 6,
            "itemStyle": {},
            "label": {"show": false},
            "lineStyle": {"width": 1},
            "areaStyle": {
              "opacity": 0.2,
              "color": {
                "type": "linear",
                "x": 0,
                "y": 0,
                "x2": 0,
                "y2": 1,
                "colorStops": [{"offset": 0, "color": "#f4e000"}, {"offset": 1, "color": "transparent"}],
                "global": false
              }
            }
          }, {
            "name": "H轴",
            "data": data.data.dayHl,
            "type": "line",
            "smooth": true,
            "symbol": "emptyCircle",
            "symbolSize": 6,
            "itemStyle": {},
            "label": {"show": false},
            "lineStyle": {"width": 1},
            "areaStyle": {
              "opacity": 0.2,
              "color": {
                "type": "linear",
                "x": 0,
                "y": 0,
                "x2": 0,
                "y2": 1,
                "colorStops": [{"offset": 0, "color": "#ff0000"}, {"offset": 1, "color": "transparent"}],
                "global": false
              }
            }
          }];
        let option = {
          color,
          tooltip,
          legend,
          grid: {
            top: '20%',
            left: '3%',
            right: '4%',
            bottom: '15%',
            containLabel: true,
          },

          xAxis: {
            show: true, //显示x轴+x轴label文字
            type: 'category',
            boundaryGap: false, //从Y轴出发，这个false很好的
            axisLine: {
              show: true, //显示x坐标轴轴线
              lineStyle: {
                color: 'rgba(255,255,255,.4)',
              },
            },
            axisTick: {
              show: false, //不显示x坐标1cm刻度
            },
            axisLabel: {
              color: '#ffffff', //x轴label文字颜色
            },
            splitLine: {
              show: false, //不显示grid竖向分割线
            },

            data: data.data.date,
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              color: '#ffffff',
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: 'rgba(255,255,255,.4)',
              },
            },
            splitLine: {
              show: false, //不显示grid水平分割线
            },
          },

          series: seriesData,
        };
        myChart.setOption(option)
      });
    },


    emitLoading(type) {
      this.$emit('on-loading', type)
    },


    getNowTime(date) {
      let arr = ['日', '一', '二', '三', '四', '五', '六',]
      let year = date.getFullYear();
      let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
      let dates = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let day = date.getDay();
      let days = arr[day];
      let data = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      let nowData = `${year}-${month}-${dates}`;
      return nowData;
    },

    // typeRadioChange(val) {
    //   switch (val) {
    //     case '近3天位移记录':
    //       this.initData();
    //       break;
    //     case '近7天位移记录':
    //       this.initTimeSlotData(1);
    //       break;
    //     case '时间段查询':
    //       this.initData();
    //       break;
    //     case '按年查询':
    //       this.initData();
    //       break;
    //   }
    //   console.log(11, val);
    // },

  }
}
</script>

<style scoped>
.x-lingxing {
  background: url("~@/assets/common/形状 1 (1).png") no-repeat;
  background-size: 100% 100%;
  width: 27px;
  height: 27px;
  position: relative;
}
</style>
