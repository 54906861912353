/**
* @Author liYang
* @Date 2022/9/15 23:18
* @Description: TODO
* @Version 1.0
*/
<template>
  <div>
    <div class="x-tc-page-bg">
      <div class="x-tc-title douyuFont">
        {{ title }}
      </div>
      <div class="x-close" @click="closeClick"></div>
      <div class="x-tc-cnt">
       <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "x-dialog-comp",
  props: ['title'],
  data() {
    return {}
  },

  created() {
  },

  mounted() {
  },

  beforeDestroy() {

  },

  methods: {
    closeClick() {
      this.$emit('on-close')
    },
  }
}
</script>

<style scoped>
.x-tc-page-bg {
/*  background: url("~@/assets/common/tanChuang.png") no-repeat;
  background-size: 100% 100%;
  width: 1132px;
  height: 813px;
  position: fixed;
  top: 100px;
  left: 400px;
  z-index: 99999; */
  
  	background: url("~@/assets/common/tanChuang.png") no-repeat;
  	background-size: 100% 100%;
  	width: 1132px;
  	height: 813px;
  	position: fixed;
  	top: 135px;
  	left: 400px;
  	z-index: 99999;
}

.x-tc-title {
  font-size: 22px;
  position: absolute;
  left: 80px;
  top: 15px;
  color: white;
  letter-spacing: 5px;
}

.x-close {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 30px;
  cursor: pointer;
}


.x-tc-cnt {
  width: 1085px;
  height: 750px;
  position: absolute;
  left: 22px;
  top: 58px;
  overflow: hidden;
}
</style>
