class XUtil {
	constructor() {}

	/**
	 * @Description: 根据传入的val判断返回文字闪烁效果 【gj、yu】
	 * @Author: LiYang
	 * @Date:  2022/7/26  0:07
	 */
	getTextAnimation(val) {
		switch (val) {
			case 'gj':
				return 'x-text-shanShuo-gaoJing';
			case 'yj':
				return 'x-text-shanShuo-yuJing';
		}

	}

	// 日期格式化
	parseTime(time, pattern) {
		if (arguments.length === 0 || !time) {
			return null
		}
		const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
		let date
		if (typeof time === 'object') {
			date = time
		} else {
			if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
				time = parseInt(time)
			} else if (typeof time === 'string') {
				time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
			}
			if ((typeof time === 'number') && (time.toString().length === 10)) {
				time = time * 1000
			}
			date = new Date(time)
		}
		const formatObj = {
			y: date.getFullYear(),
			m: date.getMonth() + 1,
			d: date.getDate(),
			h: date.getHours(),
			i: date.getMinutes(),
			s: date.getSeconds(),
			a: date.getDay()
		}
		const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
			let value = formatObj[key]
			// Note: getDay() returns 0 on Sunday
			if (key === 'a') {
				return ['日', '一', '二', '三', '四', '五', '六'][value]
			}
			if (result.length > 0 && value < 10) {
				value = '0' + value
			}
			return value || 0
		})
		return time_str
	}

	getDate7() {
		let date0 = new Date();
		date0 = this.parseTime(date0, '{m}-{d}');

		let date1 = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
		date1 = this.parseTime(date1, '{m}-{d}');

		let date2 = new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000);
		date2 = this.parseTime(date2, '{m}-{d}');

		let date3 = new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000);
		date3 = this.parseTime(date3, '{m}-{d}');

		let date4 = new Date(new Date().getTime() - 4 * 24 * 60 * 60 * 1000);
		date4 = this.parseTime(date4, '{m}-{d}');

		let date5 = new Date(new Date().getTime() - 5 * 24 * 60 * 60 * 1000);
		date5 = this.parseTime(date5, '{m}-{d}');

		let date6 = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);
		date6 = this.parseTime(date6, '{m}-{d}');

		return [date6, date5, date4, date3, date2, date1, date0];
	}

	getDateAdd7() {
		let date0 = new Date();
		date0 = this.parseTime(date0, '{m}-{d}');

		let date1 = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
		date1 = this.parseTime(date1, '{m}-{d}');

		let date2 = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000);
		date2 = this.parseTime(date2, '{m}-{d}');

		let date3 = new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000);
		date3 = this.parseTime(date3, '{m}-{d}');

		let date4 = new Date(new Date().getTime() + 4 * 24 * 60 * 60 * 1000);
		date4 = this.parseTime(date4, '{m}-{d}');

		let date5 = new Date(new Date().getTime() + 5 * 24 * 60 * 60 * 1000);
		date5 = this.parseTime(date5, '{m}-{d}');

		let date6 = new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000);
		date6 = this.parseTime(date6, '{m}-{d}');

		return [date0, date1, date2, date3, date4, date5, date6];
	}

	/**
	 * 构造树型结构数据
	 * @param {*} data 数据源
	 * @param {*} id id字段 默认 'id'
	 * @param {*} parentId 父节点字段 默认 'parentId'
	 * @param {*} children 孩子节点字段 默认 'children'
	 */
	handleTree(data, id, parentId, children) {
		let config = {
			id: id || 'id',
			parentId: parentId || 'parentId',
			childrenList: children || 'children'
		};

		var childrenListMap = {};
		var nodeIds = {};
		var tree = [];

		for (let d of data) {
			let parentId = d[config.parentId];
			if (childrenListMap[parentId] == null) {
				childrenListMap[parentId] = [];
			}
			nodeIds[d[config.id]] = d;
			childrenListMap[parentId].push(d);
		}

		for (let d of data) {
			let parentId = d[config.parentId];
			if (nodeIds[parentId] == null) {
				tree.push(d);
			}
		}

		for (let t of tree) {
			adaptToChildrenList(t);
		}

		function adaptToChildrenList(o) {
			if (childrenListMap[o[config.id]] !== null) {
				o[config.childrenList] = childrenListMap[o[config.id]];
			}
			if (o[config.childrenList]) {
				for (let c of o[config.childrenList]) {
					adaptToChildrenList(c);
				}
			}
		}
		return tree;
	}

	addEntitiePoint(name, lon, lat, pointImg, html, scale = 0.11, callback) {
		let entitie = viewer.entities.add({
			position: Cesium.Cartesian3.fromDegrees(lon, lat),
			show: true,
			billboard: {
				image: pointImg,
				scale: scale, //原始大小的缩放比例
				horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
				verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
				scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 8.0e6, 0.1),
				disableDepthTestDistance: Number.POSITIVE_INFINITY, //一直显示，不被地形等遮挡
			},
			label: {
				show: false,
				text: name,
				font: "normal small-caps normal 19px 楷体",
				style: Cesium.LabelStyle.FILL_AND_OUTLINE,
				fillColor: Cesium.Color.fromCssColorString("#ffffff").withAlpha(1),
				outlineWidth: 2,
				horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
				verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
				pixelOffset: new Cesium.Cartesian2(10, 0), //偏移量
				heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, //贴地
			},
			tooltip: {
				html: name,
				anchor: [0, -10],
			},
			popup: {
				html: html,
				anchor: [0, -12], //左右、上下的偏移像素值。
				onAdd: function(eleId) {
					window._popup_eleId = eleId;
				},
			},
			click: function(e) { //单击后的回调
				callback();
			}
		});
		return entitie;
	}

	//判断环境打开新页面
	windowOpen(name, url) {
		console.log(url)
		var iWidth = window.screen.width //弹出窗口的宽度;
		var iHeight = window.screen.height //弹出窗口的高度;
		//window.screen.height获得屏幕的高，window.screen.width获得屏幕的宽
		var iTop = 0 //获得窗口的垂直位置;
		var iLeft = 0 //获得窗口的水平位置;
		console.log(iWidth);
		console.log(iHeight);
		console.log(iTop);
		console.log(iLeft);
		var tempwindow = window.open(
			url,
			name,
			`height=${iHeight},innerHeight=${iHeight},width=${iWidth},innerWidth=${iWidth},top=${iTop},left=${iLeft},toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no`
		)
		tempwindow.moveTo(0, 0);
		tempwindow.resizeTo(screen.width, screen.height);
	}
}

export default new XUtil();