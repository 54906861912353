import request from '@/utils/request'

// 查询数据底板信息列表
export function listFloorData(query) {
  return request({
    url: '/data/floorData/list',
    method: 'get',
    params: query
  })
}

// 查询数据底板信息详细
export function getFloorData(id) {
  return request({
    url: '/data/floorData/' + id,
    method: 'get'
  })
}

// 新增数据底板信息
export function addFloorData(data) {
  return request({
    url: '/data/floorData',
    method: 'post',
    data: data
  })
}

// 修改数据底板信息
export function updateFloorData(data) {
  return request({
    url: '/data/floorData',
    method: 'put',
    data: data
  })
}

// 删除数据底板信息
export function delFloorData(id) {
  return request({
    url: '/data/floorData/' + id,
    method: 'delete'
  })
}
