import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import zongHeYuTu from './modules/zongHeYuTu'
import device from './modules/device'
import station from './modules/station'
import qita from './modules/qita'
import jiankong from './modules/jiankong'
import getters from './getters'

Vue.use(Vuex)
export default new Vuex.Store({
	modules: {
    user,
		device,
		zongHeYuTu,
		station,
		qita,
		jiankong
	},
	getters
})