  <template>
  <div style="pointer-events: auto">
		<x-dialog-comp :title="name" @on-close="$emit('on-close')">
		    <x-title-btn :menu-data.sync="titleMenuData"></x-title-btn>
				<video v-show="titleMenuData[0].checkType" autoplay controls muted ref="videoRef" style="width: 100%;height: 100%;object-fit: fill"></video>
        <div style="position: absolute;right: 20px;top: 10px;font-size: 20px">
          环境烟雾浓度:正常
        </div>
		</x-dialog-comp>
  </div>
</template>

<script>
import XDialogComp from "@/components/x-dialog-comp";
import XDialogCompSmall from "@/components/x-dialog-comp-small";
import XTitleBtn from "@/components/x-title-btn";

import eventDetails from "./event-details";

export default {
  name: "xx-yg-video",
	components: { XDialogComp, XTitleBtn, XDialogCompSmall, eventDetails},
	
  props: ['videoSrc'],
  data: () => {
    return {
			name:"",
			titleMenuData: [
			  {
			    checkType: true,
			    name: '实时监控',
			  }, {
			    checkType: false,
			    name: 'AI视频事件',
			  }
			],
			getList:[],
      // 总条数
      total: 0,
			// 查询参数
			queryParams: {
			  pageNum: 1,
			  pageSize: 10,
			},
			open:false,
			headList:[{
			  id:1,
			  time:'',
			  imgUrl:"~@/assets/ZhiNengXiTong/img.png"
			},
			{
			  id:2,
			  time:'',
			  imgUrl:"~@/assets/ZhiNengXiTong/img_1.png"
			}
			],
			activeImg:require('@/assets/ZhiNengXiTong/永镇水库.png'),
			eventInfo:{},
		}
  },
  created() {
  },
  mounted() {
		var that = this;
    this.$request(
        {
          url: `${window.$baseUrl_hz_bz}/api/haikcamera/previewURLsV2?cameraIndexCode=${this.videoSrc.serialNumber}&streamType=0&protocol=hls`,
          type: 'get',
          headers: {
            isToken: false,
          }
        }
    ).then((val) => {
      let video = this.$refs.videoRef;
      if (video.canPlayType('application/vnd.apple.mpegurl')) {
        // 浏览器本身支持HLS
        video.src = val.data;
        that.name = val.data.deviceName;
      } else if (Hls.isSupported()) {
        // 检查是否支持HLS.js
        let hls = new Hls();
				that.name = val.data.deviceName;
        hls.loadSource(val.data.bofangUrl);
        hls.attachMedia(video);
      }
    });
		
		this.getDataList();
			
  },
	beforeDestroy() {
	  if (this.hls) {
	    this.$refs[this.Videoid].pause();
	    this.hls.stopLoad();
	    this.hls.destroy();
	    this.hls = null;
			// document.getElementById(this.Videoid).innerHTML = "";
			console.log("停止播放");
	  }
		this.videoShow = false;
	},
  methods: {
    /**
     * 关闭点击
     */
    closeClick() {
      this.$emit('on-close')
    },
		getDataList(){
			// this.loading = true;
			this.$request(
			    {
			      url: `${window.$baseUrl_hz_bz}/camera/actionAnalyseEvent/list?serialNumber=${this.videoSrc.serialNumber}`,
			      type: 'get',
			      headers: {
			        isToken: false,
			      }
			    }
			).then((val) => {
			  console.log("AI视频事件");
			  console.log(val);
				this.getList = val.rows;
				// this.getList = [
				// 	{startTime:1,regionName:2,resName:3,eventTypeName:4,eventLevel:5},
				// ];
				this.total = 45;
			});
		},
		openShow(item){
			this.eventInfo = item;
			this.open = !this.open;
		},
		
  }
}
</script>

<style scoped>
.x-tc-page-bg {
  background: url("~@/assets/common/tanChuang.png") no-repeat;
  background-size: 100% 100%;
  width: 1132px;
  height: 763px;
  position: fixed;
  top: 100px;
  left: 400px;
  z-index: 99999;
  margin-left: 55px;
}

.x-tc-title {
  font-size: 22px;
  position: absolute;
  left: 80px;
  top: 15px;
  color: white;
  letter-spacing: 5px;
}

.x-close {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 30px;
  cursor: pointer;
}

.x-tc-cnt {
  width: 1080px;
  margin-left: 25px;
  height: 700px;
  overflow: auto;
  position: absolute;
  top: 58px;
  overflow: hidden;
  background-color: white;
}
.x-table-title-list-bg{
	width: 98%;margin-left: 1%;text-align: center;line-height:40px;height:40px;
}
.x-table-title-list-bg div{
	flex: 1;
}
.x-table-title-list-bg:hover{
	background: #032e5a;
}
</style>
