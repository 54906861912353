var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex margin-top"}),_c('div',{staticClass:"margin-top flex"},[_c('div',[_c('div',{ref:"WeiYi3Ref",staticStyle:{"width":"500px","height":"500px"}}),_vm._m(0)]),_c('div',{staticClass:"text-white text-left",staticStyle:{"line-height":"30px"}},[_c('div',{staticClass:"flex"},[_c('span',{staticStyle:{"color":"#0f386233"}},[_vm._v(_vm._s(_vm.deviceId))])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{ref:"weiYi2Ref",staticStyle:{"width":"500px","height":"400px"}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"douyuFont"},[_vm._v(" 安全等级："),_c('span',{staticClass:"text-green margin-left"},[_vm._v("A")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('div',{staticClass:"x-lingxing"}),_vm._v(" X轴（北方向）位移：0.001m，阈值：0.1m，正常 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('div',{staticClass:"x-lingxing"}),_vm._v(" Y轴（东方向）位移：0.002m，阈值：0.1m，正常 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('div',{staticClass:"x-lingxing"}),_vm._v(" H轴（高度）位移：0.001m，阈值：0.1m，正常 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('div',{staticClass:"x-lingxing"}),_vm._v(" XY轴（二维）位移：0.001m，阈值：0.1m，正常 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('div',{staticClass:"x-lingxing"}),_vm._v(" XYH轴（三维）位移：0.001m，阈值：0.1m，正常 ")])
}]

export { render, staticRenderFns }