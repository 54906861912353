<template>
	<el-dialog :visible.sync="cktp" width="0px" append-to-body class="el-dialog-cktp" :before-close="handleClickcktp">

		<div>
			<div class="x-tc-page-bg">
				<div class="x-tc-title douyuFont">
					查看详情
				</div>
				<div class="x-close" @click="closeClick"></div>
				<div class="x-tc-cnt">
					<!-- <iframe :src="this.tuurl" class="pdf_iframe"></iframe> -->
					<div class="xuanze">
						<template v-if="this.leixing == 1 || this.leixing == 3">
							<span :class="shijianbj == 1 ? 'active' : ''" @click="tannInfo(1)">详细信息</span>
							<span :class="shijianbj == 2 ? 'active' : ''" @click="tannInfo(2)">查看监控</span>
						</template>
						<template v-if="this.leixing != 1">
							<span :class="shijianbj == 1 ? 'active' : ''" @click="tannInfo(1)">详细信息</span>
						</template>
					</div>
					<div v-if="this.leixing == 0 ">
						<div class="wb_kuang">
							<div class="wenbenrun">
								<span class="yihang" style="font-size: 18px;width: 100%;"
									v-if="this.zongitem.eventType != 0"><img src="~@/assets/dapingtc/dian.png"
												class="xiaodiantu" />{{
								event_type[this.zongitem.eventType] }}</span>
								<span class="yihang" style="font-size: 18px;width: 100%;"
									v-if="this.zongitem.eventType == 0"><img src="~@/assets/dapingtc/dian.png"
												class="xiaodiantu" />{{
								this.zongitem.alertName }}</span>
							<!-- 	<span class="yihang"><img src="~@/assets/dapingtc/dian.png"
												class="xiaodiantu" />
									<span class="fang_bt">事件等级：</span>
									<span v-if="this.zongitem.eventLevel == 1" class="fangkuang"
										style="background-color: #ff0000;">1级</span>
									<span v-if="this.zongitem.eventLevel == 2" class="fangkuang"
										style="background-color: #da6200;">2级</span>
									<span v-if="this.zongitem.eventLevel == 3" class="fangkuang"
										style="background-color: #c0be20;">3级</span>
								</span> -->
								<span class="yihang" style="width: 99%;"><img src="~@/assets/dapingtc/dian.png"
												class="xiaodiantu" />发生时间：{{ this.zongitem.happenTime }}</span>
												
								<span class="yihangtwo" style="width: 99%;" v-if="topicData.length==0">事件详情：{{ this.wenbenneirong }}</span>
								
								<span class="yihangtwo" style="width: 99%;" v-else>

									<div v-for="(topic, index) in topicData" :key="index">
										<div class="wennei"><img src="~@/assets/dapingtc/dian.png"
												class="xiaodiantu" />{{ getTopic(topic) }}:</div>
										<div v-for="(section, sectionIndex) in getSections(topic)" :key="sectionIndex">
											<div class="wennei" style="margin-left: 4%;width: 95%;">
												<img src="~@/assets/dapingtc/dian.png"
														class="xiaodiantu" />{{ getSectionTitle(section) }}:
											</div>
											<div class="wennei" style="margin-left: 8%;width: 91%;">
												{{ getSectionContent(section) }}
											</div>
										</div>
									</div>
								</span>
							</div>
						</div>
					</div>
					<div v-else>
						<div class="tp_kuang">
							<div class="nei_kuang" v-if="this.shijianbj == 1">
								<!-- 0文本 1图片 2音频 3视频 -->
								<div v-if="this.leixing == 1">
									<el-carousel height="610px">
										<div v-for="(item, index) of tupian" :key="index">
											<el-carousel-item>
												<img :src="(item.replace('http://218.59.186.166:6120','/166_6120'))"
													style="width: 100%;height: 100%;" />
											</el-carousel-item>
										</div>
									</el-carousel>
								</div>
								<div v-if="this.leixing != 1">
									<div class="tunei_bj">
										<img src="@/assets/ShuNao/zanwu.png"
											style="width: 444px;height: 387px;float: left;margin-top: 111.5px;margin-left: 133px;" />
										<span class="zanwuspan">暂无图片</span>
									</div>
								</div>
							</div>

							<div class="nei_kuang" v-if="this.shijianbj == 2">
								<jiankongbofang :ID="zongitem.deviceId" :yuntaikongzhi="2" :duijiangkongzhi="2"
									style="width: 100%;height: 100%;">
								</jiankongbofang>

							</div>


						</div>
						<div class="wz_kuang">
							<div class="wzneirong">
								<span class="yihang" style="font-size: 18px;" v-if="this.zongitem.eventType != 0">{{
								event_type[this.zongitem.eventType] }}</span>
								<span class="yihang" style="font-size: 18px;" v-if="this.zongitem.eventType == 0">{{
								this.zongitem.alertName }}</span>
								<span class="yihang">
									<span class="fang_bt">事件等级：</span>
									<span v-if="this.zongitem.eventLevel == 1" class="fangkuang"
										style="background-color: #ff0000;">1级</span>
									<span v-if="this.zongitem.eventLevel == 2" class="fangkuang"
										style="background-color: #da6200;">2级</span>
									<span v-if="this.zongitem.eventLevel == 3" class="fangkuang"
										style="background-color: #c0be20;">3级</span>
								</span>
								<span class="yihang">发生时间：{{ this.zongitem.happenTime }}</span>
								<span class="yihangtwo">事件详情：{{ this.zongitem.message }}</span>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import jiankongbofang from "@/components/x-video/jiankongbofang";
	import {
		getDictData
	} from "@/utils/dict";
	export default {
		name: "index",
		components: {
			jiankongbofang,
		},
		//                               0文本 1图片 2音频 3视频
		props: ['cktpId', 'cktpwen', 'cktpurl', 'leixing', 'zongitem'],
		data() {
			return {
				// 弹出层标题
				cktp: true,
				wenzhang: this.cktpwen,
				tuurl: this.cktpurl,
				tupian: [],
				textarea: '',
				event_type: [],
				shijianbj: 1,
				wenbenneirong: "",
				topicData: [],
			}
		},

		created() {

		},

		mounted() {
			if (this.leixing == 0) {
				console.log('文本')
				this.wenbenneirong = this.zongitem.message
				console.log(this.wenbenneirong)
				this.processData()

			}
			if (this.leixing == 1) {
				console.log('图片')
				this.wenbenneirong = this.zongitem.message
			}
			if (this.leixing == 2) {
				console.log('音频')
				this.wenbenneirong = this.zongitem.message
			}
			if (this.leixing == 3) {
				console.log('视频')
				this.wenbenneirong = this.zongitem.message
			}
			// console.log('传的cktpId', this.cktpId)
			// console.log('传的cktpwen',this.cktpwen)
			// console.log('传的cktpurl', this.cktpurl)
			console.log('传的zongitem', this.zongitem)

			getDictData("event_type").then((val) => {
				this.event_type = val;
			});
			if (this.tuurl != null) {
				var arr = this.tuurl.split(",");
				this.tupian = arr
			}

			// if (this.tuurl == null) {
			// 	this.tupian[0] =
			// 		"http://218.59.186.166:6120/pic?ed00=a602l36-do8d18*81d8*0o5=3773*0l5=8636688t6=313**ps==017=*101*7b1651413-3aa557c-193oe7-pi0edf=00290"

			// 	console.log("切完的" + this.tupian[0])
			// }
		},
		beforeDestroy() {

		},
		methods: {

			processData() {
				this.topicData = this.wenbenneirong.split(']}，');
				this.topicData.splice(-1, 1);
				console.log("topicData")
				console.log(this.topicData)
			},
			getTopic(topic) {
				return topic.split('：')[0] || '';
			},
			getSections(topic) {

				const contentStartIndex = topic.indexOf('：') + 1;
				const content = topic.substring(contentStartIndex).replace(/{/g, '')
				const segments = content.split(']');
				// console.log(segments)
				return segments || [];
			},
			getSectionTitle(section) {
				return section.split('：')[0] || '';;
			},
			getSectionContent(section) {
				// console.log(section)
				const sectionContent = section.split('：')[1];
				// console.log(sectionContent)
				const match = section.split('[')[1];

				let lastChar = match.charAt(match.length - 1);
				if (lastChar == ',' || lastChar == '，') {
					return match.slice(0, -1) + '。';
				} else {
					return match || '';
				}
			},
			tannInfo: function(id) {
				this.shijianbj = id;
			},
			handleClickcktp() {
				this.$emit('on-point')
			},

			closeClick() {
				this.$emit('on-point')
			},


		}
	}
</script>
<style scoped>
	.xuanze span {
		/* background: url("~@/assets/dapingtc/1.png") no-repeat; */
		background-image: url('~@/assets/dapingtc/1.png');
		background-size: cover;
		background-repeat: no-repeat;
		color: #fff;
		float: left;
		width: 265px;
		height: 70px;
		margin-top: 0px;
		margin-left: 5px;
		font-size: 16px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.xuanze span.active {
		/* background: url("~@/assets/dapingtc/2.png") no-repeat; */

		background-image: url('~@/assets/dapingtc/2.png');
		background-size: cover;
		background-repeat: no-repeat;

		text-align: center;
		color: #fff;
		float: left;
		width: 265px;
		height: 70px;
		margin-top: 0px;
		margin-left: 5px;
		font-size: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.xuanze {

		width: 1005px;
		height: 75px;
		margin-top: 15px;
		margin-left: 20px;
		float: left;
		/* border: #fff solid 1px; */
		cursor: pointer;
	}

	.chaxunanniu {
		color: #fff;
		margin-left: 0px;
		float: left;
		line-height: 32px;
		width: 72px;
		height: 32px;
		margin-top: 0px;
		border-radius: 10%;
		font-size: 16px;
		background-color: #1a6e9f;

	}

	.chaxunanniu:hover {
		color: #fff;
		margin-left: 0px;
		float: left;
		line-height: 32px;
		width: 72px;
		height: 32px;
		margin-top: 0px;
		border-radius: 10%;
		font-size: 16px;
		background-color: #218fce;

	}

	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 150px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}

	.pdf_iframe {
		width: 1085px;
		height: 750px;
	}

	.tp_kuang {
		width: 730px;
		height: 630px;
		/* margin-top: 66px; */
		margin-top: 16px;
		margin-left: 20px;
		float: left;
		border: #55aaff solid 1px;
		border-radius: 13px;
	}

	.wb_kuang {
		width: 1048px;
		height: 630px;
		/* margin-top: 66px; */
		margin-top: 16px;
		margin-left: 20px;
		float: left;
		border: #55aaff solid 1px;
		border-radius: 13px;
	}

	.nei_kuang {
		width: 710px !important;
		height: 610px !important;
		margin-top: 10px;
		margin-left: 10px;
		float: left;
		pointer-events: auto;
		color: #fff
	}

	.wz_kuang {
		width: 300px;
		height: 630px;
		margin-top: 16px;
		/* margin-top: 66px; */
		margin-left: 20px;
		float: left;
		border: #55aaff solid 1px;
		border-radius: 13px;
	}

	.zanwuspan {
		display: block;
		width: 710px;
		float: left;
		color: #fff;
		font-size: 20px;
		margin-top: -50px;
		text-align: center;
	}

	.yihang {
		display: block;
		float: left;
		color: #fff;
		width: 275px;
		height: 30px;
		margin-top: 20px;
		margin-left: 0px;
	}

	.wennei {
		float: left;
		width: 100%;
		/* height: 30px; */
	}

	.xiaodiantu {
		width: 20px;
		height: 20px;
		float: left;
	}

	.yihangtwo {
		display: block;
		float: left;
		color: #fff;
		width: 275px;
		/* height: 30px; */
		margin-top: 20px;
		margin-left: 0px;

	}

	.wenbenrun {
		float: left;
		color: #fff;
		width: 100%;
		height: 610px;
		margin-top: 0px;
		margin-left: 10px;

		display: block;
		/* 	justify-content: center;
		align-items: center;
		flex-direction: column; */
		font-size: 17px;
		overflow-x: hidden;
		overflow-y: scroll;
		/* line-height: 55px; */
		/* text-align: center; */
	}

	.wzneirong {
		float: left;
		color: #fff;
		width: 280px;
		height: 610px;
		margin-top: 10px;
		margin-left: 10px;

		display: block;
		/* 	justify-content: center;
		align-items: center;
		flex-direction: column; */
		font-size: 17px;
		overflow-x: hidden;
		overflow-y: scroll;
		/* line-height: 55px; */
		/* text-align: center; */
	}

	.x-tc-page-bg {
		background: url("~@/assets/common/tanChuang.png") no-repeat;
		background-size: 100% 100%;
		width: 1132px;
		height: 813px;
		position: fixed;
		top: 100px;
		left: 400px;
		z-index: 99999;
	}

	.x-tc-title {
		font-size: 22px;
		position: absolute;
		left: 80px;
		top: 15px;
		color: white;
		letter-spacing: 5px;
	}

	.x-close {
		width: 50px;
		height: 50px;
		position: absolute;
		right: 30px;
		cursor: pointer;
	}


	.x-tc-cnt {
		width: 1085px;
		height: 750px;
		position: absolute;
		left: 22px;
		top: 58px;
		overflow: hidden;
	}

	.fang_bt {
		display: block;
		height: 23px;
		text-align: center;
		line-height: 21px;
		margin-top: 2px;
		float: left;
		width: 86px;
	}

	.fangkuang {
		display: block;
		width: 70px;
		height: 23px;
		float: left;
		text-align: center;
		line-height: 21px;
		margin-top: 2px;
		/* margin-left: 20px */
	}

	.tunei_bj {
		border-radius: 13px;
		width: 710px;
		height: 610px;
		margin-top: 0px;
		margin-left: 0px;
		float: left;
		/* border: #55aaff solid 1px; */
		background-color: rgb(47, 170, 255, 0.4);


	}
</style>
<style>
	.shurunr .el-textarea__inner {
		color: #fff !important;
		background-color: rgb(47, 170, 255, 0.5) !important;
	}
</style>