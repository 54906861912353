// import spz from "@/assets/icon/deviceIcon/设备icon-视频站.png";
// import szz from "@/assets/icon/deviceIcon/设备icon-水质站.png";
// import wyz from "@/assets/icon/deviceIcon/设备icon-位移站.png";
// import syz from "@/assets/icon/deviceIcon/设备icon-渗压站.png";
// import sq from "@/assets/icon/deviceIcon/设备icon-墒情站.png";
// import qx from "@/assets/icon/deviceIcon/设备icon-气象站.png";
// import ll from "@/assets/icon/deviceIcon/设备icon-流量站.png";
// import sw from "@/assets/icon/deviceIcon/设备icon-水位站.png";
// import ykz from "@/assets/icon/deviceIcon/设备icon-远控站.png";
// import YiMinCunImg from '@/assets/icon/pointImg/移民村.png'
// import YiMinGongChengImg from '@/assets/icon/pointImg/示范工程.png'

import YanZhongIMG from "@/assets/icon/deviceIcon/严重事件.png";
import ZhongYaoIMG from "@/assets/icon/deviceIcon/重要事件.png";
import YiBanIMG from "@/assets/icon/deviceIcon/一般事件.png";
import iconIMG from "@/assets/icon/pointImg/icon.png"; //默认图片


const state = {
	// 清空做比较是不是两次点的同一个
	NameComparison: '默认',
	// 选择树
	checkData: [],
	// GIS展示点存放数组
	treeData: [],
	cloneTreeData: [],
	// 目录
	muluData: [],
	// 存储点集合
	StorageData: [],
	// 水利移民
	YiMinCunInfo: [],
	YiMinCunEntitie: [],
	// 水利移民 示范工程
	YiMinGongChengInfo: [],
	YiMinGongChengEntitie: [],

	// 现存勿动
	// 视频监控站设备
	videoInfo: [],
	videoEntitie: [],
	// 气象站设备
	QiXiangInfo: [],
	QiXiangEntitie: [],
	// 墒情站设备
	sqInfo: [],
	sqInfoEntitie: [],
	// 网关设备
	GatewayDevice: [],
	GatewayDeviceEntitie: [],
	// 泥沙含量计设备
	SedimentDevice: [],
	SedimentDeviceEntitie: [],
	// 水质站设备
	waterQualityInfo: [],
	waterQualityEntitie: [],
	// LED屏设备
	LEDscreenDevice: [],
	LEDscreenDeviceEntitie: [],
	// 音频设备设备
	FrequencyDevice: [],
	FrequencyDeviceEntitie: [],
	// 水闸设备
	ZhaMenInfo: [],
	ZhaMenEntitie: [],
	// 流量计设备
	LiuLiangInfo: [],
	LiuLiangEntitie: [],
	// 水位计设备
	ShuiWeiInfo: [],
	ShuiWeiEntitie: [],
	// 视频监视站
	videoInfo_hz: [],
	videoHZEntitie: [],
	//泵组检测站
	bengzuInfo_hz: [],
	BengZuHZEntitie: [],
	// 报警严重事件
	YanZhongInfo: [],
	YanZhongEntitie: [],
	// 报警重要事件
	ZhongYaoInfo: [],
	ZhongYaoEntitie: [],
	// 报警一般事件
	YiBanInfo: [],
	YiBanEntitie: [],
}

const mutations = {
	ADD_hz_BengZu: (state, val) => {
		state.bengzuInfo_hz.push.apply(state.bengzuInfo_hz, val);
	},
	ADD_YiMinCun: (state, val) => {
		state.YiMinCunInfo.push(val);
	},
	ADD_YiMinGongCheng: (state, val) => {
		state.YiMinGongChengInfo.push(val);
	},

	// 现存勿动
	// 存储数据消除
	StorageTotal: (state) => {
		state.videoInfo = [];
		state.QiXiangInfo = [];
		state.sqInfo = [];
		state.GatewayDevice = [];
		state.SedimentDevice = [];
		state.waterQualityInfo = [];
		state.LEDscreenDevice = [];
		state.FrequencyDevice = [];
		state.ZhaMenInfo = [];
		state.LiuLiangInfo = [];
		state.ShuiWeiInfo = [];
		state.videoInfo_hz = [];
		state.YanZhongInfo = [];
		state.ZhongYaoInfo = [];
		state.YiBanInfo = [];
	},
	ADD_Comparison: (state, val) => {
		state.NameComparison = val;
	},
	// 视频监控设备
	ADD_VIDEO: (state, val) => {
		state.videoInfo.push(val);
	},
	// 气象站设备
	ADD_QiXiang: (state, val) => {
		state.QiXiangInfo.push(val);
	},
	// 墒情站设备
	ADD_SQ: (state, val) => {
		state.sqInfo.push(val);
	},
	// 网关设备
	ADD_Gateway: (state, val) => {
		state.GatewayDevice.push(val);
	},
	// 泥沙含量计设备
	ADD_Sediment: (state, val) => {
		state.SedimentDevice.push(val);
	},
	// 水质站设备
	ADD_WATER_QUALITY: (state, val) => {
		state.waterQualityInfo.push(val);
	},
	// LED屏设备
	ADD_LEDscreen: (state, val) => {
		state.LEDscreenDevice.push(val);
	},
	// 音频设备
	ADD_Frequency: (state, val) => {
		state.FrequencyDevice.push(val);
	},
	// 水闸设备
	ADD_ZhaMen: (state, val) => {
		state.ZhaMenInfo.push(val);
	},
	// 流量计设备
	ADD_LiuLiang: (state, val) => {
		state.LiuLiangInfo.push(val);
	},
	// 水位计设备
	ADD_ShuiWei: (state, val) => {
		state.ShuiWeiInfo.push(val);
	},
	// 视频监视站
	ADD_hz_VIDEO: (state, val) => {
		state.videoInfo_hz.push(val);
	},
	// 报警严重事件
	ADD_YanZhong: (state, val) => {
		state.YanZhongInfo.push(val);
	},
	// 报警重要事件
	ADD_ZhongYao: (state, val) => {
		state.ZhongYaoInfo.push(val);
	},
	// 报警一般事件
	ADD_YiBan: (state, val) => {
		state.YiBanInfo.push(val);
	},
	// 树总选择
	CheckData: (state, val) => {
		state.checkData.push(val);
	},
	// 树总选择清空
	CheckDataEmpty: (state) => {
		state.checkData = [];
	},
	// 树总添加
	CheckAddData: (state, data) => {
		state.checkData[data.checkNum] = data.checkData;
	},
	// GIS展示点存放数组
	TreeData: (state, val) => {
		state.treeData.push(val);
	},
	// GIS展示点存放数组清空
	TreeDataEmpty: (state) => {
		state.treeData = [];
	},
	// 复制
	cloneTreeData: (state, val) => {
		state.cloneTreeData.push(val);
	},
	// GIS展示点存放数组
	TreeSaveData: (state, data) => {
		state.treeData[data.checkNum] = data.treeData;
	},
	// 复制GIS展示点存放数组
	CloneTreeSaveData: (state, data) => {
		state.cloneTreeData[data.checkNum] = data.treeData;
	},
	// 渲染数据存储
	addStorageData: (state, val) => {
		state.StorageData.push(val);
	},
	// 渲染存储清空
	delStorageData: (state) => {
		state.StorageData = [];
	},
	// 还原初始状态
	restoreMap: (state) => {
		state.StorageData = [];
		state.muluData = [];
		for (let i = 0; i < state.checkData.length; i++) {
			if (state.checkData[i].length > 0) {
				state.checkData[i] = [];
				// state.treeData[i] = [];
			}
		}
		for (let i = 0; i < state.treeData.length; i++) {
			state.treeData[i] = state.cloneTreeData[i];
		}
	},
	// 目录判断
	MuluData: (state, val) => {
		state.muluData.push(val);
	},
	// 目录清空
	emptyMuluData: (state) => {
		state.muluData = [];
	},
}


const actions = {
	// 公共设备显示
	showDeviceOfIds({
		commit
	}, ids) {
		// console.log(ids)
		// console.log(state)
		// console.log(state[ids[1]])
		for (let item of state[ids[2]]) {
			viewer.entities.remove(item)
		}
		state[ids[2]] = [];
		let scale = 0.29;
					// console.log(ids[2])
					// console.log(state[ids[1]])
		for (let item of state[ids[1]]) {
			if (ids[0].includes(item.id)) {
				let name = item.name;
				let longitude = item.longitude;
				let latitude = item.latitude;
				let O_Comment = '';
				let entitie = null;
				
				if (item.bindingId != null && item.bindingId != '') {
					entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
						if (ids[2] == 'videoEntitie') {
							window.videoClick(item)
						} else if (ids[2] == 'QiXiangEntitie') {
							window.QiXiangClick(item)
						} else if (ids[2] == 'sqInfoEntitie') {
							window.ShangQingClick(item)
						} else if (ids[2] == 'SedimentDeviceEntitie') {
							window.ShangQingClick(item)
						} else if (ids[2] == 'waterQualityEntitie') {
							window.shuiZhiClick(item)
						} else if (ids[2] == 'ZhaMenEntitie') {
							window.ZhaMenClick(item)
						} else if (ids[2] == 'LiuLiangEntitie') {
							window.LiuLiangClick(item)
						} else if (ids[2] == 'ShuiWeiEntitie') {
							window.ShuiWeiClick(item)
						}
					});
				} else {
					entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => { });
				}

				entitie.treeId = item.id;
				//entitie.show = false;
				state[ids[2]].push(entitie)
			}
		}
	},

	// 视频监控设备显示
	// showVideoOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.videoEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.videoEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.videoInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = '';
	// 			let entitie = null;
	// 			if (item.bindingId != null && item.bindingId != '') {
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
	// 					window.videoClick(item)
	// 				});
	// 			} else {
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => { });
	// 			}

	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.videoEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 气象站设备显示
	// showQiXiangOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.QiXiangEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.QiXiangEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.QiXiangInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = null;
	// 			if (item.bindingId != null && item.bindingId != '') {
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
	// 					window.QiXiangClick(item)
	// 				});
	// 			} else {
	// 				// console.log("没绑定设备不弹窗", item)
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => { });
	// 			}
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.QiXiangEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 墒情站设备显示
	// showShangQingOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.sqInfoEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.sqInfoEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.sqInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = null;
	// 			if (item.bindingId != null && item.bindingId != '') {
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
	// 					window.ShangQingClick(item)
	// 				});
	// 			} else {
	// 				// console.log("没绑定设备不弹窗", item)
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => { });
	// 			}
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.sqInfoEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 网关设备显示
	// showWangGuanOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.GatewayDeviceEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.GatewayDeviceEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.GatewayDevice) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = null;
	// 			if (item.bindingId != null && item.bindingId != '') {
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
	// 					window.ShangQingClick(item)
	// 				});
	// 			} else {
	// 				// console.log("没绑定设备不弹窗", item)
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => { });
	// 			}

	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.GatewayDeviceEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 泥沙含量计设备显示
	// showNiShaOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.SedimentDeviceEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.SedimentDeviceEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.SedimentDevice) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = null;
	// 			if (item.bindingId != null && item.bindingId != '') {
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
	// 					window.ShangQingClick(item)
	// 				});
	// 			} else {
	// 				// console.log("没绑定设备不弹窗", item)
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => { });
	// 			}
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.SedimentDeviceEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 显示水质站显示
	// showWaterQualityOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.waterQualityEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.waterQualityEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.waterQualityInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = null;
	// 			if (item.bindingId != null && item.bindingId != '') {
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
	// 					window.shuiZhiClick(item)
	// 				});
	// 			} else {
	// 				// console.log("没绑定设备不弹窗", item)
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => { });
	// 			}
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.waterQualityEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// LED屏设备显示
	// showLEDOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.LEDscreenDeviceEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.LEDscreenDeviceEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.LEDscreenDevice) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = null;
	// 			if (item.bindingId != null && item.bindingId != '') {
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
	// 					window.shuiZhiClick(item)
	// 				});
	// 			} else {
	// 				// console.log("没绑定设备不弹窗", item)
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => { });
	// 			}
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.LEDscreenDeviceEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 音频设备显示
	// showMUSICOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.FrequencyDeviceEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.FrequencyDeviceEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.FrequencyDevice) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = null;
	// 			if (item.bindingId != null && item.bindingId != '') {
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
	// 					window.shuiZhiClick(item)
	// 				});
	// 			} else {
	// 				// console.log("没绑定设备不弹窗", item)
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => { });
	// 			}
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.FrequencyDeviceEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 水闸设备显示
	// showZhaMenOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.ZhaMenEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.ZhaMenEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.ZhaMenInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = null;
	// 			if (item.bindingId != null && item.bindingId != '') {
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
	// 					window.ZhaMenClick(item)
	// 				});
	// 			} else {
	// 				// console.log("没绑定设备不弹窗", item)
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => { });
	// 			}
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.ZhaMenEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 流量计设备显示
	// showLiuLiangOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.LiuLiangEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.LiuLiangEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.LiuLiangInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = null;
	// 			if (item.bindingId != null && item.bindingId != '') {
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
	// 					window.LiuLiangClick(item)
	// 				});
	// 			} else {
	// 				// console.log("没绑定设备不弹窗", item)
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => { });
	// 			}
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.LiuLiangEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 水位计设备显示
	// showShuiWeiOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.ShuiWeiEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.ShuiWeiEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.ShuiWeiInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = null;
	// 			if (item.bindingId != null && item.bindingId != '') {
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
	// 					window.ShuiWeiClick(item)
	// 				});
	// 			} else {
	// 				// console.log("没绑定设备不弹窗", item)
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => { });
	// 			}
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.ShuiWeiEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 视频监视站显示
	// showHZVideoOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.videoHZEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.videoHZEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.videoInfo_hz) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = item.O_Comment || '';
	// 			let entitie = null;
	// 			if (item.bindingId != null && item.bindingId != '') {
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
	// 					window.videoHZClick(item)
	// 				});
	// 			} else {
	// 				// console.log("没绑定设备不弹窗", item)
	// 				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => { });
	// 			}
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.videoHZEntitie.push(entitie)
	// 		}
	// 	}
	// },

	//报警严重事件显示
	showDeviceYanZhongOfIds({
		commit
	}, ids) {
		for (let item of state[ids[2]]) {
			viewer.entities.remove(item)
		}
		state[ids[2]] = [];
		let scale = 0.1;
		for (let item of state[ids[1]]) {
			if (ids[0].includes(item.id)) {
				let name = item.name;
				let longitude = item.longitude;
				let latitude = item.latitude;
				let O_Comment = '';
				let entitie = addEntitiePoint(name, longitude, latitude, item.imgtwo, name + O_Comment, scale, () => {
					window.PoliceClick(item)
				});
				entitie.treeId = item.id;
				entitie.show = true;
				state[ids[2]].push(entitie)
			}
		}
	},

	//报警严重事件显示
	// showYanZhongOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.YanZhongEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.YanZhongEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.YanZhongInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = '';
	// 			let entitie = addEntitiePoint(name, longitude, latitude, YanZhongIMG, name + O_Comment, scale, () => {
	// 				window.PoliceClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			entitie.show = true;
	// 			state.YanZhongEntitie.push(entitie)
	// 		}
	// 	}
	// },

	//报警重要事件显示
	// showZhongYaoOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.ZhongYaoEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.ZhongYaoEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.ZhongYaoInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = '';
	// 			let entitie = addEntitiePoint(name, longitude, latitude, ZhongYaoIMG, name + O_Comment, scale, () => {
	// 				window.PoliceClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			entitie.show = true;
	// 			state.ZhongYaoEntitie.push(entitie)
	// 		}
	// 	}
	// },

	//报警一般事件显示
	// showYiBanOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.YiBanEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.YiBanEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.YiBanInfo) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let O_Comment = '';
	// 			let entitie = addEntitiePoint(name, longitude, latitude, YiBanIMG, name + O_Comment, scale, () => {
	// 				window.PoliceClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			entitie.show = true;
	// 			state.YiBanEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 视频监控设备隐藏
	hiddenVideoInfo({
		commit
	}, data = false) {
		for (let item of state.videoEntitie) {
			item.show = data;
		}
	},

	// 水质站隐藏
	hiddenWaterQualityInfo({
		commit
	}, data = false) {
		for (let item of state.waterQualityEntitie) {
			item.show = data;
		}
	},

	// 报警严重事件隐藏
	hiddenYanZhongInfo({
		commit
	}, data = false) {
		for (let item of state.YanZhongEntitie) {
			item.show = data;
		}
	},
	// 报警重要事件隐藏
	hiddenZhongYaoInfo({
		commit
	}, data = false) {
		for (let item of state.ZhongYaoEntitie) {
			item.show = data;
		}
	},
	// 报警一般事件隐藏
	hiddenYiBanInfo({
		commit
	}, data = false) {
		for (let item of state.YiBanEntitie) {
			item.show = data;
		}
	},
	// 水位计设备隐藏
	hiddenShuiWeiJiInfo({
		commit
	}, data = false) {
		for (let item of state.ShuiWeiEntitie) {
			item.show = data;
		}
	},
	// 流量计设备隐藏
	hiddenLiuLiangJiInfo({
		commit
	}, data = false) {
		for (let item of state.LiuLiangEntitie) {
			item.show = data;
		}
	},
	// 墒情设备隐藏
	hiddenShangQingInfo({
		commit
	}, data = false) {
		for (let item of state.sqInfoEntitie) {
			item.show = data;
		}
	},
	// 气象设备隐藏
	hiddenQiXiangInfo({
		commit
	}, data = false) {
		for (let item of state.QiXiangEntitie) {
			item.show = data;
		}
	},

	// BengZu
	// showHZBengZuOfIds({
	// 	commit
	// }, ids) {
	// 	for (let item of state.BengZuHZEntitie) {
	// 		viewer.entities.remove(item)
	// 	}
	// 	state.BengZuHZEntitie = [];
	// 	let scale = 0.1;
	// 	for (let item of state.bengzuInfo_hz) {
	// 		if (ids.includes(item.id)) {
	// 			let name = item.name;
	// 			let longitude = item.longitude;
	// 			let latitude = item.latitude;
	// 			let entitie = addEntitiePoint(name, longitude, latitude, item.img, "", scale, () => {
	// 				window.BengZuHZClick(item)
	// 			});
	// 			entitie.treeId = item.id;
	// 			//entitie.show = false;
	// 			state.BengZuHZEntitie.push(entitie)
	// 		}
	// 	}
	// },

	// 移民
	showYiMinCunOfIds({
		commit
	}, ids) {
		for (let item of state.YiMinCunEntitie) {
			viewer.entities.remove(item)
		}
		state.YiMinCunEntitie = [];
		let scale = 0.29;
		for (let item of state.YiMinCunInfo) {
			if (ids.includes(item.id)) {
				let name = item.name;
				let longitude = item.longitude;
				let latitude = item.latitude;
				let O_Comment = item.O_Comment || '';
				let entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
					// window.YiMinClick(item)
				});
				entitie.treeId = item.id;
				//entitie.show = false;
				state.YiMinCunEntitie.push(entitie)
			}
		}
	},

	// 移民   （未使用）
	showYiMinGongChengOfIds({
		commit
	}, ids) {
		for (let item of state.YiMinGongChengEntitie) {
			viewer.entities.remove(item)
		}
		state.YiMinGongChengEntitie = [];
		let scale = 0.29;
		for (let item of state.YiMinGongChengInfo) {
			if (ids.includes(item.id)) {
				let name = item.name;
				let longitude = item.longitude;
				let latitude = item.latitude;
				let O_Comment = item.O_Comment || '';
				let entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
					// window.YiMinClick(item)
					window.shifangongchengClick(item)
				});
				entitie.treeId = item.id;
				//entitie.show = false;
				state.YiMinGongChengEntitie.push(entitie)
			}
		}
	},

	// 灌区舆图流量计设备显示
	showYtLiuLiangOfIds({
		commit
	}) {
		for (let item of state.LiuLiangEntitie) {
			viewer.entities.remove(item)
		}
		state.LiuLiangEntitie = [];
		let scale = 0.29;
		for (let item of state.LiuLiangInfo) {
			let name = item.name;
			let longitude = item.longitude;
			let latitude = item.latitude;
			let O_Comment = item.O_Comment || '';
			let entitie = null;
			if (item.bindingId != null && item.bindingId != '') {
				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
					window.LiuLiangClick(item)
				});
			} else {
				// console.log("没绑定设备不弹窗", item)
				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => { });
			}
			entitie.treeId = item.id;
			//entitie.show = false;
			state.LiuLiangEntitie.push(entitie)
		}
	},

	// 灌区舆图水位计设备显示
	showTyShuiWeiOfIds({
		commit
	}) {
		for (let item of state.ShuiWeiEntitie) {
			viewer.entities.remove(item)
		}
		state.ShuiWeiEntitie = [];
		let scale = 0.29;
		for (let item of state.ShuiWeiInfo) {
			let name = item.name;
			let longitude = item.longitude;
			let latitude = item.latitude;
			let O_Comment = item.O_Comment || '';
			let entitie = null;
			if (item.bindingId != null && item.bindingId != '') {
				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
					window.ShuiWeiClick(item)
				});
			} else {
				// console.log("没绑定设备不弹窗", item)
				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => { });
			}
			entitie.treeId = item.id;
			//entitie.show = false;
			state.ShuiWeiEntitie.push(entitie)
		}
	},

	showYongZhenVideoOfIds({
		commit
	}) {
		for (let item of state.videoEntitie) {
			viewer.entities.remove(item)
		}
		state.videoEntitie = [];
		let scale = 0.29;
		for (let item of state.videoInfo) {
			let name = item.name;
			let longitude = item.longitude;
			let latitude = item.latitude;
			let O_Comment = '';
			let entitie = null;
			if (item.bindingId != null && item.bindingId != '') {
				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => {
					window.videoClick(item)
				});
			} else {
				// console.log("没绑定设备不弹窗", item)
				entitie = addEntitiePoint(name, longitude, latitude, item.img, name + O_Comment, scale, () => { });
			}
			entitie.treeId = item.id;
			//entitie.show = false;
			state.videoEntitie.push(entitie)
		}
	},

	// 隐藏移民
	hiddenYiMinCunOfIds({
		commit
	}, data = false) {
		for (let item of state.YiMinCunEntitie) {
			item.show = data;
		}
	},
	// 隐藏移民工程
	hiddenYiMinGongChengOfIds({
		commit
	}, data = false) {
		for (let item of state.YiMinGongChengEntitie) {
			item.show = data;
		}
	},

	// 根据ID飞行
	flyInfoOfId({
		commit
	}, info) {
		var id = info[0];
		var infoEntitle = info[1];
		for (let item of infoEntitle) {
			if (item.treeId.toString() === id.toString()) {
				viewer.mars.flyTo(item, {
					scale: 2.5,
					radius: 2000
				});
				// item.name.show = true;
				window.setTimeout(function () {
					// item.name.show = false;
				}, 5000);
				return;
			}
		}
	},


};


export default {
	namespaced: true,
	state,
	mutations,
	actions
}



/**
 * 添加点位数据
 * @param name
 * @param lon
 * @param lat
 * @param pointImg
 * @param html
 */
function addEntitiePoint(name, lon, lat, pointImg, html, scale, callback) {
	if (pointImg == null || pointImg == '') {
		pointImg = iconIMG
	}
	console.log(scale)
	let entitie = viewer.entities.add({
		position: Cesium.Cartesian3.fromDegrees(lon, lat),
		show: true,
		billboard: {
			image: pointImg,
			scale: scale, //原始大小的缩放比例
			horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
			verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
			scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 8.0e6, 0.1),
			disableDepthTestDistance: Number.POSITIVE_INFINITY, //一直显示，不被地形等遮挡
		},
		label: {
			show: false,
			text: name,
			font: "normal small-caps normal 19px 楷体",
			style: Cesium.LabelStyle.FILL_AND_OUTLINE,
			fillColor: Cesium.Color.fromCssColorString("#ffffff").withAlpha(1),
			outlineWidth: 2,
			horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
			verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
			pixelOffset: new Cesium.Cartesian2(10, 0), //偏移量
			heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, //贴地
		},
		tooltip: {
			html: name,
			anchor: [0, -10],
		},
		popup: {
			html: html,
			anchor: [0, -12], //左右、上下的偏移像素值。
			onAdd: function (eleId) {
				window._popup_eleId = eleId;
			},
		},
		click: function (e) { //单击后的回调
			callback();
		}
	});
	return entitie;
}